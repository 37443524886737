import { Navigate, Route, Routes } from "react-router-dom";
import { publicRoutes } from "../resources/Routes";
import Login from "../components/Commons/Login/Login";
import CreateAccount from "../components/FO-components/CreateAccount/CreateAccount";
import ChangePassword from "../components/Commons/ChangePassword/ChangePassword";

export default function PublicRouter() {
  return (
    <Routes>
      <Route path={publicRoutes.login} element={<Login />} />
      <Route path={publicRoutes.createAccount} element={<CreateAccount />} />
      <Route path={publicRoutes.changePassword} element={<ChangePassword />} />
      <Route path="*" element={<Navigate to={publicRoutes.login} />} />
    </Routes>
  );
}
