import { AxiosResponse } from "axios";
import HttpService from "./HttpService";
import { Endpoints } from "../resources/Endpoints";
import { IOrganization, IOrganizationUpdate } from "../interfaces/Organization";

export default function OrganizationService() {
  const getUniqueOrganization = (): Promise<AxiosResponse<IOrganization>> =>
    HttpService.fetch({
      url: Endpoints.organizations.uniqueOrganization(),
      method: "get",
    });

  const getOrganizations = (): Promise<AxiosResponse<IOrganization[]>> =>
    HttpService.fetch({ url: Endpoints.organizations.base, method: "get" });

  const updateOrganization = (
    uuid: string,
    organizationData: IOrganizationUpdate,
  ): Promise<AxiosResponse<IOrganization>> =>
    HttpService.fetch({ url: Endpoints.organizations.organization(uuid), method: "put", data: organizationData });

  const updateOrganizationLogo = (uuid: string, file: File): Promise<AxiosResponse<IOrganization>> => {
    const formData = new FormData();
    formData.append("logo", file);
    return HttpService.fetch({
      url: Endpoints.organizations.organizationLogo(uuid),
      method: "put",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
  };

  const getOrganizationLogo = (uuid: string): Promise<AxiosResponse<Blob>> =>
    HttpService.fetch({
      url: Endpoints.organizations.organizationLogo(uuid),
      method: "get",
      responseType: "blob",
    });

  return {
    updateOrganization,
    updateOrganizationLogo,
    getOrganizationLogo,
    getUniqueOrganization,
    getOrganizations,
  };
}
