import { AxiosResponse } from "axios";
import HttpService from "./HttpService";
import { ICompanyUser } from "../interfaces/CompanyUser";
import { Endpoints } from "../resources/Endpoints";
import { IBackOfficeUser } from "../interfaces/BackOfficeUser";
import { ICompanyDocument, ICompanyShort } from "../interfaces/Company";
import { IOiraShortForm } from "../interfaces/OiraForm";
import { IShortFormFilter } from "../interfaces/Form";

export default function FilterService() {
  const requestWithPage = (url: string, filterOptions, page?: number, size?: number) => {
    const params = new URLSearchParams(filterOptions);
    params.append("page", page?.toString() ?? "0");
    params.append("size", size?.toString() ?? "10");
    return HttpService.fetch({
      url,
      method: "get",
      params,
    });
  };

  const userFilter = (filterOptions, page = 0, size = 10): Promise<AxiosResponse<ICompanyUser[]>> =>
    requestWithPage(`${Endpoints.users.companyUsers.base}`, filterOptions, page, size);

  const expertFilter = (filterOptions, page = 0, size = 10): Promise<AxiosResponse<IBackOfficeUser[]>> =>
    requestWithPage(`${Endpoints.users.expertUsers.base}`, filterOptions, page, size);

  const companyFilter = (filterOptions, page = 0, size = 10): Promise<AxiosResponse<ICompanyShort[]>> =>
    requestWithPage(Endpoints.companies.base, filterOptions, page, size);

  const shortDocumentFilter = (filterOptions, page = 0, size = 10): Promise<AxiosResponse<IShortFormFilter[]>> =>
    requestWithPage(Endpoints.forms, filterOptions, page, size);

  const documentFilter = (
    uuid: string,
    filterOptions,
    page = 0,
    size = 10,
  ): Promise<AxiosResponse<ICompanyDocument[]>> =>
    requestWithPage(`${Endpoints.companies.forms(uuid)}`, filterOptions, page, size);

  const oiraFilter = (filterOptions, page = 0, size = 10): Promise<AxiosResponse<IOiraShortForm[]>> =>
    requestWithPage(Endpoints.oira.base, filterOptions, page, size);

  const groupedOiraFilter = (filterOptions, page = 0, size = 10): Promise<AxiosResponse<IOiraShortForm[]>> =>
    requestWithPage(Endpoints.oira.singleOiraByGroup, filterOptions, page, size);

  return {
    userFilter,
    expertFilter,
    companyFilter,
    documentFilter,
    oiraFilter,
    shortDocumentFilter,
    groupedOiraFilter,
  };
}
