/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-key */
// === Import NPM
import React, { useState, useEffect } from "react";
import {
  Tooltip,
  Box,
  Stack,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import { GridActionsCellItem, GridRowParams } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faCirclePlus, faUserGear, faUserTie } from "@fortawesome/free-solid-svg-icons";
import { FieldValues, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { ObjectShape } from "yup/lib/object";

// === Import LOCAL
import colors from "../../../../resources/cssConstant";
import GenericDatagrid from "../../../Generics/GenericDatagrid/GenericDatagrid";
import Header from "../../../Commons/Header/Header";
import GenericButton from "../../../Generics/buttons/GenericButton";
import GenericFilter from "../../../Generics/GenericFilter/GenericFilter";
import GenericTextField from "../../../Generics/GenericTextField/GenericTextField";
import { IFilterConfigurations, IFilterTypes, IShape } from "../../../../interfaces/Filter";
import GenericDialog from "../../../Generics/GenericDialog/GenericDialog";
import { stringifyErrorMessage } from "../../../../utils/ConversionMethods";
import {
  IBackOfficeUser,
  IBackOfficeUserCreation,
  IBackofficeUserRole,
  IBackOfficeUserUpdate,
} from "../../../../interfaces/BackOfficeUser";
import ExpertService from "../../../../services/ExpertService";
import { Role } from "../../../../interfaces/User";
import { objectFilter } from "../../../../utils/ObjectFilter";
import FilterService from "../../../../services/FilterService";
import { StatusCode } from "../../../../resources/StatusCode";
import { AmetraToken, AmetraTotalCount } from "../../../../resources/AppConstants";
import { useAuth } from "../../../../routers/useAuth";

interface ExpertFilterSchema {
  lastname: string;
  firstname: string;
  email: string;
  role: string;
}
interface CreateExpertSchema {
  lastname: string;
  firstname: string;
  email: string;
  role: string;
}
interface UpdateExpertSchema {
  lastname: string;
  firstname: string;
  email: string;
  role: string;
}

const expertFilterSchema = yup.object<IShape<ExpertFilterSchema>>({
  lastname: yup.string().max(70, "Le nom ne doit pas excéder 70 caractères."),
  firstname: yup.string().max(70, "Le prénom ne doit pas excéder 70 caractères."),
  email: yup.string().max(70, "L'email ne doit pas excéder 70 caractères."),
  role: yup.string().oneOf(["ALL", "EXPERT", "ADMIN"]),
});
const createExpertSchema = yup.object<IShape<CreateExpertSchema>>({
  firstname: yup
    .string()
    .max(70, "Votre prénom ne doit pas excéder 70 caractères.")
    .required("Veuillez remplir ce champ."),
  lastname: yup
    .string()
    .max(70, "Votre prénom ne doit pas excéder 70 caractères.")
    .required("Veuillez remplir ce champ."),
  email: yup.string().email("Le format est incorrect.").required("Veuillez remplir ce champ."),
  role: yup.string().oneOf(["ADMIN", "EXPERT"]).required("Veuillez remplir ce champ."),
});
const updateExpertSchema = yup.object<IShape<UpdateExpertSchema>>({
  firstname: yup
    .string()
    .max(70, "Votre prénom ne doit pas excéder 70 caractères.")
    .required("Veuillez remplir ce champ."),
  lastname: yup
    .string()
    .max(70, "Votre prénom ne doit pas excéder 70 caractères.")
    .required("Veuillez remplir ce champ."),
  email: yup.string().email("Le format est incorrect.").required("Veuillez remplir ce champ."),
  role: yup.string().oneOf(["ADMIN", "EXPERT"]).required("Veuillez remplir ce champ."),
});

// filter Inputs
const expertFilter: IFilterConfigurations<ExpertFilterSchema> = {
  lastname: {
    id: "lastname",
    name: "lastname",
    label: "Nom",
    type: IFilterTypes.TEXT,
  },
  firstname: {
    id: "firstname",
    name: "firstname",
    label: "Prénom",
    type: IFilterTypes.TEXT,
  },
  email: {
    id: "email",
    name: "email",
    label: "Email",
    type: IFilterTypes.TEXT,
  },
  role: {
    id: "role",
    name: "role",
    label: "Fonction",
    type: IFilterTypes.SELECT,
    optionValues: [
      { id: 1, value: Role.ALL, label: "Tous" },
      { id: 2, value: "EXPERT", label: "Préventeur" },
      { id: 3, value: "ADMIN", label: "Administrateur" },
    ],
  },
};

function Experts() {
  const auth = useAuth();

  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [experts, setExperts] = useState<IBackOfficeUser[]>([]);
  const [expertInfos, setExpertInfos] = useState<IBackOfficeUser>();
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [rowCount, setRowCount] = useState<number>(10);
  const [currentFilter, setCurrentFilter] = useState<IFilterConfigurations<ObjectShape> | null>(null);

  const filterMethod = async (filterInputs) => {
    setCurrentFilter(filterInputs);
    const requestParams = objectFilter(filterInputs, (filterInput) => filterInput !== "ALL" && filterInput !== "");
    const response = await FilterService().expertFilter(requestParams, page, rowsPerPage);
    if (response.status === StatusCode.OK) {
      setExperts(response.data);
      setRowCount(+(response.headers[AmetraTotalCount] ?? response.data.length));
    }
  };

  const fetchExperts = async () => {
    setExperts([]);
    setLoading(true);
    setCurrentFilter(null);
    filterMethod({});
    setLoading(false);
  };

  // useForm for create expert form
  const {
    register: createRegister,
    handleSubmit: handleCreateSubmit,
    reset: createReset,
    formState: { errors: createErrors, isValid: createValid },
  } = useForm({
    resolver: yupResolver(createExpertSchema),
    mode: "onTouched",
  });

  // useForm for update expert form
  const {
    register: updateRegister,
    handleSubmit: handleUpdateSubmit,
    reset: updateReset,
    formState: { errors: updateErrors, isValid: updateValid },
  } = useForm({
    resolver: yupResolver(updateExpertSchema),
    mode: "onTouched",
  });

  useEffect(() => {
    setLoading(true);
    if (currentFilter) {
      filterMethod(currentFilter);
    } else {
      fetchExperts();
    }
    setLoading(false);
  }, [page, rowsPerPage]);

  // reset create form inputs
  useEffect(() => {
    createReset();
  }, [openCreateModal]);

  // reset update form inputs
  useEffect(() => {
    updateReset();
  }, [openUpdateModal]);

  const handleAddAnExpert = async (data: FieldValues) => {
    const expertData: IBackOfficeUserCreation = {
      lastname: data.lastname,
      firstname: data.firstname,
      email: data.email,
      role: data.role,
    };
    const response = await ExpertService().createExpert(expertData);
    if (response.status === StatusCode.CREATED) {
      toast.success(
        data.role === Role.EXPERT
          ? `Le préventeur ${data.firstname} ${data.lastname} a bien été ajouté.`
          : `L'administrateur ${data.firstname} ${data.lastname} a bien été ajouté.`,
      );
      setOpenCreateModal(false);
      fetchExperts();
    } else {
      toast.error("Impossible d'ajouter le préventeur ou l'administrateur.");
      setOpenCreateModal(false);
    }
  };

  const handleEditExpert = async (data: FieldValues) => {
    const expertData: IBackOfficeUserUpdate = {
      lastname: data.lastname,
      firstname: data.firstname,
      email: data.email,
    };
    const expertRole: IBackofficeUserRole = data.role;
    if (expertRole !== expertInfos?.role) {
      const roleResponse = await ExpertService().updateExpertRole(expertInfos?.uuid ?? "", expertRole);
      if (roleResponse.status === StatusCode.NO_CONTENT) {
        const response = await ExpertService().updateExpert(expertInfos?.uuid ?? "", expertData);
        const tocken = response.headers[AmetraToken];
        if (tocken) auth.updateToken(tocken);
        if (response.status === StatusCode.NO_CONTENT) {
          toast.success(
            expertRole === Role.EXPERT
              ? `Le préventeur ${data.firstname} ${data.lastname} a bien été mis à jour.`
              : `L'administrateur ${data.firstname} ${data.lastname} a bien été mis à jour.`,
          );

          setOpenUpdateModal(false);
          fetchExperts();
        }
      } else {
        toast.error("Impossible de mettre à jour le préventeur ou l'administrateur.");
        setOpenUpdateModal(false);
      }
    } else {
      const response = await ExpertService().updateExpert(expertInfos?.uuid, expertData);
      const tocken = response.headers[AmetraToken];
      if (tocken) auth.updateToken(tocken);
      if (response.status === StatusCode.NO_CONTENT) {
        toast.success(
          expertInfos.role === Role.EXPERT
            ? `Le préventeur ${data.firstname} ${data.lastname} a bien été mis à jour.`
            : `L'administrateur ${data.firstname} ${data.lastname} a bien été mis à jour.`,
        );

        setOpenUpdateModal(false);
        fetchExperts();
      } else {
        toast.error("Impossible de mettre à jour le préventeur ou l'administrateur.");
        setOpenUpdateModal(false);
      }
    }
  };

  const deleteExpert = async (uuid: string) => {
    const response = await ExpertService().deleteExpert(uuid);
    if (response.status === StatusCode.NO_CONTENT) {
      toast.success(`Le préventeur ${expertInfos?.firstname} ${expertInfos?.lastname} a bien été supprimé.`);
      setOpenDeleteModal(false);
      setExpertInfos(undefined);
      fetchExperts();
    } else {
      toast.error("Impossible de supprimer le préventeur ou l'administrateur.");
      setOpenDeleteModal(false);
    }
  };

  const onFilterSubmit = async (data: FieldValues) => {
    const filterInputs = {
      lastname: data.lastname,
      firstname: data.firstname,
      email: data.email,
      role: data.role,
    };
    filterMethod(filterInputs);
  };

  const expertColumns = [
    {
      field: "uuid",
      hide: true,
    },
    {
      field: "lastname",
      headerName: "Nom",
      flex: 1,
      sortable: false,
    },
    {
      field: "firstname",
      headerName: "Prénom",
      flex: 1,
      sortable: false,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
      sortable: false,
    },
    {
      field: "role",
      headerName: "Rôle",
      flex: 2,
      sortable: false,
      valueGetter: (params) => (params.value === "ADMIN" ? "Administrateur" : "Préventeur"),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      headerAlign: "center",
      width: 200,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          key={`gaci1e-${params.row?.uuid}`}
          icon={
            <Tooltip arrow title="Modifier cet utilisateur">
              <FontAwesomeIcon size="lg" color={colors.primary} icon={faEdit} />
            </Tooltip>
          }
          label="edit"
          onClick={() => {
            setExpertInfos(params.row);
            setOpenUpdateModal(true);
          }}
        />,
        <GridActionsCellItem
          key={`gaci2e-${params.row?.uuid}`}
          icon={
            <Tooltip arrow title="Supprimer cet utilisateur">
              <FontAwesomeIcon size="lg" color={colors.deleteIcon} icon={faTrash} />
            </Tooltip>
          }
          label="delete"
          onClick={() => {
            setExpertInfos(params.row);
            setOpenDeleteModal(true);
          }}
        />,
      ],
    },
  ];

  const renderCreateExpertForm = () => (
    <Stack component="form" justifyContent="center" spacing={5}>
      <GenericTextField
        type="text"
        id="create-lastname"
        label="Nom"
        required
        error={!!createErrors.lastname}
        helperText={stringifyErrorMessage(createErrors.lastname)}
        register={createRegister("lastname")}
      />
      <GenericTextField
        type="text"
        id="create-firstname"
        label="Prénom"
        required
        error={!!createErrors.firstname}
        helperText={stringifyErrorMessage(createErrors.firstname)}
        register={createRegister("firstname")}
      />
      <GenericTextField
        id="create-email"
        label="Email"
        required
        error={!!createErrors.email}
        helperText={stringifyErrorMessage(createErrors.email)}
        register={createRegister("email")}
      />
      <FormControl required>
        <FormLabel id="create-role">Droits</FormLabel>
        <RadioGroup aria-labelledby="role" defaultValue="ADMIN" name="role">
          <FormControlLabel
            value="ADMIN"
            control={<Radio {...createRegister("role")} />}
            label={
              <Stack direction="row" spacing={1} ml={1}>
                <FontAwesomeIcon icon={faUserTie} size="lg" />
                <Typography>Administrateur</Typography>
              </Stack>
            }
          />
          <FormControlLabel
            value="EXPERT"
            control={<Radio {...createRegister("role")} />}
            label={
              <Stack direction="row" spacing={1} ml={1}>
                <FontAwesomeIcon icon={faUserGear} size="lg" />
                <Typography>Préventeur</Typography>
              </Stack>
            }
          />
        </RadioGroup>
      </FormControl>
    </Stack>
  );

  const renderUpdateExpertForm = () => (
    <>
      <Stack component="form" justifyContent="center" spacing={5}>
        <GenericTextField
          defaultValue={expertInfos?.lastname}
          type="text"
          id="update-lastname"
          label="Nom"
          required
          error={!!updateErrors.lastname}
          helperText={stringifyErrorMessage(updateErrors.lastname)}
          register={updateRegister("lastname")}
        />
        <GenericTextField
          defaultValue={expertInfos?.firstname}
          type="text"
          id="update-firstname"
          label="Prénom"
          required
          error={!!updateErrors.firstname}
          helperText={stringifyErrorMessage(updateErrors.firstname)}
          register={updateRegister("firstname")}
        />
        <GenericTextField
          defaultValue={expertInfos?.email}
          id="update-email"
          label="Email"
          required
          error={!!updateErrors.email}
          helperText={stringifyErrorMessage(updateErrors.email)}
          register={updateRegister("email")}
        />
        <FormControl required>
          <FormLabel id="update-role">Droits</FormLabel>
          <RadioGroup aria-labelledby="role" defaultValue={expertInfos?.role} name="role">
            <FormControlLabel
              value="ADMIN"
              control={<Radio {...updateRegister("role")} />}
              label={
                <Stack direction="row" spacing={1} ml={1}>
                  <FontAwesomeIcon icon={faUserTie} size="lg" />
                  <Typography>Administrateur</Typography>
                </Stack>
              }
            />
            <FormControlLabel
              value="EXPERT"
              control={<Radio {...updateRegister("role")} />}
              label={
                <Stack direction="row" spacing={1} ml={1}>
                  <FontAwesomeIcon icon={faUserGear} size="lg" />
                  <Typography>Préventeur</Typography>
                </Stack>
              }
            />
          </RadioGroup>
        </FormControl>
      </Stack>
      <Box sx={{ mt: 4 }}>
        <Typography variant="body1" fontWeight="bold" display="inline">
          Attention :
        </Typography>
        <Typography variant="body1" mb={2}>
          Un compte administrateur possède des droits plus élevés qu’un préventeur.
        </Typography>
        <Typography variant="body1">
          Soyez certain des besoins de ce compte. En cas de doute, attribuez uniquement des droits “Préventeur”.
        </Typography>
      </Box>
    </>
  );

  const renderCreateExpertModal = () => (
    <GenericDialog
      title="Créer un compte préventeur"
      openDialog={openCreateModal}
      handleClose={() => setOpenCreateModal(false)}
      confirmLabel="Enregistrer"
      onValid={handleCreateSubmit(handleAddAnExpert)}
      disabled={!createValid}
    >
      {renderCreateExpertForm()}
    </GenericDialog>
  );

  const renderUpdateExpertModal = () => (
    <GenericDialog
      title="Modifier le compte préventeur"
      openDialog={openUpdateModal}
      handleClose={() => setOpenUpdateModal(false)}
      confirmLabel="Enregistrer"
      onValid={handleUpdateSubmit(handleEditExpert)}
      disabled={!updateValid}
    >
      {renderUpdateExpertForm()}
    </GenericDialog>
  );

  const renderDeleteModal = () => (
    <GenericDialog
      title="Supprimer un compte préventeur"
      openDialog={openDeleteModal}
      handleClose={() => setOpenDeleteModal(false)}
      confirmLabel="Confirmer"
      onValid={() => deleteExpert(expertInfos?.uuid ?? "")}
    >
      <Stack>
        <Typography variant="modalContent">
          Souhaitez vous réellement supprimer le préventeur {expertInfos?.firstname} {expertInfos?.lastname} ?
        </Typography>
        <Typography variant="modalContent">Cette action est irréversible.</Typography>
      </Stack>
    </GenericDialog>
  );

  return (
    <>
      {renderCreateExpertModal()}
      {renderUpdateExpertModal()}
      {renderDeleteModal()}
      <Header title="Annuaire des préventeurs">
        <GenericButton text="Nouveau compte préventeur" icon={faCirclePlus} onClick={() => setOpenCreateModal(true)} />
      </Header>
      <Box sx={{ px: 15, py: 4 }}>
        <GenericFilter onSubmit={onFilterSubmit} schema={expertFilterSchema} filterInputs={expertFilter} />
        <GenericDatagrid
          rows={experts}
          columns={expertColumns}
          loading={loading}
          page={page}
          onPageChange={setPage}
          size={rowsPerPage}
          onPageSizeChange={setRowsPerPage}
          rowCount={rowCount}
        />
      </Box>
    </>
  );
}

export default Experts;
