import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import { backOfficeRoutes } from "../../resources/Routes";

export interface DrawerIcons {
  itemName: string;
  itemIcon: JSX.Element;
  itemLink: string;
}

const preventerList: DrawerIcons[] = [
  {
    itemName: "Entreprises",
    itemIcon: <BusinessRoundedIcon fontSize="large" />,
    itemLink: backOfficeRoutes.home,
  },
  {
    itemName: "Annuaire des utilisateurs",
    itemIcon: <PeopleAltIcon fontSize="large" />,
    itemLink: backOfficeRoutes.company_representatives,
  },
];

const adminList: DrawerIcons[] = [
  {
    itemName: "Entreprises",
    itemIcon: <BusinessRoundedIcon fontSize="large" />,
    itemLink: backOfficeRoutes.home,
  },
  {
    itemName: "Annuaire des utilisateurs",
    itemIcon: <PeopleAltIcon fontSize="large" />,
    itemLink: backOfficeRoutes.company_representatives,
  },
  {
    itemName: "Annuaire des préventeurs",
    itemIcon: <ManageAccountsRoundedIcon fontSize="large" />,
    itemLink: backOfficeRoutes.experts,
  },
  {
    itemName: "Questionnaires OIRA",
    itemIcon: <HelpRoundedIcon fontSize="large" />,
    itemLink: backOfficeRoutes.all_forms,
  },
  {
    itemName: "Administration",
    itemIcon: <SettingsRoundedIcon fontSize="large" />,
    itemLink: backOfficeRoutes.administration,
  },
];

export { preventerList, adminList };
