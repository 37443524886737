export enum AccompanyingStatus {
  ALL = "ALL",
  NOT_ACCOMPANIED = "NOT_ACCOMPANIED",
  ACCOMPANIED = "ACCOMPANIED",
  FIELD_ACCOMPANIMENT = "FIELD_ACCOMPANIMENT",
  UNDEFINED = "UNDEFINED",
}

export enum ProgressStatus {
  ALL = "ALL",
  FINALIZED = "FINALIZED",
  COMPLETED = "COMPLETED",
  NOT_FINALIZED = "NOT_FINALIZED",
  ARCHIVED = "ARCHIVED",
}

export enum Type {
  ALL = "ALL",
  DUERP = "DUERP",
  COMPLEMENTARY_FORM = "COMPLEMENTARY_FORM",
}

export enum ActionPriority {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}

export interface IShortForm {
  uuid: string;
  documentType: Type;
  progressStatus: ProgressStatus;
  accompanyingStatus: AccompanyingStatus;
  reference: string;
  creationDate: string;
  lastUpdatedDate: string;
  validationDate: Date | null;
}

export interface IShortFormFilter extends IShortForm {
  companyName: string;
}

export enum DocumentFormat {
  PDF = "PDF",
  WORD = "WORD",
  EXCEL = "EXCEL",
}

export const SPSTITableRowField = [
  "sis_cdi",
  "sis_cdd",
  "sis_inter",
  "sis_saison",
  "sir_cdi",
  "sir_cdd",
  "sir_inter",
  "sir_saison",
  "sia_cdi",
  "sia_cdd",
  "sia_inter",
  "sia_saison",
];

export interface SPSTITableRow {
  id: number;
  jobs: string;
  sis_cdi: number;
  sis_cdd: number;
  sis_inter: number;
  sis_saison: number;
  sir_cdi: number;
  sir_cdd: number;
  sir_inter: number;
  sir_saison: number;
  sia_cdi: number;
  sia_cdd: number;
  sia_inter: number;
  sia_saison: number;
}
