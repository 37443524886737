/* EXTERNAL */
import { FieldValues } from "react-hook-form";
import { toast } from "react-toastify";
import { Stack } from "@mui/material";
import { useAtom, useAtomValue } from "jotai";

/* LOCAL */
import { useNavigate } from "react-router-dom";
import { backOfficeUserAtom, companyUserAtom, userAtom } from "../../../atoms/Atoms";
import UserService from "../../../services/UserService";
import Header from "../Header/Header";
import { Role } from "../../../interfaces/User";
import ExpertService from "../../../services/ExpertService";
import ProfileForm from "./ProfileForm/ProfileForm";
import { StatusCode } from "../../../resources/StatusCode";
import { ICompanyUser } from "../../../interfaces/CompanyUser";
import { IBackOfficeUser } from "../../../interfaces/BackOfficeUser";
import { AmetraToken } from "../../../resources/AppConstants";
import { useAuth } from "../../../routers/useAuth";

export default function Profile() {
  const auth = useAuth();

  const [, setCompanyUser] = useAtom(companyUserAtom);
  const [, setExpertUser] = useAtom(backOfficeUserAtom);
  const user = useAtomValue(userAtom);

  const navigate = useNavigate();

  const fetchUser = async (uuid: string, companyUser = false) => {
    const res = companyUser ? await UserService().getUserById(uuid) : await ExpertService().getExpertById(uuid);
    if (res.data && companyUser) {
      setCompanyUser(res.data as ICompanyUser);
    } else if (res.data && !companyUser) {
      setExpertUser(res.data as IBackOfficeUser);
    }
  };

  const onUserSubmit = async (data: FieldValues) => {
    const newUserData = {
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      password: data.password,
      internal: data.internal,
      positionTitle: data.positionTitle,
    };
    const res = await UserService().updateUser(user.uuid, newUserData);
    if (res.status === StatusCode.NO_CONTENT) {
      toast.success("Vos informations personnelles ont bien été mises à jour.");
      fetchUser(user.uuid, true);
      navigate("/");
    } else {
      toast.error("Impossible de mettre à jour vos données.");
    }
  };

  const onExpertSubmit = async (data: FieldValues) => {
    const newUserData = {
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
    };
    const res = await ExpertService().updateExpert(user.uuid, newUserData);
    if (res.status === StatusCode.NO_CONTENT) {
      toast.success("Vos informations personnelles ont bien été mises à jour.");
      const tocken = res.headers[AmetraToken];
      if (tocken) auth.updateToken(tocken);
      fetchUser(user.uuid);
      navigate("/");
    } else {
      toast.error("Impossible de mettre à jour vos données.");
    }
  };
  return (
    <Stack justifyContent="center" alignItems="center" sx={{ mb: 5 }}>
      <Header title="Votre profil monduerp.fr" marginBottom={5} />
      {user.role === Role.COMPANY_USER ? (
        <ProfileForm
          submitButtonText="Mettre à jour"
          onSubmit={onUserSubmit}
          update
          companyUser
          title="Informations de votre profil"
        />
      ) : (
        <ProfileForm
          submitButtonText="Mettre à jour"
          onSubmit={onExpertSubmit}
          update
          title="Informations de votre profil"
        />
      )}
    </Stack>
  );
}
