// === Import  NPM
import React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";

// === Import local
import Navbar from "./components/Commons/Navbar/Navbar";
import Router from "./routers/Router";
import { ProvideAuth } from "./routers/useAuth";
import theme from "./utils/theme";

function App() {
  return (
    <ProvideAuth>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Navbar />
          <Router />
        </ThemeProvider>
      </BrowserRouter>
    </ProvideAuth>
  );
}

export default App;
