import { IShortForm } from "./Form";
import { IDuerpForm } from "./DuerpForm";

export enum ICompanyMembershipOrganism {
  OTHER = "Autre",
}

export interface ICompanyCreation {
  companyName: string;
  membershipNumber: string;
  membershipOrganization: string;
  collaborators: ICollaborator[];
  logoUuid?: string;
}

export interface ICompanyCreated extends ICompanyCreation {
  uuid: string;
}

export interface ICompany extends ICompanyCreated {
  documents: IShortForm[];
  externalCollaborators: ICollaborator[];
}

export interface ICompanyShort extends ICompanyCreated {
  lastDuerp: IShortForm | null;
  lastComplementaryForm: IShortForm | null;
  duerpsNumber: number;
  complementaryFormsNumber: number;
  externalCollaborators: ICollaborator[];
}

export interface ICompanyNameFilter extends ICompanyCreation {
  companyName: string;
}

export type ICollaborator = string;

export type ICompanyDocument = IShortForm | IDuerpForm;
