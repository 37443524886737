import { Button } from "@mui/material";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import colors from "../../../resources/cssConstant";

interface GenericButtonProps {
  text: string;
  color?: string;
  icon?: IconDefinition;
  disabled?: boolean;
  onClick?: () => void;
  login?: boolean;
}
export default function GenericButton({ text, color, disabled, onClick, icon, login }: Readonly<GenericButtonProps>) {
  return (
    <Button
      size="large"
      variant="contained"
      sx={{ backgroundColor: color, borderRadius: 3 }}
      onClick={onClick}
      startIcon={icon ? <FontAwesomeIcon size="xl" icon={icon} /> : null}
      disabled={disabled}
      type={login ? "submit" : "button"}
    >
      {text}
    </Button>
  );
}

GenericButton.defaultProps = {
  color: colors.mainOrange,
  icon: null,
  disabled: false,
  login: false,
};
