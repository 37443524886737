// === Import  NPM
import React from "react";
import { createTheme } from "@mui/material";
import type {} from "@mui/x-data-grid/themeAugmentation";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import { frFR as frDataGrid } from "@mui/x-data-grid";
import { frFR as frLocale } from "@mui/material/locale";

// === Import  LOCAL
import MontserratRegular from "../assets/fonts/Montserrat-Regular.ttf";
import MontserratBold from "../assets/fonts/Montserrat-Bold.ttf";
import CabinRegular from "../assets/fonts/Cabin-Regular.ttf";
import CabinBold from "../assets/fonts/Cabin-Bold.ttf";
import colors from "../resources/cssConstant";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    bottomMarginH4: React.CSSProperties;
    modalContent: React.CSSProperties;
    question: React.CSSProperties;
    subQuestion: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    bottomMarginH4?: React.CSSProperties;
    modalContent: React.CSSProperties;
    question: React.CSSProperties;
    subQuestion: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    bottomMarginH4: true;
    modalContent: true;
    question: true;
    subQuestion: true;
  }
}

const theme = createTheme(
  {
    typography: {
      h1: {
        fontFamily: "MontserratBold",
        fontSize: "4rem",
        color: `${colors.primary}`,
      },
      h2: {
        fontFamily: "MontserratBold",
        fontSize: "3rem",
        color: `${colors.primary}`,
      },
      h3: {
        fontFamily: "MontserratBold",
        fontSize: "1.7rem",
        color: `${colors.primary}`,
        textTransform: "uppercase",
      },
      h4: {
        fontFamily: "Cabin",
        fontSize: "1.5rem",
        color: `${colors.primary}`,
      },
      question: {
        fontFamily: "MontserratBold",
        fontSize: "1.5rem",
        color: `${colors.primary}`,
      },
      subQuestion: {
        fontFamily: "CabinBold",
        fontSize: "1.3rem",
        color: `${colors.primary}`,
      },
      bottomMarginH4: {
        fontFamily: "Cabin",
        fontSize: "1.5rem",
        color: `${colors.primary}`,
        marginBottom: 30,
      },
      body1: {
        fontFamily: "Cabin",
        color: `${colors.primary}`,
        fontSize: "1rem",
      },
      body2: {
        fontFamily: "CabinBold",
        color: `${colors.primary}`,
        fontSize: "1rem",
      },
      subtitle1: {
        fontFamily: "Cabin",
        color: `${colors.primary}`,
        fontSize: "1rem",
      },
      modalContent: {
        fontFamily: "Cabin",
        fontSize: "1.3rem",
        color: `${colors.primary}`,
        marginBottom: 20,
      },
    },

    components: {
      MuiCssBaseline: {
        styleOverrides: `
        @font-face {
          font-family: 'Montserrat';
          src: local('Montserrat'), local('Montserrat-Regular'), url(${MontserratRegular}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'MontserratBold';
          src: local('MontserratBold'), local('Montserrat-Bold'), url(${MontserratBold}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Cabin';
          src: local('Cabin'), local('Cabin-Regular'), url(${CabinRegular}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'CabinBold';
          src: local('CabinBold'), local('Cabin-Bold'), url(${CabinBold}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        /* ===== Scrollbar CSS ===== */

          /* Firefox */
          * {
            scrollbar-width:thin;
            scrollbar-color: #253E8C #ffffff;
          }

          /* Chrome, Edge, and Safari */
          *::-webkit-scrollbar {
            width: 10px;
          }

          *::-webkit-scrollbar-track {
            background-color: #00000000;
          }

          *::-webkit-scrollbar-track-piece {
              background-color: #00000000;
            }

          *::-webkit-scrollbar-thumb {
            background-color: #253E8C;
            border-radius: 10px;
            border: 1px solid #ffffff;
          }

          body {
            overflow: overlay;
            overflow-x:hidden;
          }
          `,
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            height: "70px",
            backgroundColor: "#fff",
            selected: {
              backgroundColor: `${colors.listItemHover}`,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "CabinBold",
            color: `${colors.white}`,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: "12px",
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            fontFamily: "CabinBold",
            color: `${colors.white}`,
            textTransform: "uppercase",
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontFamily: "Cabin",
            fontSize: "1rem",
            color: `${colors.white}`,
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            fontFamily: "CabinBold",
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            fontFamily: "Cabin",
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: `${colors.primary}`,
            minWidth: "70px",
            overflow: "hidden",
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            padding: "1rem",
            "&:hover": {
              backgroundColor: `${colors.mainOrange}`,
              borderRadius: 15,
            },
            "&.Mui-selected": {
              backgroundColor: `${colors.listItemHover}`,
              borderRadius: 15,
              "&:hover": {
                backgroundColor: `${colors.mainOrange}`,
                borderRadius: 15,
              },
            },
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: `${colors.white}`,
            fontSize: "2rem",
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            color: `${colors.white}`,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            fontFamily: "Cabin",
            color: `${colors.primary}`,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: `${colors.primary}`,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: `${colors.errors}`,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            variant: "outlined",
            color: `${colors.primary}`,
            textColor: `${colors.primary}`,
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            fontSize: "1rem",
            "& .MuiDataGrid-actionsCell": {
              width: "100%",
              justifyContent: "center",
            },
          },
          columnHeader: {
            backgroundColor: `${colors.primary}`,
            color: "#fff",
            justifyContent: "center",
            fontFamily: "CabinBold",
          },
          cell: {
            color: `${colors.primary}`,
            fontFamily: "Cabin",
            "&:focus": {
              outline: "none",
            },
            "&:focus-within": {
              outline: "none",
            },
          },
          columnSeparator: {
            display: "none",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            padding: "20px 100px",
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontFamily: "MontserratBold",
            fontSize: "1.7rem",
            color: `${colors.primary}`,
            textTransform: "uppercase",
            marginBottom: 30,
            textAlign: "center",
            padding: 0,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            display: "flex",
            justifyContent: "center",
            gap: "15%",
            marginTop: 30,
            padding: 0,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontFamily: "Cabin",
          },
          head: {
            fontFamily: "CabinBold",
            backgroundColor: `${colors.primary}`,
            color: `${colors.white}`,
            fontSize: "1rem",
          },
          body: {
            fontFamily: "Cabin",
            color: `${colors.primary}`,
            fontSize: "1rem",
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            borderRadius: 5,
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {
            fontFamily: "Cabin",
            color: `${colors.primary}`,
          },
          displayedRows: {
            fontFamily: "Cabin",
            color: `${colors.primary}`,
          },
          selectLabel: {
            fontFamily: "Cabin",
            color: `${colors.primary}`,
          },
        },
      },
      MuiDayPicker: {
        styleOverrides: {
          weekDayLabel: {
            fontFamily: "CabinBold",
            color: `${colors.primary}`,
            fontSize: "1rem",
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            fontFamily: "Cabin",
            color: `${colors.primary}`,
            fontSize: "1rem",
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontFamily: "Cabin",
          },
        },
      },
    },
    palette: {
      primary: {
        main: colors.primary,
      },
      info: {
        main: colors.white,
      },
    },
  },
  frLocale,
  frDataGrid,
);

export default theme;
