// === Import NPM
import React from "react";
import { TextField, MenuItem } from "@mui/material";

// === Import LOCAL
import { IOptionValue } from "../../../interfaces/Filter";

interface GenericTextFieldProps {
  defaultValue?: string | number | boolean;
  label: string;
  type?: string;
  id: string;
  isSelectInput?: boolean;
  error: boolean;
  helperText: string;
  required?: boolean;
  optionValues?: IOptionValue[];
  register: any;
  placeholder?: string;
  hidden?: boolean;
  sx?: object;
  multiline?: boolean;
  disabled?: boolean;
  rows?: number;
  value?: string;
  onChange?: any;
  multipleSelect?: boolean;
}
function GenericTextField({
  defaultValue,
  label,
  type,
  id,
  isSelectInput,
  multipleSelect,
  error,
  helperText,
  required,
  optionValues,
  register,
  placeholder,
  hidden,
  sx,
  multiline,
  rows,
  disabled,
}: Readonly<GenericTextFieldProps>) {
  const renderOptionValues = () =>
    optionValues?.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ));

  return (
    <TextField
      fullWidth
      type={type}
      defaultValue={defaultValue}
      label={label}
      id={id}
      select={isSelectInput}
      SelectProps={{
        multiple: multipleSelect,
      }}
      error={error}
      helperText={helperText}
      required={required}
      placeholder={placeholder}
      hidden={hidden}
      sx={sx}
      multiline={multiline}
      rows={rows}
      disabled={disabled}
      {...register}
    >
      {isSelectInput && renderOptionValues()}
    </TextField>
  );
}

GenericTextField.defaultProps = {
  defaultValue: undefined,
  type: undefined,
  required: false,
  disabled: false,
  isSelectInput: false,
  optionValues: undefined,
  placeholder: undefined,
  hidden: false,
  sx: undefined,
  multiline: false,
  rows: undefined,
  value: undefined,
  onChange: undefined,
  multipleSelect: false,
};

export default GenericTextField;
