/* EXTERNAL */
import { FieldValues } from "react-hook-form";
import { Stack } from "@mui/material";

/* LOCAL */
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ICompanyUserRegister } from "../../../interfaces/CompanyUser";
import AuthenticationService from "../../../services/AuthenticationService";
import { useAuth } from "../../../routers/useAuth";
import Header from "../../Commons/Header/Header";
import { frontOfficeRoutes } from "../../../resources/Routes";
import ProfileForm from "../../Commons/Profile/ProfileForm/ProfileForm";

export default function CreateAccount() {
  const auth = useAuth();
  const navigate = useNavigate();

  const onSubmit = async (data: FieldValues) => {
    const newUserData: ICompanyUserRegister = {
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      password: data.password,
      internal: data.internal,
      positionTitle: data.positionTitle,
    };
    const res = await AuthenticationService().createFOAccount(newUserData);
    if (res.data) {
      const loginRes = await auth.login({ email: data.email, password: data.password });
      if (loginRes.data) {
        navigate(frontOfficeRoutes.home);
      }
    } else {
      toast.error("Un compte est déjà associé à cette adresse email.");
    }
  };

  return (
    <Stack justifyContent="center" alignItems="center" sx={{ mb: 5 }}>
      <Header
        title="Nouveau compte monduerp.fr"
        subtitle="Créez un compte et accédez à notre service de création de Documents Uniques"
        marginBottom={5}
      />
      <ProfileForm
        submitButtonText="Créer un compte"
        onSubmit={onSubmit}
        title="Veuillez renseigner vos informations"
        companyUser
      />
    </Stack>
  );
}
