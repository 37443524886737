// === Import NPM
import React from "react";
import { TextField } from "@mui/material";
import { Control, Controller, FieldValues } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";

// === Import LOCAL

interface AutocompleteOption {
  id: string;
  name: string;
}

interface GenericAutoCompleteProps {
  name: string;
  control: Control<FieldValues, any> | undefined;
  id: string;
  label: string;
  options: any[];
  freeSolo?: boolean;
  defaultValue?: any;
}
function GenericAutoComplete({
  name,
  control,
  id,
  label,
  options,
  freeSolo,
  defaultValue,
}: Readonly<GenericAutoCompleteProps>) {
  const getAutoCompleteOptions = (optionsValues: any[]) => {
    const optionsArray: AutocompleteOption[] = [];
    optionsValues.forEach((option) => {
      const optionObject: AutocompleteOption = {
        id: option.uuid,
        name: option.name ?? option.companyName ?? option.title,
      };
      optionsArray.push(optionObject);
    });
    return optionsArray;
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => (
        <Autocomplete
          fullWidth
          freeSolo={freeSolo}
          id={id}
          getOptionKey={(option) => option.id + option.name}
          options={getAutoCompleteOptions(options)}
          defaultValue={defaultValue}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option?.id === value.id && option?.name === value.name}
          renderInput={(params) => <TextField {...params} label={label} />}
          onChange={(_, data) => {
            onChange(data);
            return data;
          }}
        />
      )}
    />
  );
}

GenericAutoComplete.defaultProps = {
  freeSolo: false,
  defaultValue: undefined,
};

export default GenericAutoComplete;
