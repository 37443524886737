import { ObjectShape } from "yup/lib/object";

export interface AutocompleteOption {
  id: string;
  name: string;
}

export enum IFilterTypes {
  TEXT,
  SELECT,
  AUTOCOMPLETE,
}

export interface IFilterInputNoSelect {
  id: string;
  name: string;
  label: string;
  type: IFilterTypes.TEXT;
  optionValues?: never;
}

export interface IFilterInputSelect {
  id: string;
  name: string;
  label: string;
  type: IFilterTypes.SELECT;
  optionValues: IOptionValue[];
}
export interface IFilterInputAutoComplete {
  id: string;
  name: string;
  label: string;
  type: IFilterTypes.AUTOCOMPLETE;
  optionValues: any[];
}

export interface IOptionValue {
  id?: number;
  value: string | number;
  label: string;
}

export type IFilterConfiguration = IFilterInputNoSelect | IFilterInputSelect | IFilterInputAutoComplete;

export type IFilterConfigurations<T extends {}> = Record<keyof T, IFilterConfiguration>;

type IObjectShapeValues = ObjectShape extends Record<string, infer V> ? V : never;

export type IShape<T extends {}> = Record<keyof T, IObjectShapeValues>;
