import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, IconButton } from "@mui/material";
import colors from "../../../resources/cssConstant";

interface GenericIconButtonProps {
  icon: IconDefinition;
  tooltip: string;
  color?: string;
  onClick: (event?: any) => void;
  size?: any;
  disabled?: boolean;
}

export default function GenericIconButton({
  icon,
  tooltip,
  color,
  onClick,
  disabled,
  size,
}: Readonly<GenericIconButtonProps>) {
  return (
    <Tooltip arrow title={tooltip}>
      <span>
        <IconButton onClick={onClick} disabled={disabled}>
          <FontAwesomeIcon size={size ?? "sm"} color={disabled ? colors.disabledIcon : color} icon={icon} />
        </IconButton>
      </span>
    </Tooltip>
  );
}

GenericIconButton.defaultProps = {
  color: colors.primary,
  disabled: false,
  size: undefined,
};
