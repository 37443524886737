import { Button, Dialog, DialogActions, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import colors from "../../../resources/cssConstant";
import LegalNotice from "../LegalNotice/LegalNotice";
import GenericButton from "../../Generics/buttons/GenericButton";

interface LegalNoticeUpdateDialogProps {
  open: boolean;
  onAccept: () => void;
}

export default function LegalNoticeUpdateDialog({ open, onAccept }: Readonly<LegalNoticeUpdateDialogProps>) {
  const [openLegalNotice, setOpenLegalNotice] = useState<boolean>(false);

  return (
    <Dialog keepMounted open={open} maxWidth="md" fullWidth>
      <DialogTitle>Mise à jour des Conditions Générales d'Utilisation</DialogTitle>

      <Typography>
        Les Conditions Générales d'Utilisation ont été mises à jour. Veuillez en prendre connaissance et accepter leur
        nouvelle version afin de pouvoir continuer à utiliser la plateforme MONDUERP.
      </Typography>
      <Stack alignItems={"center"}>
        <Button
          sx={{
            textDecoration: "underline",
            textTransform: "none",
            padding: 1,
            color: colors.primary,
            width: "fit-content",
            margin: 3,
          }}
          variant="text"
          onClick={() => setOpenLegalNotice(true)}
        >
          Lire les Conditions Générales d'Utilisation
        </Button>
      </Stack>

      <LegalNotice open={openLegalNotice} setOpen={setOpenLegalNotice} />
      <DialogActions>
        <GenericButton text={"J'accepte les CGU"} onClick={onAccept} />
      </DialogActions>
    </Dialog>
  );
}
