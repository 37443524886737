import { Skeleton, Stack, Typography } from "@mui/material";

export default function AdministrationSkeleton() {
  return (
    <Stack margin={3}>
      <Typography variant="h1">
        <Skeleton />
      </Typography>
      <Typography variant="h1">
        <Skeleton />
      </Typography>
    </Stack>
  );
}
