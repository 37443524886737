import { faDownload, faFileWord, faFilePdf, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { ListItemIcon, MenuItem, Stack, Typography, Box } from "@mui/material";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericIconButton from "../../../Generics/buttons/GenericIconButton";
import { Type, DocumentFormat } from "../../../../interfaces/Form";
import GenericDialog from "../../../Generics/GenericDialog/GenericDialog";
import GenericButton from "../../../Generics/buttons/GenericButton";
import colors from "../../../../resources/cssConstant";
import { downloadActionPlan, downloadBusinessDocument, downloadDUERP } from "../../../../utils/DownloadDocuments";

interface DownloadDocumentButtonProps {
  documentType: Type;
  documentUuid: string;
  iconButton?: boolean;
  menuButton?: boolean;
  endPageButtons?: boolean;
}

export default function DownloadDocumentButton({
  documentType,
  documentUuid,
  iconButton,
  menuButton,
  endPageButtons,
}: Readonly<DownloadDocumentButtonProps>) {
  const isDuerp = documentType === Type.DUERP;
  const typeLabel = isDuerp ? "DUERP et Plan d'Actions" : "la Fiche Entreprise";
  const [openDownloadModal, setOpenDownloadModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const downloadDocument = async (
    downloadMethod: (uuid: string, format: DocumentFormat) => Promise<void>,
    documentFormat: DocumentFormat,
  ) => {
    setLoading(true);
    await downloadMethod(documentUuid, documentFormat);
    setLoading(false);
  };

  const downloadPageContent = () => (
    <Stack spacing={5}>
      <Box>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Téléchargez votre DUERP
        </Typography>
        <Stack
          direction="row"
          justifyContent={endPageButtons ? "space-between" : "space-evenly"}
          width={endPageButtons ? 0.5 : 1}
        >
          <GenericButton
            onClick={() => downloadDocument(downloadDUERP, DocumentFormat.WORD)}
            icon={faFileWord}
            text="Format Word"
            disabled={loading}
          />
          <GenericButton
            onClick={() => downloadDocument(downloadDUERP, DocumentFormat.PDF)}
            icon={faFilePdf}
            text="Format PDF"
            disabled={loading}
          />
        </Stack>
      </Box>
      <Box>
        <Typography variant="body2" sx={{ mb: 2 }}>{`Téléchargez votre Plan d'Actions`}</Typography>
        <Stack
          direction="row"
          justifyContent={endPageButtons ? "space-between" : "space-evenly"}
          width={endPageButtons ? 0.5 : 1}
        >
          <GenericButton
            onClick={() => downloadDocument(downloadActionPlan, DocumentFormat.EXCEL)}
            icon={faFileExcel}
            text="Format Excel"
            disabled={loading}
          />
          <GenericButton
            onClick={() => downloadDocument(downloadActionPlan, DocumentFormat.PDF)}
            icon={faFilePdf}
            text="Format PDF"
            disabled={loading}
          />
        </Stack>
      </Box>
    </Stack>
  );

  const renderDownloadDuerpModal = () => (
    <GenericDialog
      openDialog={openDownloadModal}
      handleClose={() => setOpenDownloadModal(false)}
      title={`Télécharger DUERP et Plan d'Actions`}
    >
      {downloadPageContent()}
    </GenericDialog>
  );

  const renderDownloadModal = () =>
    isDuerp ? (
      renderDownloadDuerpModal()
    ) : (
      <GenericDialog
        openDialog={openDownloadModal}
        handleClose={() => setOpenDownloadModal(false)}
        title={`Télécharger ${typeLabel}`}
      >
        <Stack direction="row" justifyContent="space-evenly">
          <GenericButton
            onClick={() => downloadDocument(downloadBusinessDocument, DocumentFormat.WORD)}
            icon={faFileWord}
            text="Format Word"
            disabled={loading}
          />
        </Stack>
      </GenericDialog>
    );

  const renderMenuButton = () => (
    <MenuItem onClick={() => setOpenDownloadModal(true)}>
      <ListItemIcon>
        <FontAwesomeIcon icon={faDownload} size="xs" color={colors.primary} />
      </ListItemIcon>
      <Typography variant="body1">{`Télécharger ${typeLabel}`}</Typography>
    </MenuItem>
  );

  const renderInternalButton = () => {
    return menuButton ? renderMenuButton() : renderButtonStyle();
  };

  const renderButtonStyle = () =>
    iconButton ? (
      <GenericIconButton
        onClick={() => setOpenDownloadModal(true)}
        icon={faDownload}
        tooltip={`Télécharger ${typeLabel}`}
      />
    ) : (
      <GenericButton onClick={() => setOpenDownloadModal(true)} text={`Télécharger ${typeLabel}`} />
    );

  return (
    <>
      {renderDownloadModal()}
      {endPageButtons ? downloadPageContent() : renderInternalButton()}
    </>
  );
}

DownloadDocumentButton.defaultProps = {
  iconButton: false,
  menuButton: false,
  endPageButtons: false,
};
