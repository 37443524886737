/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { useAtom } from "jotai";
import Header from "../Header/Header";
import CollapsibleCompanyTable from "../CollapsibleCompanyTable/CollapsibleCompanyTable";
import GenericButton from "../../Generics/buttons/GenericButton";
import OrganizationService from "../../../services/OrganizationService";
import { allOrganizationsAtom } from "../../../atoms/Atoms";

export default function Companies() {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [, setAllOrganizations] = useAtom(allOrganizationsAtom);

  const fetchAllOrganizations = async () => {
    const organizationRes = await OrganizationService().getOrganizations();
    setAllOrganizations(organizationRes.data);
  };

  useEffect(() => {
    fetchAllOrganizations();
  }, []);

  const renderHeaderButton = () => (
    <GenericButton text="Nouvelle entreprise" icon={faPlusCircle} onClick={() => setOpenModal(true)} />
  );

  return (
    <Stack justifyContent="center">
      <Header title="Entreprises">{renderHeaderButton()}</Header>
      <Stack spacing={2} sx={{ mx: "auto", my: 5, width: "85%" }}>
        <CollapsibleCompanyTable setOpenModal={setOpenModal} openModal={openModal} />
      </Stack>
    </Stack>
  );
}
