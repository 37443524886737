import { Stack, Typography } from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../Header/Header";
import GenericTextField from "../../Generics/GenericTextField/GenericTextField";
import { stringifyErrorMessage } from "../../../utils/ConversionMethods";
import GenericButton from "../../Generics/buttons/GenericButton";
import AuthenticationService from "../../../services/AuthenticationService";
import { StatusCode } from "../../../resources/StatusCode";
import { publicRoutes } from "../../../resources/Routes";

const passwordSchema = yup.object().shape({
  password: yup
    .string()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]).{8,30}$/,
      "Le mot de passe doit contenir entre 8 et 30 caractères, dont 1 lettre majuscule, 1 lettre minuscule, 1 chiffre et 1 caractère spécial (!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~).",
    )
    .required("Veuillez saisir votre nouveau mot de passe."),
  confirmPassword: yup
    .string()
    .required("Veuillez confirmer votre nouveau mot de passe.")
    .oneOf([yup.ref("password")], "Le mot de passe doit être saisi à l'identique."),
});
export default function ChangePassword() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(passwordSchema),
    mode: "onTouched",
  });

  const onSubmit = async (data: FieldValues) => {
    if (token) {
      const res = await AuthenticationService().updatePassword(token, data.password);
      if (res.status === StatusCode.OK) {
        toast.success("Votre mot de passe a bien été modifié.");
        reset();
        navigate(publicRoutes.login);
      } else {
        toast.error("Impossible de modifier le mot de passe.");
      }
    } else {
      toast.error("Ce lien est invalide.");
      navigate(publicRoutes.login);
    }
  };

  return (
    <Stack justifyContent="center" alignItems="center" spacing={3}>
      <Header
        title="Modifiez votre mot de passe monduerp.fr"
        subtitle="Accédez à vos DUERPs ou démarrez de nouveaux questionnaires de prévention des risques"
        marginBottom={5}
      />
      <Stack alignItems="center" spacing={10} width="30%" onSubmit={handleSubmit(onSubmit)} component="form">
        <Typography variant="h4">Modifier votre mot de passe</Typography>
        <Stack alignItems="center" spacing={2} width="100%">
          <GenericTextField
            defaultValue=""
            required
            type="password"
            id="password"
            label="Nouveau mot de passe"
            error={!!errors.password}
            helperText={stringifyErrorMessage(errors.password)}
            register={register("password")}
          />
          <GenericTextField
            defaultValue=""
            required
            type="password"
            id="confirmPassword"
            label="Confirmez votre nouveau mot de passe"
            error={!!errors.confirmPassword}
            helperText={stringifyErrorMessage(errors.confirmPassword)}
            register={register("confirmPassword")}
          />
        </Stack>
        <GenericButton text="Sauvegarder le nouveau mot de passe" disabled={!isValid} login />
      </Stack>
    </Stack>
  );
}
