/* EXTERNAL */
import { faArchive, faCheck, faCheckDouble, faQuestionCircle, faSync } from "@fortawesome/free-solid-svg-icons";
import colors from "../../../resources/cssConstant";

/* LOCAL */
import { ProgressStatus } from "../../../interfaces/Form";
import GenericChip from "../../Generics/GenericChip/GenericChip";

interface ProgressChipProps {
  progressType: ProgressStatus;
}

export default function ProgressChip({ progressType }: ProgressChipProps) {
  const renderChip = () => {
    switch (progressType) {
      case ProgressStatus.NOT_FINALIZED:
        return <GenericChip text="Non finalisé" icon={faSync} color={colors.notFinalized} />;
      case ProgressStatus.COMPLETED:
        return <GenericChip text="Complété" icon={faCheck} color={colors.completed} />;
      case ProgressStatus.FINALIZED:
        return <GenericChip text="Finalisé" icon={faCheckDouble} color={colors.finalized} />;
      case ProgressStatus.ARCHIVED:
        return <GenericChip text="Archivé" icon={faArchive} color={colors.archived} />;
      default:
        return <GenericChip text="INCONNU" icon={faQuestionCircle} color={colors.accompanied} />;
    }
  };

  return renderChip();
}
