/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react-hooks/exhaustive-deps */
// === Import NPM
import { Dispatch, SetStateAction } from "react";
import { toast } from "react-toastify";
import { Typography } from "@mui/material";

// === Import LOCAL
import GenericDialog from "../../../Generics/GenericDialog/GenericDialog";
import { ICompanyShort } from "../../../../interfaces/Company";
import CompanyService from "../../../../services/CompanyService";
import { StatusCode } from "../../../../resources/StatusCode";

interface CompanyDeleteProps {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  company?: ICompanyShort;
  fetchCompanies: () => void;
}

function CompanyDelete({ openModal, setOpenModal, company, fetchCompanies }: Readonly<CompanyDeleteProps>) {
  const handleDeleteCompany = async () => {
    if (!company?.uuid) {
      toast.error("Erreur lors de la suppression de l'entreprise");
      return;
    }
    const response = await CompanyService().deleteCompany(company.uuid);
    if (response.status === StatusCode.OK) {
      toast.success("Entreprise {} supprimée avec succès".replace("{}", company.companyName));
      setOpenModal(false);
      fetchCompanies();
    } else {
      toast.error("Erreur lors de la suppression de l'entreprise");
    }
  };

  return (
    <GenericDialog
      openDialog={openModal}
      handleClose={() => setOpenModal(false)}
      title="Suppression d'entreprise"
      onValid={handleDeleteCompany}
    >
      <Typography variant="modalContent">
        {company?.companyName &&
          `Vous allez supprimer l'entreprise ${company?.companyName}, elle n'apparaîtra donc plus dans l'outil.`}
      </Typography>
      <Typography variant="modalContent">Cette action est définitive.</Typography>
    </GenericDialog>
  );
}

export default CompanyDelete;
