import { Typography, Skeleton, Stack, Box } from "@mui/material";

function SkeletonComplementaryFormItem() {
  return (
    <Typography variant="body2" sx={{ height: "100px", width: "100%" }}>
      <Skeleton sx={{ height: "100px", width: "100%" }} />
    </Typography>
  );
}

export default function SkeletonComplementaryForm() {
  return (
    <Stack spacing={5} height="100%">
      <Typography variant="h1">
        <Skeleton />
      </Typography>
      <Stack spacing={1} width="100%">
        {[...Array(5)].map((_, index) => (
          <Box key={index}> {SkeletonComplementaryFormItem()} </Box>
        ))}
      </Stack>
    </Stack>
  );
}
