import { useAtomValue } from "jotai";
import { IShortForm, ProgressStatus, Type } from "../../../../../interfaces/Form";
import { ICompanyShort } from "../../../../../interfaces/Company";
import DuplicateDocumentButton from "../../../DocumentActions/DuplicateDocumentButton/DuplicateDocumentButton";
import OpenDocumentButton from "../../../DocumentActions/OpenDocumentButton/OpenDocumentButton";
import NewDocumentButton from "../../../DocumentActions/NewDocumentButton/NewDocumentButton";
import DownloadDocumentButton from "../../../DocumentActions/DownloadDocumentButton/DownloadDocumentButton";
import { IOiraShortForm } from "../../../../../interfaces/OiraForm";
import { userShortAtom } from "../../../../../atoms/Atoms";
import { Role } from "../../../../../interfaces/User";

interface DocumentActionsProps {
  document: IShortForm;
  company: ICompanyShort;
  oiras: IOiraShortForm[];
}
export default function DocumentActions({ document, company, oiras }: Readonly<DocumentActionsProps>) {
  const user = useAtomValue(userShortAtom);
  return (
    <>
      <OpenDocumentButton document={document} company={company} />
      <DuplicateDocumentButton documentType={document.documentType} documentUuid={document.uuid} company={company} />
      <NewDocumentButton buttonWithIcon company={company} documentType={document.documentType} oiras={oiras} />
      {user.role !== Role.COMPANY_USER && document.progressStatus !== ProgressStatus.NOT_FINALIZED && (
        <DownloadDocumentButton documentType={document.documentType} documentUuid={document.uuid} iconButton />
      )}
      {user.role === Role.COMPANY_USER &&
        document.documentType === Type.DUERP &&
        document.progressStatus !== ProgressStatus.NOT_FINALIZED && (
          <DownloadDocumentButton documentType={document.documentType} documentUuid={document.uuid} iconButton />
        )}
    </>
  );
}
