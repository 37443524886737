import { AxiosResponse } from "axios";
import HttpService from "./HttpService";
import { IShortForm, DocumentFormat } from "../interfaces/Form";
import { Endpoints } from "../resources/Endpoints";
import {
  IDuerpCreation,
  IDuerpForm,
  IDuerpQuestion,
  IDuerpQuestionCreation,
  IDuerpSection,
  IDuerpSectionUpdate,
} from "../interfaces/DuerpForm";

export default function DuerpService() {
  const getDuerpFormShorts = (uuid: string): Promise<AxiosResponse<IShortForm[]>> =>
    HttpService.fetch({
      url: Endpoints.companies.duerp(uuid),
      method: "get",
    });

  const archiveDuerp = (duerpUuid: string): Promise<AxiosResponse<IDuerpForm>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.archiveDuerp(duerpUuid),
      method: "patch",
    });

  const createDuerp = (duerpCreationDto: IDuerpCreation): Promise<AxiosResponse<IDuerpForm>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.base,
      method: "post",
      data: duerpCreationDto,
    });

  const getDuerpByReference = (reference: string): Promise<AxiosResponse<IDuerpForm>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.treeWithRef(reference),
      method: "get",
    });
  // Return a tree

  const getDuerp = (uuid: string): Promise<AxiosResponse<IDuerpForm>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.tree(uuid),
      method: "get",
    });
  // Return a tree

  const duplicateDuerp = (duerpUuid: string, companyUuid: string): Promise<AxiosResponse<IDuerpForm>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.duplicateDuerp(duerpUuid),
      method: "post",
      data: companyUuid,
      headers: { "Content-Type": "application/json" },
    });

  const deleteDuerp = (duerpUuid: string): Promise<AxiosResponse<IDuerpForm>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.getDuerp(duerpUuid),
      method: "delete",
    });
  const downloadDuerp = (duerpUuid: string, format: DocumentFormat): Promise<AxiosResponse<Blob>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.exportDuerp(duerpUuid),
      method: "get",
      params: {
        format,
      },
      responseType: "blob",
    });

  const downloadActionPlan = (duerpUuid: string, format: DocumentFormat): Promise<AxiosResponse<Blob>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.actionPlanDuerp(duerpUuid),
      method: "get",
      params: {
        format,
      },
      responseType: "blob",
    });

  const patchFieldAccompaniment = (duerpUuid: string): Promise<AxiosResponse<IDuerpForm>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.fieldAcc(duerpUuid),
      method: "patch",
    });

  const validateDuerp = (duerpUuid: string): Promise<AxiosResponse<IDuerpForm>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.validate(duerpUuid),
      method: "patch",
    });

  const patchGeneralComment = (duerpUuid: string, newComment: string): Promise<AxiosResponse<IDuerpForm>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.generalComment(duerpUuid),
      method: "patch",
      data: { comment: newComment },
    });

  // ---------------------------Section---------------------------
  const getDuerpSection = (duerpUuid: string, sectionUuid: string): Promise<AxiosResponse<IDuerpSection>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.baseSection(duerpUuid, sectionUuid),
      method: "get",
    });
  const getDuerpSectionTree = (duerpUuid: string, sectionUuid: string): Promise<AxiosResponse<IDuerpSection[]>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.treeSection(duerpUuid, sectionUuid),
      method: "get",
    });

  const updateDuerpSection = (
    sectionDto: IDuerpSectionUpdate,
    duerpUuid: string,
    sectionUuid: string,
  ): Promise<AxiosResponse<IDuerpSection>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.baseSection(duerpUuid, sectionUuid),
      method: "put",
      data: sectionDto,
    });

  // ---------------------------Questions---------------------------

  const getDuerpQuestion = (
    duerpUuid: string,
    sectionUuid: string,
    questionUuid: string,
  ): Promise<AxiosResponse<IDuerpQuestion>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.baseQuestion(duerpUuid, sectionUuid, questionUuid),
      method: "get",
    });

  const updateDuerpQuestion = (
    questionDto: IDuerpQuestion,
    duerpUuid: string,
    sectionUuid: string,
    questionUuid: string,
  ): Promise<AxiosResponse<IDuerpQuestion[]>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.baseQuestion(duerpUuid, sectionUuid, questionUuid),
      method: "put",
      data: questionDto,
    });

  const deleteDuerpQuestion = (
    duerpUuid: string,
    sectionUuid: string,
    questionUuid: string,
  ): Promise<AxiosResponse<IDuerpQuestion[]>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.baseQuestion(duerpUuid, sectionUuid, questionUuid),
      method: "delete",
    });

  const postDuerpQuestion = (
    questionCreationDto: IDuerpQuestionCreation,
    duerpUuid: string,
    sectionUuid: string,
  ): Promise<AxiosResponse<IDuerpQuestion>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.customQuestion(duerpUuid, sectionUuid),
      method: "post",
      data: questionCreationDto,
    });

  return {
    getDuerpFormShorts,
    downloadDuerp,
    archiveDuerp,
    deleteDuerp,
    duplicateDuerp,
    createDuerp,
    downloadActionPlan,
    getDuerp,
    getDuerpByReference,
    patchFieldAccompaniment,
    validateDuerp,
    patchGeneralComment,
    getDuerpSection,
    getDuerpSectionTree,
    updateDuerpSection,
    getDuerpQuestion,
    updateDuerpQuestion,
    deleteDuerpQuestion,
    postDuerpQuestion,
  };
}
