import { DateTime } from "luxon";
import { IShortForm, ActionPriority } from "./Form";

export interface IDuerpCreation {
  companyUuid: string;
  oiraFormUuid: string;
}

export interface IDuerpForm extends IShortForm {
  title: string;
  sections: IDuerpLightSection[];
  generalComment: string;
  companyUuid: string;
  oiraFormUuid: string;
}

export type IDuerpLightSection =
  | {
      uuid: string;
      title: string;
      companyConcerned: boolean;
      completed: boolean;
      questions: IDuerpLightQuestion[];
      subSections?: never;
    }
  | {
      uuid: string;
      title: string;
      companyConcerned: boolean;
      completed: boolean;
      questions?: never;
      subSections: IDuerpLightSection[];
    };

export interface IDuerpSection {
  uuid: string;
  title: string;
  description: string;
  optional: boolean;
  companyConcerned: boolean;
  customRisksSection: boolean;
  completed: boolean;
}

export interface IDuerpSectionUpdate {
  companyConcerned: boolean;
}

export interface IDuerpQuestionCreation {
  title: string;
}

export interface IDuerpLightQuestion {
  uuid: string;
  title: string;
  addByExpert: boolean;
  completed: boolean;
}

export interface IDuerpQuestion {
  uuid: string;
  title: string;
  description: string;
  actionPriority: ActionPriority;
  currentMeasures: IDuerpAnswer[];
  futureMeasures: IDuerpAnswer[];
  riskManaged: boolean;
  companyComment: string;
  expertComment: string;
  completed: boolean;
  addByExpert: boolean;
}

export enum AnswerType {
  CURRENT = "CURRENT",
  FUTURE = "FUTURE",
}

export interface IDuerpAnswer {
  uuid: string;
  title: string;
  description?: string;
  date?: DateTime;
  checkedByExpert: boolean;
  checkedByCompany: boolean;
  currentlyInPlace: boolean;
  oiraAnswerUuid: string; //only for mesurecard deletion
  expertComment: string;
  answerType: AnswerType;
  duerpQuestionUuid: string;
}
