import { Dispatch, useEffect, useState, SetStateAction } from "react";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Stack, Typography, Button, Grid } from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { stringifyErrorMessage } from "../../../utils/ConversionMethods";
import OrganizationService from "../../../services/OrganizationService";
import GenericTextField from "../../Generics/GenericTextField/GenericTextField";
import { IOrganization, IOrganizationUpdate } from "../../../interfaces/Organization";
import { IShape } from "../../../interfaces/Filter";
import GenericButton from "../../Generics/buttons/GenericButton";
import { StatusCode } from "../../../resources/StatusCode";

interface UpdateOrganizationSchema {
  organizationName: string;
  logo: object;
  spstipresentation: string;
  companyName: string;
  rcsregistration: string;
  address: string;
  firstname: string;
  lastname: string;
  electronicAddress: string;
}

interface AdministrationFormProps {
  organization: IOrganization;
  updateOrganization: Dispatch<SetStateAction<IOrganization>>;
}

export default function AdministrationForm({ organization, updateOrganization }: Readonly<AdministrationFormProps>) {
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [organizationLogo, setOrganizationLogo] = useState<Blob | null>(null);
  const [fileErrorMessage, setFileErrorMessage] = useState<string | null>(null);

  const testImageFormat = (format: string) =>
    ["image/jpeg", "image/jpg", "image/png", "image/svg+xml"].includes(format);

  const checkFileType = (fileList: FileList) => {
    if (!fileList?.length) return true;
    return testImageFormat(fileList[0]?.type);
  };

  const checkFileSize = (fileList: FileList) => {
    if (!fileList?.length) return true;
    return fileList[0]?.size <= 5000000;
  };

  const updateErrorMessage = (fileList: FileList) => {
    if (!fileList || fileList.length === 0) return true;
    if (!checkFileSize(fileList)) {
      setFileErrorMessage("La taille du fichier ne doit pas dépasser 5Mo.");
      setOrganizationLogo(null);
      return false;
    }
    if (!checkFileType(fileList)) {
      setFileErrorMessage("Seuls les formats suivants sont acceptés : jpeg, jpg, png, svg.");
      setOrganizationLogo(null);
      return false;
    }
    setFileErrorMessage(null);
    setOrganizationLogo(fileList[0]);
    return true;
  };

  const updateOrganizationSchema = yup.object<IShape<UpdateOrganizationSchema>>({
    organizationName: yup
      .string()
      .max(255, "Le nom de l'organisme ne doit pas excéder 255 caractères.")
      .required("Veuillez remplir ce champ."),
    spstipresentation: yup
      .string()
      .max(2000, "La description ne doit pas excéder 2000 caractères.")
      .required("Veuillez remplir ce champ."),
    companyName: yup
      .string()
      .max(255, "Le nom de la société ne doit pas excéder 255 caractères.")
      .required("Veuillez remplir ce champ."),
    rcsregistration: yup.string().required("Veuillez remplir ce champ."),
    address: yup
      .string()
      .max(255, "Les informations d'adresse ne doivent pas excéder 255 caractères.")
      .required("Veuillez remplir ce champ."),
    firstname: yup
      .string()
      .max(70, "Le prénom du responsable ne doit pas excéder 70 caractères.")
      .required("Veuillez remplir ce champ."),
    lastname: yup
      .string()
      .max(70, "Le nom du responsable ne doit pas excéder 70 caractères.")
      .required("Veuillez remplir ce champ."),
    electronicAddress: yup.string().email("Le format est incorrect.").required("Veuillez remplir ce champ."),
    logo: yup.mixed().test("fileTest", "Impossible d'ajouter ce fichier.", (value) => updateErrorMessage(value)),
  });

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(updateOrganizationSchema),
    mode: "onTouched",
  });

  const logo = watch("logo");

  const updateOrganizationLogo = async (organizationUuid: string) => {
    await OrganizationService().updateOrganizationLogo(organizationUuid, logo[0]);
  };

  const getOrganizationLogo = async () => {
    if (organization?.logoUuid) {
      const res = await OrganizationService().getOrganizationLogo(organization.uuid);
      if (res.data) {
        setOrganizationLogo(res.data);
      }
    }
  };

  useEffect(() => {
    if (logo?.length > 0) setShowPreview(true);
  }, [logo]);

  useEffect(() => {
    getOrganizationLogo();
  }, []);

  const onSubmit = async (data: FieldValues) => {
    const organizationToSubmit: IOrganizationUpdate = {
      organizationName: data.organizationName,
      spstipresentation: data.spstipresentation,
      companyName: data.companyName,
      rcsregistration: data.rcsregistration,
      address: data.address,
      firstname: data.firstname,
      lastname: data.lastname,
      electronicAddress: data.electronicAddress,
    };

    const response = await OrganizationService().updateOrganization(organization.uuid, organizationToSubmit);
    if (response.status === StatusCode.OK) {
      updateOrganization(response.data);
      if (logo?.length > 0) await updateOrganizationLogo(response.data.uuid);
      toast.success("Les informations ont bien été modifiées.");
      setShowPreview(false);
    } else {
      toast.error("Impossible de sauvegarder les informations pour le moment.");
    }
  };

  return (
    <Stack component="form" spacing={5} sx={{ margin: "3%", paddingBottom: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h4">{`Version actuelle des CGU : ${organization.cguVersion}`}</Typography>
        <GenericButton text="Mettre à jour les CGU" onClick={handleSubmit(onSubmit)} disabled={!isValid} />
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="subQuestion" sx={{ marginY: 2 }}>
          Organisme SPSTI
        </Typography>
        <Grid container spacing={3}>
          <Grid item container direction="column" xs={12} sm={12} md={6} spacing={3}>
            <Grid item>
              <GenericTextField
                defaultValue={organization?.organizationName ?? ""}
                id="organizationName"
                label="Nom de l'organisme"
                required
                error={!!errors.organizationName}
                helperText={stringifyErrorMessage(errors.organizationName)}
                register={register("organizationName")}
              />
            </Grid>
            <Grid item>
              <GenericTextField
                defaultValue={organization?.spstipresentation ?? ""}
                id="spstipresentation"
                label="Description de l'organisme"
                required
                error={!!errors.spstipresentation}
                helperText={stringifyErrorMessage(errors.spstipresentation)}
                register={register("spstipresentation")}
                multiline
                rows={5}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Stack spacing={1} justifyContent="space-between">
              <Typography variant="body1">{`Logo de l'organisme`}</Typography>
              {!fileErrorMessage && showPreview && (
                <img src={URL.createObjectURL(logo[0])} alt="logo preview" style={{ width: "25%" }} />
              )}
              {!fileErrorMessage && organizationLogo && !showPreview && (
                <img src={URL.createObjectURL(organizationLogo)} alt="Logo" style={{ width: "25%" }} />
              )}
              {!organizationLogo && <Typography variant="body1">Aucun logo ajouté</Typography>}

              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  component="label"
                  startIcon={<FontAwesomeIcon size="xl" style={{ marginRight: "1rem" }} icon={faUpload} />}
                >
                  Importer un logo
                  {/*
                   */}
                  <input
                    hidden
                    accept="image/png, image/jpg, image/jpeg, image/svg+xml"
                    type="file"
                    {...register("logo")}
                  />
                </Button>
                {fileErrorMessage && (
                  <Typography variant="body1" color="error">
                    {fileErrorMessage}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="subQuestion" sx={{ marginY: 2 }}>
          Société
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <GenericTextField
              defaultValue={organization?.companyName ?? ""}
              id="spstipresentation"
              label="Nom de la société"
              required
              error={!!errors.companyName}
              helperText={stringifyErrorMessage(errors.companyName)}
              register={register("companyName")}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <GenericTextField
              defaultValue={organization?.rcsregistration ?? ""}
              id="rcsregistration"
              label="Immatriculation au RCS"
              required
              error={!!errors.rcsregistration}
              helperText={stringifyErrorMessage(errors.rcsregistration)}
              register={register("rcsregistration")}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <GenericTextField
              defaultValue={organization?.address ?? ""}
              id="address"
              label="Adresse de la société"
              required
              error={!!errors.address}
              helperText={stringifyErrorMessage(errors.address)}
              register={register("address")}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="subQuestion" sx={{ marginY: 2 }}>
          Responsable des données
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <GenericTextField
              defaultValue={organization?.firstname ?? ""}
              id="firstname"
              label="Prénom du responsable"
              required
              error={!!errors.firstname}
              helperText={stringifyErrorMessage(errors.firstname)}
              register={register("firstname")}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <GenericTextField
              defaultValue={organization?.lastname ?? ""}
              id="lastname"
              label="Nom du responsable"
              required
              error={!!errors.lastname}
              helperText={stringifyErrorMessage(errors.lastname)}
              register={register("lastname")}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <GenericTextField
              defaultValue={organization?.electronicAddress ?? ""}
              id="electronicAddress"
              label="Email du responsable"
              required
              error={!!errors.electronicAddress}
              helperText={stringifyErrorMessage(errors.electronicAddress)}
              register={register("electronicAddress")}
            />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
}
