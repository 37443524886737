import { useEffect, useState } from "react";
import * as yup from "yup";
import { AnySchema } from "yup";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { faCommentDots, faComments, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { useAtom, useAtomValue } from "jotai";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import {
  IComplementaryAnswer,
  IComplementaryAnswerGroup,
  IComplementaryQuestion,
  IComplementarySection,
  IQuestionEnum,
  IQuestionType,
  ISectionEnum,
} from "../../../../../interfaces/ComplementaryForm";
import GenericTextField from "../../../../Generics/GenericTextField/GenericTextField";
import GenericDatePicker from "../../../../Generics/GenericDatePicker/GenericDatePicker";
import { dateTimeValidator, stringifyErrorMessage } from "../../../../../utils/ConversionMethods";
import { frenchPhoneFormatRegexp, maxSPSTILines } from "../../../../../resources/AppConstants";
import GenericButton from "../../../../Generics/buttons/GenericButton";
import colors from "../../../../../resources/cssConstant";
import { currentComplementaryFormAtom, userAtom } from "../../../../../atoms/Atoms";
import { SPSTITableRow, SPSTITableRowField } from "../../../../../interfaces/Form";
import { Role } from "../../../../../interfaces/User";
import GenericIconButton from "../../../../Generics/buttons/GenericIconButton";
import ComplementaryFormSPSTITable from "../ComplementaryFormSPSTITable/ComplementaryFormSPSTITable";
import { backOfficeRoutes, frontOfficeRoutes } from "../../../../../resources/Routes";
import GenericDialog from "../../../../Generics/GenericDialog/GenericDialog";
import ComplementaryFormEndPage from "../ComplementaryFormEndPage/ComplementaryFormEndPage";
import CommentCard from "../../../CommentCard/CommentCard";

interface ComplementaryFormSectionProps {
  section: IComplementarySection;
  canEdit: boolean;
  goToNextPage: () => void;
  goToPreviousPage: () => void;
  renderNext: boolean;
  renderPrevious: boolean;
}

const generateYupValidation = (question: IComplementaryQuestion) => {
  const isDuerpDate = question.enumValue === IQuestionEnum.DUERP_DATE;
  switch (question.type) {
    case IQuestionType.BOOLEAN:
      return generateYupValidationForBoolean();
    case IQuestionType.DATE:
      return generateYupValidationForDate();
    case IQuestionType.SELECT:
      return generateYupValidationForSelect();
    case IQuestionType.EMAIL:
      return generateYupValidationForEmail();
    case IQuestionType.NUMBER:
      return generateYupValidationForNumber(isDuerpDate);
    case IQuestionType.PHONE:
      return generateYupValidationForPhone();
    case IQuestionType.SINGLE_SELECT:
    case IQuestionType.TEXT:
    default:
      return generateYupValidationForSingleSelectAndText();
  }
};

const generateYupValidationForBoolean = () => {
  return yup.string().nullable().oneOf(["", "Oui", "Non", null]);
};

const generateYupValidationForDate = () => {
  return yup
    .mixed()
    .nullable()
    .test("is-date-time", "Le format est incorrect.", (value) => (value ? dateTimeValidator(value) : true));
};

const generateYupValidationForSelect = () => {
  return yup.array().of(yup.string().nullable()).nullable();
};

const generateYupValidationForEmail = () => {
  return yup.string().nullable().email("Le format est incorrect.");
};

const generateYupValidationForNumber = (isDuerpDate: boolean) => {
  return yup
    .number()
    .typeError(isDuerpDate ? "Veuillez saisir une année au format AAAA." : "Veuillez saisir uniquement des chiffres.")
    .max(
      isDuerpDate ? DateTime.now().year : Number.MAX_VALUE,
      isDuerpDate
        ? "Vous ne pouvez pas saisir une date supérieure à l'année actuelle."
        : "La valeur saisie est trop grande.",
    )
    .min(
      isDuerpDate ? 1000 : 0,
      isDuerpDate ? "Veuillez saisir une année au format AAAA." : "Veuillez saisir des valeurs positives.",
    )
    .nullable()
    .transform((_, val) => (val !== "" ? Number(val) : null));
};

const generateYupValidationForPhone = () => {
  return yup
    .string()
    .matches(frenchPhoneFormatRegexp, "Le format est incorrect.")
    .nullable()
    .transform((value) => value || null);
};

const generateYupValidationForSingleSelectAndText = () => {
  return yup.string().nullable();
};

// Thank you, Julia, I love you : https://bionicjulia.com/blog/generating-yup-validation-object-with-map-function
const generateYupSchema = (questions: IComplementaryQuestion[]) => {
  const validationObject: { [key: string]: AnySchema } = {};
  questions.forEach((question) => {
    validationObject[question.enumValue.toString()] = generateYupValidation(question);
  });
  return yup.object(validationObject);
};

const commentSchema = yup.object().shape({
  comment: yup.string().max(2000, "Votre commentaire ne doit pas excéder 2000 caractères."),
});

export default function ComplementaryFormSection({
  section,
  canEdit,
  goToNextPage,
  goToPreviousPage,
  renderNext,
  renderPrevious,
}: Readonly<ComplementaryFormSectionProps>) {
  const user = useAtomValue(userAtom);
  const navigate = useNavigate();
  const [complementaryForm, setComplementaryForm] = useAtom(currentComplementaryFormAtom);
  const [tableRows, setTableRows] = useState<SPSTITableRow[]>([]);
  const [openExpertCommentModal, setOpenExpertCommentModal] = useState<boolean>(false);
  const [questionToComment, setQuestionToComment] = useState<IQuestionEnum | null>(null);
  const [comments, setComments] = useState<{ [k: string]: string }>({});

  const defaultAnswerValue = (questionEnumValue: IQuestionEnum) => {
    const { answersDictionary } = complementaryForm;
    const hasAnswer = answersDictionary[questionEnumValue];
    let defaultValue = "";
    if (hasAnswer) {
      defaultValue = hasAnswer.answers.map((answer) => answer.value).join(",");
    }
    return defaultValue;
  };

  const defaultWatchValues = () => {
    const watchInitialValues: { [k: string]: string } = {};
    section.questions.forEach((question) => {
      watchInitialValues[question.enumValue] = defaultAnswerValue(question.enumValue);
    });
    return watchInitialValues;
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(generateYupSchema(section.questions)),
    mode: "onTouched",
    defaultValues: defaultWatchValues(),
  });
  const watchAllFields = watch(); // when pass nothing as argument, you are watching everything

  const {
    register: registerComment,
    handleSubmit: handleSubmitComment,
    reset: resetComment,
    formState: { errors: errorsComment, isValid: isValidComment },
  } = useForm({
    resolver: yupResolver(commentSchema),
    mode: "onTouched",
  });

  const defaultTableAnswerValue = () => {
    const { answersDictionary } = complementaryForm;
    const hasAnswer = answersDictionary[IQuestionEnum.JOBS_TABLE];
    if (hasAnswer) {
      return hasAnswer.answers.reduce((acc: string[], current) => {
        acc.push(current.value);
        return acc;
      }, []);
    }
    return [];
  };

  useEffect(() => {
    reset();
    const defaultValue = defaultTableAnswerValue();
    const retrievedRows = defaultValue.map((value, index) => {
      const rowElements = value.split(",");
      const newRow = { id: index, jobs: rowElements[0] };
      return SPSTITableRowField.reduce((rowObject, field, i) => {
        const updatedRowObject = { ...rowObject };
        updatedRowObject[field] = rowElements[i + 1];
        return updatedRowObject;
      }, newRow);
    });
    setTableRows((retrievedRows as SPSTITableRow[]) ?? []);
    const updatedComments = {};
    const keys = Object.keys(complementaryForm.answersDictionary);
    keys.forEach((key) => {
      updatedComments[key] = complementaryForm.answersDictionary[key].expertComment;
    });
    setComments(updatedComments);
  }, []);

  useEffect(() => {
    resetComment();
  }, [openExpertCommentModal]);

  const getCurrentAnswerGroup = (key: string) => {
    const newAnswerGroup: IComplementaryAnswerGroup = {
      uuid: null,
      answers: [],
      expertComment: "",
    };
    return complementaryForm.answersDictionary[key] ?? newAnswerGroup;
  };

  const getCurrentAnswer = (answerGroup: IComplementaryAnswerGroup, key, answer: string | string[]) => {
    const updatedAnswerGroup = { ...answerGroup };
    updatedAnswerGroup.answers = [];
    if (Array.isArray(answer)) {
      const multipleAnswers = answer.map((value: string) => ({ uuid: null, value }));
      multipleAnswers.forEach((newAnswer: IComplementaryAnswer) => {
        if (newAnswer.value !== "") updatedAnswerGroup.answers.push(newAnswer);
      });
    } else if (key === IQuestionEnum.JOBS_TABLE) {
      // this means that for the table the answer array is null.
      updatedAnswerGroup.answers = [];
    } else {
      const newAnswer = { uuid: null, value: answer };
      updatedAnswerGroup.answers.push(newAnswer);
    }
    updatedAnswerGroup.expertComment = comments[key] ?? "";
    return updatedAnswerGroup;
  };

  const saveAnswers = (data: FieldValues) => {
    const keys = Object.keys(data);
    const updatedAnswers = { ...complementaryForm };
    if (section.enumValue === ISectionEnum.SPSTI_INFORMATION) {
      const tableAnswers: string[] = tableRows.map((row) => {
        const rowElements: string[] = Object.values(row);
        // we remove the index because it is not needed for the backend
        rowElements.splice(0, 1);
        return rowElements.join(",");
      });
      updatedAnswers.answersDictionary[IQuestionEnum.JOBS_TABLE] = getCurrentAnswer(
        getCurrentAnswerGroup(IQuestionEnum.JOBS_TABLE),
        IQuestionEnum.JOBS_TABLE,
        tableAnswers,
      );
    }
    keys.forEach((key) => {
      let answerValue = data[key];
      if (key === IQuestionEnum.ASBESTOS_TECHNICAL_REPORT.toString()) {
        answerValue = watchAllFields[key];
      }
      if (dateTimeValidator(answerValue)) {
        answerValue = answerValue?.toLocaleString();
      }
      if (key !== IQuestionEnum.JOBS_TABLE) {
        updatedAnswers.answersDictionary[key] = getCurrentAnswer(getCurrentAnswerGroup(key), key, answerValue);
      }
      const questionToCheck = section.questions.find((question) => question.enumValue.toString() === key);
      const parentEnum = questionToCheck?.parentValue;
      if (parentEnum) {
        // need to check parent value to see if we should reset child value or not
        if (data[parentEnum.toString()] === "Non") {
          updatedAnswers.answersDictionary[questionToCheck.enumValue].answers = [];
          updatedAnswers.answersDictionary[questionToCheck.enumValue].expertComment = "";
        }
      }
    });
    setComplementaryForm(updatedAnswers);
    goToNextPage();
  };

  const renderBooleanQuestion = (question: IComplementaryQuestion) => (
    <FormControl>
      <RadioGroup defaultValue={defaultAnswerValue(question.enumValue)} name="boolean">
        <FormControlLabel
          value="Oui"
          control={<Radio {...register(question.enumValue.toString())} />}
          label="Oui"
          disabled={!canEdit}
        />
        <FormControlLabel
          value="Non"
          control={<Radio {...register(question.enumValue.toString())} />}
          label="Non"
          disabled={!canEdit}
        />
      </RadioGroup>
    </FormControl>
  );

  const renderMultipleSelect = (question: IComplementaryQuestion) => {
    const defaultValues = defaultAnswerValue(question.enumValue);
    return (
      <FormControl sx={{ m: 3 }} variant="standard">
        <FormGroup>
          {question.options?.map((option) => (
            <FormControlLabel
              key={option}
              value={option}
              control={
                <Checkbox
                  disabled={!canEdit}
                  defaultChecked={defaultValues.includes(option)}
                  {...register(question.enumValue.toString())}
                />
              }
              label={option}
            />
          ))}
        </FormGroup>
      </FormControl>
    );
  };

  const renderSingleSelect = (question: IComplementaryQuestion) => {
    const defaultValue = watchAllFields[question.enumValue] ?? defaultAnswerValue(question.enumValue);
    return (
      <Stack spacing={3} alignItems="flex-start">
        {question.options?.map((option) => (
          <Stack key={option} direction="row" spacing={1} alignItems="center">
            <Radio
              sx={{ p: 0 }}
              {...register(question.enumValue.toString())}
              value={option}
              checked={option === defaultValue}
              disabled={!canEdit}
            />
            <Typography variant="body1" sx={{ color: canEdit ? colors.primary : colors.disabledText }}>
              {option}
            </Typography>
          </Stack>
        ))}
      </Stack>
    );
  };

  const renderDatePicker = (question: IComplementaryQuestion) => {
    const defaultString = defaultAnswerValue(question.enumValue);
    return (
      <GenericDatePicker
        label=""
        control={control}
        name={question.enumValue.toString()}
        error={!!errors[question.enumValue.toString()]}
        helperText={stringifyErrorMessage(errors[question.enumValue.toString()])}
        defaultValue={defaultString}
        disabled={!canEdit}
        maxDate={DateTime.now()}
      />
    );
  };

  const renderText = (question: IComplementaryQuestion, rows: number) => {
    const defaultValue = defaultAnswerValue(question.enumValue);
    return (
      <GenericTextField
        label=""
        defaultValue={defaultValue}
        id={question.enumValue}
        disabled={!canEdit}
        register={register(question.enumValue.toString())}
        error={!!errors[question.enumValue.toString()]}
        helperText={stringifyErrorMessage(errors[question.enumValue.toString()])}
        multiline
        placeholder={question.enumValue === IQuestionEnum.DUERP_DATE ? "AAAA" : ""}
        rows={rows}
      />
    );
  };

  const addEmptyNewRow = () => {
    const updatedNewRows = [...tableRows];
    const emptyNewRow = SPSTITableRowField.reduce(
      (rowObject, field) => {
        const updatedRowObject = { ...rowObject };
        updatedRowObject[field] = 0;
        return updatedRowObject;
      },
      { id: updatedNewRows.length, jobs: "" },
    );
    updatedNewRows.push(emptyNewRow as SPSTITableRow);
    setTableRows(updatedNewRows);
  };

  const renderTable = () => <ComplementaryFormSPSTITable tableRows={tableRows} setTableRows={setTableRows} />;

  const renderQuestion = (question: IComplementaryQuestion) => {
    switch (question.type) {
      case IQuestionType.BOOLEAN:
        return renderBooleanQuestion(question);
      case IQuestionType.DATE:
        return renderDatePicker(question);
      case IQuestionType.SELECT:
        return renderMultipleSelect(question);
      case IQuestionType.SINGLE_SELECT:
        return renderSingleSelect(question);
      case IQuestionType.TABLE:
        return renderTable();
      case IQuestionType.EMAIL:
      case IQuestionType.NUMBER:
      case IQuestionType.PHONE:
      case IQuestionType.TEXT:
        return renderText(question, 1);
      case IQuestionType.LONG_TEXT:
        return renderText(question, 5);
      default:
        return null;
    }
  };

  const shouldDisplayQuestion = (question: IComplementaryQuestion) => {
    if (question.parentValue) {
      const answer = defaultAnswerValue(question.parentValue);
      const watchValue = watchAllFields[question.parentValue];
      if (watchValue) {
        return watchValue === "Oui";
      }
      return answer === "Oui";
    }
    return true;
  };

  const renderComment = (questionEnum: IQuestionEnum) => {
    const comment = comments[questionEnum.toString()];
    if (comment)
      return (
        <CommentCard
          commentContent={comment}
          icon={faCommentDots}
          title="Commentaire préventeur"
          backgroundColor={colors.cardBackground}
        />
      );
    return null;
  };

  const renderSection = () => {
    const disabled = tableRows.length === maxSPSTILines;
    return (
      <Grid container spacing={3}>
        {section.questions.map(
          (question) =>
            shouldDisplayQuestion(question) && (
              <Grid key={question.enumValue.toString()} item xs={12}>
                <Stack spacing={1}>
                  <Stack direction="row">
                    <Typography variant="body2">
                      {question.label}
                      {user.role !== Role.COMPANY_USER && (
                        <GenericIconButton
                          onClick={() => {
                            setQuestionToComment(question.enumValue);
                            setOpenExpertCommentModal(true);
                          }}
                          icon={faComments}
                          tooltip="Ajouter un commentaire"
                          color={colors.mainOrange}
                          disabled={!canEdit}
                        />
                      )}
                    </Typography>
                    {question.type === IQuestionType.TABLE && (
                      <GenericIconButton
                        onClick={() => addEmptyNewRow()}
                        icon={faPlusCircle}
                        tooltip={disabled ? `Vous avez déjà ajouté ${maxSPSTILines} éléments` : "Ajouter une ligne"}
                        disabled={disabled || !canEdit}
                      />
                    )}
                  </Stack>
                  {renderQuestion(question)}
                  {user.role !== Role.COMPANY_USER && renderComment(question.enumValue)}
                </Stack>
              </Grid>
            ),
        )}
      </Grid>
    );
  };

  const buttonPosition = () => (!renderPrevious ? "flex-end" : "space-between");

  const goToHomePage = () => {
    if (user.role === Role.COMPANY_USER) navigate(frontOfficeRoutes.home);
    else navigate(backOfficeRoutes.home);
  };

  const addComment = (data: FieldValues) => {
    const updatedComments = { ...comments };
    if (questionToComment) {
      updatedComments[questionToComment] = data.comment;
    }
    setComments(updatedComments);
    setQuestionToComment(null);
    setOpenExpertCommentModal(false);
    resetComment();
  };

  const renderExpertCommentModal = () => {
    let expertComment = "";
    if (questionToComment) {
      expertComment = comments[questionToComment.toString()] ?? "";
    }
    return (
      <GenericDialog
        title="Ajouter un commentaire préventeur à cette réponse"
        openDialog={openExpertCommentModal}
        handleClose={() => {
          setQuestionToComment(null);
          setOpenExpertCommentModal(false);
          resetComment();
        }}
        onValid={handleSubmitComment(addComment)}
        disabled={!isValidComment}
      >
        <GenericTextField
          multiline
          rows={4}
          placeholder="Ajoutez un commentaire"
          label="Commentaire préventeur"
          id="expert_comment"
          register={registerComment("comment")}
          error={!!errorsComment.comment}
          helperText={stringifyErrorMessage(errorsComment.comment)}
          defaultValue={expertComment}
        />
      </GenericDialog>
    );
  };

  return (
    <Stack sx={{ mx: 2 }}>
      {openExpertCommentModal && renderExpertCommentModal()}
      <Typography variant="h3" sx={{ mb: 5 }}>
        {section.label}
      </Typography>
      {section.enumValue === ISectionEnum.END ? <ComplementaryFormEndPage /> : renderSection()}
      <Stack direction="row" alignItems="center" justifyContent={buttonPosition} sx={{ mt: 5 }}>
        {renderPrevious && <GenericButton onClick={() => goToPreviousPage()} text="Précédent" />}
        {renderNext && !canEdit && <GenericButton onClick={() => goToNextPage()} text="Continuer" />}
        {renderNext && canEdit && <GenericButton onClick={handleSubmit(saveAnswers)} text="Enregistrer et continuer" />}
        {section.enumValue === ISectionEnum.END && (
          <GenericButton onClick={goToHomePage} text="Retour à l'espace entreprise" />
        )}
      </Stack>
    </Stack>
  );
}
