export enum Role {
  ALL = "ALL",
  COMPANY_USER = "COMPANY_USER",
  EXPERT = "EXPERT",
  ADMIN = "ADMIN",
  UNDEFINED = "UNDEFINED",
}

export interface IUserLogin {
  email: string;
  password: string;
  acceptedLastCGU?: boolean;
}

export interface IUserShort {
  uuid: string;
  role: Role;
}
