import { faCheckDouble, faChevronDown, faClockRotateLeft, faCommentDots, faComments } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import { useAtom, useAtomValue } from "jotai";
import { DateTime } from "luxon";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { currentDuerpAtom, userAtom } from "../../../../../atoms/Atoms";
import { AnswerType, IDuerpAnswer, IDuerpLightQuestion, IDuerpQuestion } from "../../../../../interfaces/DuerpForm";
import { ActionPriority, ProgressStatus } from "../../../../../interfaces/Form";
import { Role } from "../../../../../interfaces/User";
import { emptyQuestion } from "../../../../../resources/AppConstants";
import colors from "../../../../../resources/cssConstant";
import { StatusCode } from "../../../../../resources/StatusCode";
import DuerpService from "../../../../../services/DuerpService";
import { dateTimeValidator, stringifyErrorMessage } from "../../../../../utils/ConversionMethods";
import { LinkifyText } from "../../../../../utils/LinkifyText";
import GenericButton from "../../../../Generics/buttons/GenericButton";
import GenericDatePicker from "../../../../Generics/GenericDatePicker/GenericDatePicker";
import GenericDialog from "../../../../Generics/GenericDialog/GenericDialog";
import GenericTextField from "../../../../Generics/GenericTextField/GenericTextField";
import CommentCard from "../../../CommentCard/CommentCard";
import MeasuresCard from "./containers/MeasuresCard/MeasuresCard";
import RiskCard from "./containers/RiskCard/RiskCard";

interface DuerpQuestionProps {
  question: IDuerpLightQuestion;
  sectionUuid: string;
  setQuestion: Dispatch<SetStateAction<IDuerpQuestion>>;
  updateTree: () => void;
  goToPreviousPage: () => void;
  goToNextPage: () => void;
}

const futureMeasureSchema = yup.object().shape({
  measureTitle: yup
    .string()
    .max(2000, "Votre description ne doit pas excéder 2000 caractères.")
    .required("Veuillez remplir ce champ pour ajouter une mesure."),
  measureDate: yup
    .mixed()
    .nullable()
    .test("is-date-time", "Invalid date format", (value) => (value ? dateTimeValidator(value) : true))
    .transform((value) => value || null),
  measureDescription: yup.string().nullable().max(2000, "Votre description ne doit pas excéder 2000 caractères."),
});

const actualMeasureSchema = yup.object().shape({
  measureTitle: yup
    .string()
    .max(2000, "Votre description ne doit pas excéder 2000 caractères.")
    .required("Veuillez remplir ce champ pour ajouter une mesure."),
});

const commentSchema = yup.object().shape({
  comment: yup.string().max(2000, "Votre commentaire ne doit pas excéder 2000 caractères."),
});

const expertGeneralCommentSchema = yup.object().shape({
  comment: yup.string().max(2000, "Votre commentaire ne doit pas excéder 2000 caractères."),
});

export default function DuerpQuestion({
  question,
  sectionUuid,
  setQuestion,
  updateTree,
  goToPreviousPage,
  goToNextPage,
}: Readonly<DuerpQuestionProps>) {
  const user = useAtomValue(userAtom);
  const [duerp, setDuerp] = useAtom(currentDuerpAtom);
  const [questionData, setQuestionData] = useState<IDuerpQuestion>(emptyQuestion);
  const [openFutureMeasureModal, setOpenFutureMeasureModal] = useState<boolean>(false);
  const [openActualMeasureModal, setOpenActualMeasureModal] = useState<boolean>(false);
  const [openCompanyCommentModal, setOpenCompanyCommentModal] = useState<boolean>(false);
  const [openExpertCommentModal, setOpenExpertCommentModal] = useState<boolean>(false);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const {
    register: registerFuture,
    control: controlFuture,
    handleSubmit: handleSubmitFuture,
    reset: resetFuture,
    formState: { errors: errorsFuture, isValid: isValidFuture },
  } = useForm({
    resolver: yupResolver(futureMeasureSchema),
    mode: "onTouched",
  });
  const {
    register: registerActual,
    handleSubmit: handleSubmitActual,
    reset: resetActual,
    formState: { errors: errorsActual, isValid: isValidActual },
  } = useForm({
    resolver: yupResolver(actualMeasureSchema),
    mode: "onTouched",
  });
  const {
    register: registerComment,
    handleSubmit: handleSubmitComment,
    reset: resetComment,
    formState: { errors: errorsComment, isValid: isValidComment },
  } = useForm({
    resolver: yupResolver(commentSchema),
    mode: "onTouched",
  });
  const {
    register: registerExpertGeneralComment,
    handleSubmit: handleSubmitExpertGeneralComment,
    reset: resetExpertGeneralComment,
    formState: { errors: errorsExpertGeneralComment, isValid: isValidExpertGeneralComment },
  } = useForm({
    resolver: yupResolver(expertGeneralCommentSchema),
    mode: "onTouched",
  });
  const updateActionPriority = (priority: ActionPriority) => {
    const updatedQuestion = { ...questionData };
    updatedQuestion.actionPriority = priority;
    setQuestionData(updatedQuestion);
    setIsUpdated(true);
  };

  const fetchQuestionData = async () => {
    const res = await DuerpService().getDuerpQuestion(duerp.uuid, sectionUuid, question.uuid);
    if (res.data) {
      setQuestionData(res.data);
    }
  };

  useEffect(() => {
    resetActual();
  }, [openActualMeasureModal]);
  useEffect(() => {
    resetFuture();
  }, [openFutureMeasureModal]);
  useEffect(() => {
    resetComment();
  }, [openCompanyCommentModal]);
  useEffect(() => {
    resetExpertGeneralComment();
  }, [openExpertCommentModal]);
  useEffect(() => {
    setIsUpdated(false);
    setLoading(true);
    fetchQuestionData().then(
      () => {
        setLoading(false);
      },
      () => {
        setLoading(false);
      },
    );
  }, [question.uuid]);

  const saveQuestion = async () => {
    const dtoData = { ...questionData };
    if (isUpdated && duerp.progressStatus === ProgressStatus.NOT_FINALIZED) {
      const res = await DuerpService().updateDuerpQuestion(dtoData, duerp.uuid, sectionUuid, question.uuid);
      if (res.status === StatusCode.OK) {
        updateTree();
        goToNextPage();
      } else {
        toast.error(
          "Impossible de sauvegarder vos réponses pour le moment. Veuillez rafraîchir la page et ne pas continuer l’édition des autres questions."
        );
      }
    } else {
      goToNextPage();
    }
    setIsUpdated(false);
  };

  const addMeasure = (data: FieldValues) => {
    const updatedQuestion = { ...questionData };
    const measureToAdd: IDuerpAnswer = {
      checkedByCompany: user.role === Role.COMPANY_USER,
      checkedByExpert: false,
      oiraAnswerUuid: "",
      duerpQuestionUuid: question.uuid,
      currentlyInPlace: openActualMeasureModal,
      expertComment: "",
      answerType: openFutureMeasureModal ? AnswerType.FUTURE : AnswerType.CURRENT,
      title: data.measureTitle,
      uuid: "",
      description: data.measureDescription,
      date: data.measureDate ? data.measureDate.toISODate() : "",
    };
    if (openFutureMeasureModal) {
      updatedQuestion.futureMeasures.push(measureToAdd);
      setOpenFutureMeasureModal(false);
    } else {
      updatedQuestion.currentMeasures.push(measureToAdd);
      setOpenActualMeasureModal(false);
    }
    setQuestion(updatedQuestion);
    setIsUpdated(true);
  };

  const deleteMeasure = (field: string) => (measureIndex: number) => {
    const updatedQuestion = { ...questionData };
    updatedQuestion[field].splice(measureIndex, 1);
    setQuestionData(updatedQuestion);
    setIsUpdated(true);
  };

  const editGeneralComment = (field: string) => (data: FieldValues) => {
    const updatedQuestion = { ...questionData, [field]: data.comment };
    // workaround because for some reason the other comment field was reset...
    if (field === "expertComment") {
      updatedQuestion.expertComment = data.comment;
      updatedQuestion.companyComment = questionData.companyComment;
    } else {
      updatedQuestion.companyComment = data.comment;
      updatedQuestion.expertComment = questionData.expertComment;
    }
    setOpenCompanyCommentModal(false);
    setOpenExpertCommentModal(false);
    setQuestionData(updatedQuestion);
    setIsUpdated(true);
  };

  const addExpertCommentToMeasure = (field: string) => (measureIndex: number, comment: string) => {
    const updatedQuestion = { ...questionData };
    updatedQuestion[field][measureIndex].expertComment = comment;
    setQuestionData(updatedQuestion);
    setIsUpdated(true);
  };

  const checkMeasure = (field: string) => (checked: boolean, isExpert: boolean, measureIndex: number) => {
    const updatedQuestion = { ...questionData };
    if (isExpert) updatedQuestion[field][measureIndex].checkedByExpert = checked;
    else updatedQuestion[field][measureIndex].checkedByCompany = checked;
    setQuestionData(updatedQuestion);
    setIsUpdated(true);
  };

  const updateRiskManagement = (isRiskManaged: boolean) => {
    const updatedQuestion = { ...questionData };
    updatedQuestion.riskManaged = isRiskManaged;
    updatedQuestion.actionPriority = isRiskManaged ? ActionPriority.LOW : ActionPriority.HIGH;
    setQuestionData(updatedQuestion);
    setIsUpdated(true);
  };

  const renderActualMeasureModal = () => (
    <GenericDialog
      title="Ajouter une mesure mise en place"
      openDialog={openActualMeasureModal}
      handleClose={() => setOpenActualMeasureModal(false)}
      onValid={handleSubmitActual(addMeasure)}
      disabled={!isValidActual}
    >
      <GenericTextField
        id="measureTitle"
        label="Intitulé de la mesure"
        multiline
        rows={4}
        placeholder="Décrivez la mesure mise en place"
        register={registerActual("measureTitle")}
        error={!!errorsActual.measureTitle}
        helperText={stringifyErrorMessage(errorsActual.measureTitle)}
      />
    </GenericDialog>
  );
  const renderFutureMeasureModal = () => {
    let autocompleteOptions: string[];
    if (user.role === Role.COMPANY_USER) {
      autocompleteOptions = questionData.currentMeasures
        .filter((answer) => !answer.checkedByCompany)
        .map((answer) => answer.title);
    } else {
      autocompleteOptions = questionData.currentMeasures
        .filter(
          (answer) =>
            (!answer.checkedByCompany && !answer.checkedByExpert) ||
            (answer.checkedByExpert && !answer.checkedByCompany) ||
            (!answer.checkedByExpert && answer.checkedByCompany)
        )
        .map((answer) => answer.title);
    }
    const futureMeasures = questionData.futureMeasures.map((futureMeasure) => futureMeasure.title);
    const options =
      futureMeasures.length === 0
      ? autocompleteOptions
      : autocompleteOptions.filter((option) => !futureMeasures.find((obj) => obj === option));
    return (
      <GenericDialog
        title="Ajouter une mesure au plan d'action"
        openDialog={openFutureMeasureModal}
        handleClose={() => setOpenFutureMeasureModal(false)}
        onValid={handleSubmitFuture(addMeasure)}
        disabled={!isValidFuture}
      >
        <Stack spacing={2}>
          <Autocomplete
            freeSolo
            options={options}
            renderInput={({ inputProps, ...restParams }) => (
              <TextField
                variant="outlined"
                label="Intitulé de la mesure"
                {...restParams}
                required
                inputProps={{
                  ...inputProps,
                  placeholder: "Sélectionnez ou ajoutez une mesure"
                }}
                {...registerFuture("measureTitle")}
                helperText={stringifyErrorMessage(errorsFuture.measureTitle)}
                error={!!errorsFuture.measureTitle}
              />
            )}
            popupIcon={<FontAwesomeIcon icon={faChevronDown} />}
          />
          <GenericDatePicker
            name="measureDate"
            label="Date prévue"
            minDate={DateTime.now()}
            control={controlFuture}
            error={!!errorsFuture.measureDate}
            helperText={stringifyErrorMessage(errorsFuture.measureDate)}
            defaultValue=""
          />
          <GenericTextField
            label="Description de la mesure"
            multiline
            rows={4}
            placeholder="Ajoutez des détails concernant la mesure à ajouter au plan d'action : budget, responsable, ressources..."
            id="measureDescription"
            register={registerFuture("measureDescription")}
            error={!!errorsFuture.measureDescription}
            helperText={stringifyErrorMessage(errorsFuture.measureDescription)}
          />
        </Stack>
      </GenericDialog>
    );
  };
  const renderCompanyGeneralCommentModal = () => (
    <GenericDialog
      title="Ajouter un commentaire général"
      openDialog={openCompanyCommentModal}
      handleClose={() => setOpenCompanyCommentModal(false)}
      onValid={handleSubmitComment(editGeneralComment("companyComment"))}
      disabled={!isValidComment}
    >
      <GenericTextField
        multiline
        rows={4}
        defaultValue={questionData.companyComment}
        placeholder="Ajoutez des éléments supplémentaires concernant ce risque"
        label="Détails du risque"
        register={registerComment("comment")}
        error={!!errorsComment.comment}
        helperText={stringifyErrorMessage(errorsComment.comment)}
        id="comment"
      />
    </GenericDialog>
  );
  const renderExpertCommentModal = () => (
    <GenericDialog
      title="Ajouter un commentaire préventeur général"
      openDialog={openExpertCommentModal}
      handleClose={() => setOpenExpertCommentModal(false)}
      onValid={handleSubmitExpertGeneralComment(editGeneralComment("expertComment"))}
      disabled={!isValidExpertGeneralComment}
    >
      <GenericTextField
        multiline
        rows={4}
        placeholder="Ajoutez des éléments supplémentaires concernant ce risque"
        label="Détails du risque"
        id="risk_details"
        defaultValue={questionData.expertComment}
        register={registerExpertGeneralComment("comment")}
        error={!!errorsExpertGeneralComment.comment}
        helperText={stringifyErrorMessage(errorsExpertGeneralComment.comment)}
      />
    </GenericDialog>
  );

  const getNextBtnText = () => {
    if (duerp.progressStatus === ProgressStatus.FINALIZED) return "Suivant";
    return isUpdated ? "Enregistrer et continuer" : "Continuer";
  };
  return (
    <>
      {renderActualMeasureModal()}
      {renderFutureMeasureModal()}
      {renderCompanyGeneralCommentModal()}
      {renderExpertCommentModal()}
      <Stack spacing={5}>
        <Typography variant="question">{questionData.title}</Typography>
        <Typography variant="body1" sx={{ width: "90%" }}>
          {LinkifyText(questionData.description)}
        </Typography>
        <MeasuresCard
          title="Sélectionnez les mesures actuellement mises en place"
          icon={faCheckDouble}
          addMeasure={() => setOpenActualMeasureModal(true)}
          deleteMeasure={deleteMeasure("currentMeasures")}
          measures={questionData.currentMeasures}
          checkMeasure={checkMeasure("currentMeasures")}
          addExpertComment={addExpertCommentToMeasure("currentMeasures")}
        />
        <MeasuresCard
          title="Les mesures déjà en place sont-elles suffisantes ?"
          icon={faClockRotateLeft}
          addMeasure={() => setOpenFutureMeasureModal(true)}
          deleteMeasure={deleteMeasure("futureMeasures")}
          measures={questionData.futureMeasures}
          checkMeasure={checkMeasure("futureMeasures")}
          updateRiskManagement={updateRiskManagement}
          isRiskManaged={questionData.riskManaged ?? false}
          addExpertComment={addExpertCommentToMeasure("futureMeasures")}
        />
        <CommentCard
          commentContent={questionData.companyComment}
          icon={faCommentDots}
          title="Remarque de l'entreprise"
          editComment={() => setOpenCompanyCommentModal(true)}
        />
        {(questionData.expertComment || user.role !== Role.COMPANY_USER) && (
          <CommentCard
            title="Remarque du préventeur"
            icon={faComments}
            commentContent={questionData.expertComment}
            editComment={user.role === Role.COMPANY_USER ? undefined : () => setOpenExpertCommentModal(true)}
            backgroundColor={colors.cardBackground}
          />
        )}
        <RiskCard
          actionPriority={questionData.actionPriority ?? ActionPriority.HIGH}
          updatePriority={updateActionPriority}
        />
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mt: 5 }}>
          <GenericButton onClick={() => goToPreviousPage()} text="Précédent" />
          <GenericButton onClick={() => saveQuestion()} text={getNextBtnText()} />
        </Stack>
      </Stack>
    </>
  );
}
