import { generatePath, useNavigate } from "react-router-dom";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { useAtomValue } from "jotai";
import GenericIconButton from "../../../Generics/buttons/GenericIconButton";
import { Role } from "../../../../interfaces/User";
import { backOfficeRoutes, frontOfficeRoutes } from "../../../../resources/Routes";
import { IShortForm, ProgressStatus, Type } from "../../../../interfaces/Form";
import { userShortAtom } from "../../../../atoms/Atoms";
import { ICompany, ICompanyShort } from "../../../../interfaces/Company";

interface OpenDocumentButtonProps {
  document: IShortForm;
  company?: ICompanyShort | ICompany;
  onClick?: () => void;
}

export default function OpenDocumentButton({ document, company, onClick }: Readonly<OpenDocumentButtonProps>) {
  const navigate = useNavigate();
  const user = useAtomValue(userShortAtom);
  const documentIsFinalized = document.progressStatus !== ProgressStatus.NOT_FINALIZED;
  const openDocument = () => {
    const field = document.documentType === Type.DUERP ? "duerp" : "complementary_form";
    const path = user.role === Role.COMPANY_USER ? frontOfficeRoutes[field] : backOfficeRoutes[field];
    navigate(generatePath(path, { refNumber: document.reference.replaceAll("/", "%2F") }), {
      state: { company, uuid: document.uuid },
    });
  };

  return (
    <GenericIconButton
      onClick={() => {
        if (onClick) {
          onClick();
        }
        openDocument();
      }}
      icon={documentIsFinalized ? faEye : faEdit}
      tooltip={documentIsFinalized ? "Consulter le document" : "Modifier le document"}
    />
  );
}

OpenDocumentButton.defaultProps = {
  company: undefined,
  onClick: undefined,
};
