import { Accordion, AccordionSummary, AccordionDetails, Typography, Stack, Grid } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import * as yup from "yup";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import GenericIconButton from "../../../../Generics/buttons/GenericIconButton";
import { IOiraForm, IOiraSection } from "../../../../../interfaces/OiraForm";
import colors from "../../../../../resources/cssConstant";
import GenericDialog from "../../../../Generics/GenericDialog/GenericDialog";
import GenericTextField from "../../../../Generics/GenericTextField/GenericTextField";
import { stringifyErrorMessage } from "../../../../../utils/ConversionMethods";
import FormService from "../../../../../services/FormService";
import { LinkifyText } from "../../../../../utils/LinkifyText";

interface OiraSectionProps {
  oiraUuid: string;
  section: IOiraSection;
  setOira: (oira: IOiraForm) => void;
}

interface IOiraUpdateDescription {
  uuid: string;
  descriptionTitle: string;
  description: string;
  isSection: boolean;
}

const descriptionSchema = yup.object().shape({
  description: yup.string().max(2000, "Votre description ne doit pas excéder 2000 caractères."),
});

export default function OiraSection({ oiraUuid, section, setOira }: Readonly<OiraSectionProps>) {
  const [openDescriptionModal, setOpenDescriptionModal] = useState<boolean>(false);
  const [element, setElement] = useState<IOiraUpdateDescription>({
    uuid: "",
    descriptionTitle: "",
    description: "",
    isSection: false,
  });

  const {
    register: registerDescription,
    handleSubmit: handleSubmitDescription,
    reset: resetDescription,
    formState: { errors: errorsDescription },
  } = useForm({
    resolver: yupResolver(descriptionSchema),
    mode: "onTouched",
  });

  useEffect(() => {
    resetDescription();
  }, [openDescriptionModal]);

  const editDescription = async (data: FieldValues) => {
    let res;
    if (element?.isSection) {
      res = await FormService().updateOiraSectionDescription(oiraUuid, element?.uuid, data.description);
    } else {
      res = await FormService().updateOiraQuestionDescription(oiraUuid, element?.uuid, data.description);
    }
    if (res.data) {
      setOira(res.data);
      toast.success("Vos modifications ont bien été enregistrées.");
    } else {
      toast.error("Vos modifications n'ont pas pu être enregistrées.");
    }
    setOpenDescriptionModal(false);
  };

  const renderQuestions = (sectionToRender: IOiraSection) =>
    sectionToRender.questions?.map((question) => (
      <Stack key={question.uuid} spacing={1}>
        <Stack direction="row" alignItems="center" spacing={2} width="90%">
          <FontAwesomeIcon icon={faQuestionCircle} size="lg" color={colors.primary} />
          <Typography variant="subQuestion">{question.title}</Typography>
        </Stack>
        <Grid container>
          <Grid item xs={11}>
            <Typography variant="body1">{LinkifyText(question.description)}</Typography>
          </Grid>
          <Grid item>
            <GenericIconButton
              onClick={() => {
                setElement({
                  uuid: question.uuid,
                  descriptionTitle: question.title,
                  description: question.description,
                  isSection: false,
                });
                setOpenDescriptionModal(true);
              }}
              icon={faEdit}
              tooltip="Modifier la description de la question"
              size="1x"
            />
          </Grid>
        </Grid>
      </Stack>
    ));

  const renderAccordion = (sectionToDisplay: IOiraSection, spacing: number) => (
    <Accordion
      sx={{
        p: 1,
        svg: colors.primary,
        boxShadow: 3,
        m: spacing,
        borderRadius: 5,
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: colors.primary }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="question">{sectionToDisplay.title}</Typography>
          <GenericIconButton
            onClick={() => {
              setElement({
                uuid: sectionToDisplay.uuid,
                descriptionTitle: sectionToDisplay.title,
                description: sectionToDisplay.description,
                isSection: true,
              });
              setOpenDescriptionModal(true);
            }}
            icon={faEdit}
            tooltip="Modifier la description de la section"
            size="1x"
          />
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={4}>
          {sectionToDisplay.childSections?.length !== 0
            ? section.childSections?.map((subsection) => renderAccordion(subsection, 1))
            : renderQuestions(sectionToDisplay)}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );

  const renderDialogContent = () => (
    <GenericDialog
      openDialog={openDescriptionModal}
      handleClose={() => {
        resetDescription();
        setOpenDescriptionModal(false);
      }}
      title="Modifier une description"
      onValid={handleSubmitDescription(editDescription)}
    >
      <Stack spacing={2} width="100%">
        <Typography variant="subQuestion">{element?.descriptionTitle}</Typography>
        <GenericTextField
          multiline
          label="Description du risque"
          placeholder="Ajoutez des éléments supplémentaires concernant ce risque"
          register={registerDescription("description")}
          error={!!errorsDescription.description}
          helperText={stringifyErrorMessage(errorsDescription.description)}
          defaultValue={element?.description}
          id="description"
        />
      </Stack>
    </GenericDialog>
  );

  return (
    <>
      {renderDialogContent()}
      {renderAccordion(section, 2)}
    </>
  );
}
