import {
  IDuerpForm,
  IDuerpLightQuestion,
  IDuerpLightSection,
  IDuerpQuestion,
  IDuerpSection,
} from "../interfaces/DuerpForm";

export interface IPosition {
  currentSectionIndex: number;
  currentSubSectionIndex: number;
  currentQuestionIndex: number;
}

export const implicationSection: IDuerpSection = {
  customRisksSection: false,
  uuid: "employee_implication",
  description:
    "Les salariés sont des acteurs essentiels à la mise en œuvre de la démarche de prévention. Leur connaissance pratique des postes de travail leur donne un rôle important notamment pour identifier les risques, pour suggérer des améliorations et proposer des mesures de prévention adaptées à leur activité." +
    "\n\n" +
    "L’implication des salariés est un gage de réussite et d’efficacité de la démarche de prévention." +
    "\n\n" +
    "A vous de décider de la meilleure manière d’associer votre personnel : vous pouvez par exemple organiser une réunion ou un groupe de réflexion avec les salariés et passer en revue les différentes questions proposées dans l’outil. Pour cela, vous pouvez télécharger ou imprimer le contenu de l’outil et le partager pour échanger avec vos salariés et préparer votre évaluation des risques.",
  companyConcerned: true,
  optional: false,
  completed: false,
  title: "Implication des salariés",
};

export const recapSection: IDuerpSection = {
  customRisksSection: false,
  description: "",
  uuid: "recap_section",
  companyConcerned: true,
  optional: false,
  title: "Informations du DUERP",
  completed: false,
};

export const validationSection: IDuerpSection = {
  customRisksSection: false,
  description: "",
  uuid: "validation_section",
  companyConcerned: true,
  optional: false,
  title: "Valider le DUERP",
  completed: false,
};

export const endSection: IDuerpSection = {
  customRisksSection: false,
  description: "",
  uuid: "end_section",
  companyConcerned: true,
  optional: false,
  title: "Fin du DUERP",
  completed: false,
};

export interface INavigationElement {
  element: IDuerpLightSection | IDuerpSection | IDuerpLightQuestion;
  position: IPosition;
}

const flattenSection = (section: IDuerpLightSection, sectionIndex: number) => {
  const flattenedSection: INavigationElement[] = [];
  const pushElement = (
    element: IDuerpLightQuestion | IDuerpLightSection,
    subsectionIndex: number,
    questionIndex: number,
  ) => {
    flattenedSection.push({
      element,
      position: {
        currentSectionIndex: sectionIndex,
        currentSubSectionIndex: subsectionIndex,
        currentQuestionIndex: questionIndex,
      },
    });
  };
  pushElement(section, -1, -1);
  if (section.subSections?.length !== 0 && section.subSections !== null && section.subSections !== undefined) {
    section.subSections.forEach((subsection, subsectionIndex) => {
      pushElement(subsection, subsectionIndex, -1);
      subsection.questions?.forEach((question, questionIndex) => {
        pushElement(question, subsectionIndex, questionIndex);
      });
    });
  } else if (section.questions?.length !== 0 && section.questions !== null && section.questions !== undefined) {
    section.questions.forEach((question, questionIndex) => {
      pushElement(question, -1, questionIndex);
    });
  }
  return flattenedSection;
};

export const flattenDuerp = (duerp: IDuerpForm) => {
  let navigationList: INavigationElement[] = [
    {
      element: recapSection,
      position: {
        currentSectionIndex: -2,
        currentSubSectionIndex: -1,
        currentQuestionIndex: -1,
      },
    },
    {
      element: implicationSection,
      position: {
        currentSectionIndex: -1,
        currentSubSectionIndex: -1,
        currentQuestionIndex: -1,
      },
    },
  ];
  duerp.sections.forEach((section, index) => {
    navigationList = navigationList.concat(flattenSection(section, index));
  });
  navigationList.push({
    element: validationSection,
    position: {
      currentSectionIndex: -3,
      currentSubSectionIndex: -1,
      currentQuestionIndex: -1,
    },
  });
  navigationList.push({
    element: endSection,
    position: {
      currentSectionIndex: -4,
      currentSubSectionIndex: -1,
      currentQuestionIndex: -1,
    },
  });
  return navigationList;
};
