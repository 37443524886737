import { Stack, Typography } from "@mui/material";
import { IOrganization } from "../../../interfaces/Organization";

interface LegalNoticePartOneProps {
  organization: IOrganization;
}
export default function LegalNoticePartOne({ organization }: Readonly<LegalNoticePartOneProps>) {
  return (
    <Stack spacing={1}>
      <Typography variant="h3">1. Définitions</Typography>
      <Typography>
        Les termes employés ci-après ont, dans les présentes Conditions générales d’utilisation, la signification
        suivante :
      </Typography>
      <Typography>
        <b>Adhérent :</b> désigne toute personne physique ou morale adhérente à {organization.organizationName} ayant
        obtenu l’accès à la solution web MONDUERP.
      </Typography>
      <Typography>
        <b>Espace entreprise :</b> désigne l'espace personnel mis à la disposition de l’Adhérent lui permettant, après
        s’être identifié grâce à ses codes d’accès, d’accéder à la solution web MONDUERP.
      </Typography>
      <Typography>
        <b>CGU :</b> désignent les présentes Conditions Générales d’Utilisation.
      </Typography>
      <Typography>
        <b>DUERP :</b> désigne le document unique d'évaluation des risques professionnels au sein duquel tout employeur
        doit consigner le résultat de l’évaluation des risques pour la santé et la sécurité auxquels peuvent être
        exposés les salariés.
      </Typography>
      <Typography>
        <b>Editeur :</b> désigne l’AMETRA06 (association régie par la loi de 1901, dont le siège social est situé 2-4
        rue Jules Belleudy, 06203 Nice Cédex).
      </Typography>
      <Typography>
        <b>Plateforme MONDUERP :</b> désigne la solution éditée par l’AMETRA06 accessible à l’adresse{" "}
        {<a href={window.location.origin}>{window.location.origin}</a>}
      </Typography>
      <Typography>
        <b>Utilisateur :</b> désigne toute personne qui navigue sur la Plateforme.
      </Typography>
      <Typography>
        <b>Services :</b> désignent l’ensemble des Services disponibles sur la plateforme MONDUERP permettant
        l’élaboration de la fiche d’entreprise par {organization.organizationName} et le Document Unique d’Évaluation
        des Risques Professionnels par l’Adhérent.
      </Typography>
      <Typography>
        <b>Responsable de traitement :</b> L'adhérent utilisateur de la plateforme MONDUERP.
      </Typography>
      <Typography>
        {organization.organizationName} : {organization.companyName} - {organization.rcsregistration} -{" "}
        {organization.address}
      </Typography>
    </Stack>
  );
}
