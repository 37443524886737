/* eslint-disable react/jsx-key */
// === Import NPM
import React from "react";
import { Box, Stack, Skeleton, styled } from "@mui/material";

// === Import LOCAL

const SkeletonCell = styled(Stack)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "center",
  height: "50px",
  py: 2,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

function LoadingSkeleton() {
  return (
    <Box
      sx={{
        height: "max-content",
      }}
    >
      {React.Children.toArray(
        [...Array(10)].map((index) => (
          <SkeletonCell key={index}>
            <Skeleton sx={{ mx: 1 }} width="90%" />
          </SkeletonCell>
        )),
      )}
    </Box>
  );
}

export default LoadingSkeleton;
