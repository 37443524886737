import axios, { AxiosInstance, InternalAxiosRequestConfig } from "axios";

class HttpService {
  axiosApiInstance: AxiosInstance;

  token = "";

  constructor() {
    this.axiosApiInstance = axios.create();
  }

  async fetch(config: any) {
    return new Promise<any>((resolve) => {
      this.axiosApiInstance(config)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          resolve(err);
        });
    });
  }

  authInterceptor = () => (config: InternalAxiosRequestConfig) => {
    const retrievedConfig = config;
    if (retrievedConfig.headers) {
      retrievedConfig.headers.Authorization = `Bearer ${this.token}`;
    }
    return retrievedConfig;
  };

  /**
   * Reset axios instance
   */
  resetAxiosInstance = () => {
    this.axiosApiInstance = axios.create();
  };

  /**
   * Removing authorization interceptor.
   */
  clearToken = () => {
    this.resetAxiosInstance();
    this.token = "";
  };

  setToken(newToken: string | null) {
    if (newToken != null) {
      this.token = newToken;
    } else {
      this.clearToken();
      return;
    }
    this.axiosApiInstance.interceptors.request.use(this.authInterceptor());
  }
}

export default new HttpService();
