import { Stack, Step, StepButton, StepIconProps, StepLabel, Stepper, SvgIcon, Typography } from "@mui/material";
import { CheckCircle, Edit } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useAtomValue } from "jotai";
import colors from "../../../../../resources/cssConstant";
import { IComplementarySection, IQuestionEnum } from "../../../../../interfaces/ComplementaryForm";
import StepperCircularProgress from "../../../../Generics/CircularProgress/StepperCircularProgress";
import { currentComplementaryFormAtom, userAtom } from "../../../../../atoms/Atoms";
import { Role } from "../../../../../interfaces/User";

interface ComplementaryFormStepperProps {
  sections: IComplementarySection[];
  navigationIndex: number;
  setNavigationIndex: (index: number) => void;
}

export default function ComplementaryFormStepper({
  sections,
  navigationIndex,
  setNavigationIndex,
}: Readonly<ComplementaryFormStepperProps>) {
  const user = useAtomValue(userAtom);
  const [progressValue, setProgressValue] = useState<number>(0);
  const complementaryForm = useAtomValue(currentComplementaryFormAtom);
  const spstiSections: IQuestionEnum[] = [
    IQuestionEnum.JOBS_TABLE,
    IQuestionEnum.MET_PERSON_NAME,
    IQuestionEnum.FORM_CREATION_DATE,
    IQuestionEnum.PREVIOUS_FORM_CREATION_DATE,
    IQuestionEnum.COMPANY_DOCTOR,
    IQuestionEnum.EXPERT_NAME,
    IQuestionEnum.EXPERT_POSITION,
    IQuestionEnum.ACTION_PRIORITY,
  ];

  const countQuestionsWithParentValue = (section: IComplementarySection) => {
    return (
      section.questions?.reduce((acc, currentQuestion) => {
        if (currentQuestion.parentValue) {
          const parentValue = complementaryForm.answersDictionary[currentQuestion.parentValue];
          if (parentValue) {
            const hasYesEmptyAnswer = parentValue.answers.find((answer) => answer.value === "Oui");
            return hasYesEmptyAnswer ? acc + 1 : acc;
          }
          return acc;
        }
        return acc + 1;
      }, 0) ?? 0
    );
  };

  const countNonEmptyAnswers = () => {
    let allQuestions = Object.keys(complementaryForm.answersDictionary);
    if (user.role === Role.COMPANY_USER) {
      allQuestions = allQuestions.filter((element) => !spstiSections.find((question) => question === element));
    }
    let totalNumberOfAnswers = 0;
    allQuestions.forEach((key) => {
      const { answers } = complementaryForm.answersDictionary[key];
      if (answers.length > 0) {
        const hasNonEmptyAnswer = answers.find(
          (answer) => answer.value !== null && answer.value !== "" && answer.value !== undefined,
        );
        if (hasNonEmptyAnswer !== undefined) {
          totalNumberOfAnswers += 1;
        }
      }
    });
    return totalNumberOfAnswers;
  };

  const updateProgressValue = () => {
    const totalQuestionsNumber = sections.reduce(
      (sectionAcc, section) => sectionAcc + countQuestionsWithParentValue(section),
      0,
    );
    const totalNumberOfAnswers = countNonEmptyAnswers();
    const newValue = Math.round((100 * totalNumberOfAnswers) / totalQuestionsNumber) ?? 0;
    setProgressValue(newValue);
  };

  useEffect(() => {
    updateProgressValue();
  }, []);

  useEffect(() => {
    updateProgressValue();
  }, [navigationIndex]);

  // to avoid errors of stepicon, need to manually retrieve the props event if we do not use them
  const renderIcon = (props: StepIconProps, index: number) => (
    <SvgIcon>{index === navigationIndex ? <Edit /> : <CheckCircle />}</SvgIcon>
  );

  const renderColor = (index: number) => (index === navigationIndex ? colors.mainOrange : colors.white);

  return (
    <Stack sx={{ height: "100%", backgroundColor: colors.primary, p: 1 }} spacing={2}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <StepperCircularProgress progressValue={progressValue} />
        <Typography variant="body2" sx={{ color: colors.white }}>
          Étapes du questionnaire
        </Typography>
      </Stack>
      <Stepper nonLinear orientation="vertical" activeStep={navigationIndex} sx={{ color: colors.white }}>
        {sections.map((section, index: number) => (
          <Step key={section.enumValue}>
            <StepButton onClick={() => setNavigationIndex(index)}>
              <StepLabel StepIconComponent={(props) => renderIcon(props, index)} sx={{ color: renderColor(index) }}>
                <Typography variant="body1" sx={{ color: colors.white }}>
                  {section.label}
                </Typography>
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
