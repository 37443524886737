import { faArchive } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { ListItemIcon, MenuItem, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IShortForm, ProgressStatus, Type } from "../../../../interfaces/Form";
import GenericIconButton from "../../../Generics/buttons/GenericIconButton";
import { StatusCode } from "../../../../resources/StatusCode";
import DuerpService from "../../../../services/DuerpService";
import ComplementaryFormService from "../../../../services/ComplementaryFormService";
import colors from "../../../../resources/cssConstant";

interface ArchiveDocumentButtonProps {
  document: IShortForm;
  onValid: () => void;
  menuButton?: boolean;
}

export default function ArchiveDocumentButton({ document, onValid, menuButton }: ArchiveDocumentButtonProps) {
  const isArchived = document.progressStatus === ProgressStatus.ARCHIVED;
  const handleArchiveDocument = async () => {
    const res =
      document.documentType === Type.DUERP
        ? await DuerpService().archiveDuerp(document.uuid)
        : await ComplementaryFormService().archiveComplementaryForm(document.uuid);
    if (res?.status === StatusCode.NO_CONTENT) {
      toast.success(`Votre document de référence ${document.reference} a bien été archivé.`);
      onValid();
    } else {
      toast.error(`Impossible d'archiver le document de référence ${document.reference}.`);
    }
  };

  const renderMenuButton = () => (
    <MenuItem onClick={() => handleArchiveDocument()} disabled={document.progressStatus === ProgressStatus.ARCHIVED}>
      <ListItemIcon>
        <FontAwesomeIcon icon={faArchive} size="xs" color={colors.primary} />
      </ListItemIcon>
      <Typography variant="body1">Archiver ce questionnaire</Typography>
    </MenuItem>
  );

  return menuButton ? (
    renderMenuButton()
  ) : (
    <GenericIconButton
      onClick={() => handleArchiveDocument()}
      icon={faArchive}
      tooltip={isArchived ? "Ce document est archivé" : "Archiver ce document"}
      disabled={isArchived}
    />
  );
}

ArchiveDocumentButton.defaultProps = {
  menuButton: false,
};
