/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react-hooks/exhaustive-deps */
// === Import NPM
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { Stack, Button, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { faUpload, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldValues, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// === Import LOCAL
import { useAtomValue } from "jotai";
import GenericDialog from "../../../Generics/GenericDialog/GenericDialog";
import { stringifyErrorMessage } from "../../../../utils/ConversionMethods";
import GenericTextField from "../../../Generics/GenericTextField/GenericTextField";
import { IOptionValue, IShape } from "../../../../interfaces/Filter";
import GenericIconButton from "../../../Generics/buttons/GenericIconButton";
import GenericButton from "../../../Generics/buttons/GenericButton";
import colors from "../../../../resources/cssConstant";
import { ICompanyShort, ICollaborator } from "../../../../interfaces/Company";
import CompanyService from "../../../../services/CompanyService";
import { allOrganizationsAtom, userAtom } from "../../../../atoms/Atoms";
import { Role } from "../../../../interfaces/User";
import { StatusCode } from "../../../../resources/StatusCode";

interface CreateCompanySchema {
  companyName: string;
  membershipOrganization: string;
  membershipNumber: string;
  logo: object;
  collaborators: ICollaborator[];
}

interface AddCollaborator {
  collaborator: ICollaborator;
}

const addCollaboratorSchema = yup.object<IShape<AddCollaborator>>({
  collaborator: yup.string().min(3, "Le format est incorrect.").email("Le format est incorrect."),
});

const testImageFormat = (format: string) => ["image/jpeg", "image/jpg", "image/png", "image/svg+xml"].includes(format);

interface CompanyFormProps {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  isUpdate?: boolean;
  company?: ICompanyShort;
  fetchCompanies: () => void;
}

function CompanyForm({ openModal, setOpenModal, isUpdate, company, fetchCompanies }: Readonly<CompanyFormProps>) {
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [companyLogo, setCompanyLogo] = useState<Blob | null>(null);
  const [collaborators, setCollaborators] = useState<ICollaborator[]>([]);
  const [fileErrorMessage, setFileErrorMessage] = useState<string | null>(null);
  const organizations = useAtomValue(allOrganizationsAtom);
  const user = useAtomValue(userAtom);

  const options: IOptionValue[] = organizations.map((organization) => ({
    value: organization.organizationName,
    label: organization.organizationName,
  }));

  const checkFileType = (fileList: FileList) => {
    if (!fileList?.length) return true;
    return testImageFormat(fileList[0]?.type);
  };

  const checkFileSize = (fileList: FileList) => {
    if (!fileList?.length) return true;
    return fileList[0]?.size <= 2000000;
  };

  const updateErrorMessage = (fileList: FileList) => {
    if (!fileList || fileList.length === 0) return true;
    if (!checkFileSize(fileList)) {
      setFileErrorMessage("La taille du fichier ne doit pas dépasser 2Mo.");
      setCompanyLogo(null);
      return false;
    }
    if (!checkFileType(fileList)) {
      setFileErrorMessage("Seuls les formats suivants sont acceptés : jpeg, jpg, png, svg.");
      setCompanyLogo(null);
      return false;
    }
    setFileErrorMessage(null);
    setCompanyLogo(fileList[0]);
    return true;
  };

  const createCompanySchema = yup.object<IShape<CreateCompanySchema>>({
    companyName: yup
      .string()
      .max(70, "Le nom de l'entreprise ne doit pas excéder 70 caractères.")
      .required("Veuillez remplir ce champ."),
    membershipOrganization: yup.string().required("Veuillez remplir ce champ."),
    membershipNumber: yup.string().max(70, "Le numéro d'adhérent SPSTI ne doit pas excéder 70 caractères."),
    logo: yup.mixed().test("fileTest", "Impossible d'ajouter ce fichier.", (value) => updateErrorMessage(value)),
    collaborators: yup.array(yup.string().email("Le format est invalide.")),
  });

  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors, isValid: createValid },
  } = useForm({
    resolver: yupResolver(createCompanySchema),
    mode: "onTouched",
  });

  const {
    register: collaboratorRegister,
    handleSubmit: handleCollaboratorSubmit,
    reset: collaboratorReset,
    formState: { errors: collaboratorErrors, isValid: collaboratorValid },
  } = useForm({
    resolver: yupResolver(addCollaboratorSchema),
    mode: "onTouched",
  });

  const logo = watch("logo");

  const getCompanyLogo = async () => {
    if (company?.logoUuid && openModal) {
      const res = await CompanyService().getCompanyLogo(company.uuid);
      if (res.data) {
        setCompanyLogo(res.data);
      }
    }
  };

  useEffect(() => {
    if (!isUpdate && user.role === Role.COMPANY_USER) {
      setCollaborators([user.email]);
    } else if (isUpdate && company) {
      setCollaborators(company.collaborators.concat(company.externalCollaborators));
    } else {
      setCollaborators([]);
    }
    setShowPreview(false);
    collaboratorReset();
    setFileErrorMessage(null);
    if (!openModal) setCompanyLogo(null);
    if (openModal) getCompanyLogo();
    reset();
  }, [openModal]);

  useEffect(() => {
    if (logo?.length > 0) setShowPreview(true);
  }, [logo]);

  const updateCompanyLogo = async (companyUuid: string) => {
    await CompanyService().updateCompanyLogo(companyUuid, logo[0]);
  };

  const onCompanySubmit = async (data: FieldValues) => {
    const companyToSubmit = {
      companyName: data.companyName,
      membershipOrganization: data.membershipOrganization,
      membershipNumber: data.membershipNumber,
      collaborators,
    };
    const response =
      isUpdate && company?.uuid
        ? await CompanyService().updateCompany(company?.uuid, companyToSubmit)
        : await CompanyService().createCompany(companyToSubmit);
    if (response.status === StatusCode.CREATED || response.status === StatusCode.OK) {
      if (logo?.length > 0) await updateCompanyLogo(response.data.uuid);
      toast.success(
        response.status === StatusCode.CREATED
          ? `L'entreprise ${data.companyName} a bien été créée.`
          : `L'entreprise ${data.companyName} a bien été modifiée.`,
      );
      setShowPreview(false);
      setOpenModal(false);
      setCollaborators([]);
      fetchCompanies();
      setFileErrorMessage(null);
      reset();
    } else {
      toast.error("Une erreur est survenue.");
    }
  };

  const sendUserInCompany = (data: FieldValues) => {
    const { collaborator } = data;
    if (collaborators.includes(collaborator)) {
      toast.error("Vous avez déjà ajouté cet utilisateur.");
    } else {
      setCollaborators([...collaborators, collaborator]);
      collaboratorReset();
    }
  };

  const deleteCollaborator = (email: string) => {
    setCollaborators(collaborators.filter((collaborator) => collaborator !== email));
  };

  const renderCompanyForm = () => (
    <Stack spacing={5}>
      <Stack component="form" justifyContent="center" spacing={5}>
        <GenericTextField
          defaultValue={company?.companyName ?? ""}
          id="companyName"
          label="Raison sociale de l'entreprise"
          required
          error={!!errors.companyName}
          helperText={stringifyErrorMessage(errors.companyName)}
          register={register("companyName")}
        />
        <GenericTextField
          required
          defaultValue={company?.membershipOrganization ?? ""}
          id="membershipOrganization"
          label="Votre service de prévention et de santé au travail"
          isSelectInput
          optionValues={options}
          error={!!errors.membershipOrganization}
          helperText={stringifyErrorMessage(errors.membershipOrganization)}
          register={register("membershipOrganization")}
        />
        <GenericTextField
          defaultValue={company?.membershipNumber ?? ""}
          id="membershipNumber"
          label="Numéro d'adhérent SPSTI"
          error={!!errors.membershipNumber}
          helperText={stringifyErrorMessage(errors.membershipNumber)}
          register={register("membershipNumber")}
        />
        <Stack spacing={1}>
          <Typography variant="body1">Logo de l'entreprise</Typography>
          {!fileErrorMessage && showPreview && (
            <Stack spacing={1} direction="row" justifyContent="center" alignItems="center">
              <img src={URL.createObjectURL(logo[0])} alt="logo preview" style={{ width: "25%" }} />
            </Stack>
          )}
          {!fileErrorMessage && companyLogo && !showPreview && (
            <Stack spacing={1} direction="row" justifyContent="center" alignItems="center">
              <img src={URL.createObjectURL(companyLogo)} alt="Logo" style={{ width: "25%" }} />
            </Stack>
          )}
          <Button
            variant="contained"
            component="label"
            startIcon={<FontAwesomeIcon size="xl" style={{ marginRight: "1rem" }} icon={faUpload} />}
          >
            Importer un logo
            {/*
             */}
            <input hidden accept="image/png, image/jpg, image/jpeg, image/svg+xml" type="file" {...register("logo")} />
          </Button>
          {fileErrorMessage && (
            <Typography variant="body1" color="error">
              {fileErrorMessage}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <GenericTextField
          label="Donner l'accès à un autre utilisateur (email)"
          id="collaborator"
          error={!!collaboratorErrors.collaborator}
          helperText={stringifyErrorMessage(collaboratorErrors.collaborator)}
          register={collaboratorRegister("collaborator")}
        />
        <GenericButton
          text="Ajouter l'utilisateur sélectionné"
          onClick={handleCollaboratorSubmit(sendUserInCompany)}
          disabled={!collaboratorValid}
        />
        {collaborators.length > 0 &&
          collaborators?.map((collaborator) => (
            <Stack direction="row" key={collaborator} justifyContent="space-between" alignItems="center">
              <Typography variant="body1">{collaborator}</Typography>
              <GenericIconButton
                icon={faTrash}
                tooltip={collaborator === user.email ? "" : "Supprimer ce collaborateur"}
                color={colors.deleteIcon}
                onClick={() => deleteCollaborator(collaborator)}
                size="sm"
                disabled={collaborator === user.email}
              />
            </Stack>
          ))}
      </Stack>
    </Stack>
  );

  return (
    <GenericDialog
      openDialog={openModal}
      handleClose={() => setOpenModal(false)}
      title={isUpdate ? "Modifier une entreprise" : "Créer une nouvelle entreprise"}
      onValid={handleSubmit(onCompanySubmit)}
      disabled={!createValid}
    >
      {renderCompanyForm()}
    </GenericDialog>
  );
}

CompanyForm.defaultProps = {
  isUpdate: false,
};

export default CompanyForm;
