import {
  Paper,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { IOrganization } from "../../../interfaces/Organization";

interface LegalNoticePartNineProps {
  organization: IOrganization;
}

export default function LegalNoticePartNine({ organization }: Readonly<LegalNoticePartNineProps>) {
  return (
    <Stack spacing={3}>
      <Typography variant="h3">9. Données à caractère personnel</Typography>

      <Stack spacing={1}>
        <Typography>
          Conformément aux dispositions de la Loi Informatique et Libertés du 6 Janvier 1978 et du règlement Européen
          n°2016/679 du 27 Avril 2016, les données saisies sur la Plateforme font l’objet d’un traitement de données à
          caractère personnel.
        </Typography>
        <Typography>
          L’Adhérent est responsable de traitement et détermine ainsi les finalités et les moyens des traitements mis en
          place, c'est à dire l'objectif et la façon de les réaliser.
        </Typography>
        <Typography>
          {organization.organizationName} est sous-traitant agissant pour le compte du responsable de traitement.
        </Typography>
        <Typography>
          L’Editeur est sous-traitant du {organization.organizationName} et sous-traitant ultérieur de l’Adhérent.
        </Typography>
        <Typography>
          Le consentement libre, clair et non équivoque au traitement des Données à caractère personnel de l’Adhérent
          est matérialisé par son acceptation des présentes Conditions Générales selon les modalités fixées à l’article
          3 des Présentes.
        </Typography>
      </Stack>

      <Stack spacing={1}>
        <Typography variant="h4">9.1. Données collectées </Typography>
        <Typography>
          {organization.organizationName} ne collecte que les informations strictement nécessaires à l’exécution des
          Services, à des fins statistiques ou d’information.
        </Typography>
        <Typography>Les données à caractère personnel qui sont collectées sur ce site sont les suivantes : </Typography>
        <Typography>
          - Création de l’espace entreprise par l’Adhérent : les nom, prénom, adresse électronique, fonction
          professionnelle et intitulé du poste de la personne qui créé le compte ;
        </Typography>
        <Typography>
          - Création d’un nouvel utilisateur associé à une entreprise : les nom, prénom et adresse électronique du
          nouvel utilisateur.
        </Typography>
      </Stack>

      <Stack spacing={1}>
        <Typography variant="h4">9.2. Finalité des traitements </Typography>
        <Typography>
          {organization.organizationName} utilise les données personnelles des Utilisateurs pour les finalités suivantes
          :
        </Typography>
        <Paper variant="outlined">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Données collectées</TableCell>
                  <TableCell>Finalité du traitement</TableCell>
                  <TableCell>Fondement du traitement</TableCell>
                  <TableCell>Durée du traitement</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    Nom, prénom, fonction, intitulé du poste, adresse électronique de la personne qui créé l’espace
                    adhérent
                  </TableCell>
                  <TableCell>
                    <Typography>- Fonctionnement de la Plateforme</Typography>
                    <Typography>
                      - Enquête de satisfaction des fonctionnalités et de la méthodologie proposée dans monduerp.fr
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>- CGU</Typography>
                    <Typography>- Intérêt légitime du SPSTI ou de l’Editeur</Typography>
                  </TableCell>
                  <TableCell>Durée de l’utilisation de la Plateforme + 3 ans</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Nom, prénom, fonction, intitulé du poste, adresse électronique des utilisateurs supplémentaires
                  </TableCell>
                  <TableCell>
                    <Typography>- Fonctionnement de la Plateforme</Typography>
                    <Typography>
                      - Enquête de satisfaction des fonctionnalités et de la méthodologie proposée dans monduerp.fr
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>- CGU</Typography>
                    <Typography>- Intérêt légitime du SPSTI ou de l’Editeur</Typography>
                  </TableCell>
                  <TableCell>Durée de l’utilisation de la Plateforme + 3 ans</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Stack>

      <Stack spacing={1}>
        <Typography variant="h4">9.3. Droits des Adhérents</Typography>
        <Typography>L’Adhérent possède les droits ci-après énumérés :</Typography>
        <Typography variant={"subQuestion"} sx={{ marginTop: 1 }}>
          Droit d’accès, de rectification et droit à l’effacement
        </Typography>
        <Typography>
          L’Adhérent peut prendre connaissance, mettre à jour, modifier ou demander la suppression des données le
          concernant, en respectant la procédure ci-après énoncée :
        </Typography>
        <Typography>
          L’Adhérent doit envoyer un courrier électronique au {organization.organizationName} en précisant l’objet de sa
          demande à l’adresse suivante : {organization.firstname} {organization.lastname} -{" "}
          {organization.electronicAddress}
        </Typography>
        <Typography variant={"subQuestion"} sx={{ marginTop: 1 }}>
          Droit à la portabilité des données
        </Typography>
        <Typography>L’Adhérent a le droit de demander la portabilité de ses données personnelles.</Typography>
        <Typography>
          L’Adhérent doit envoyer un courrier électronique au {organization.organizationName} en précisant l’objet de sa
          demande à l’adresse suivante : {organization.firstname} {organization.lastname} -{" "}
          {organization.electronicAddress}
        </Typography>
        <Typography variant={"subQuestion"} sx={{ marginTop: 1 }}>
          Droit à la limitation et à l’opposition du traitement des données
        </Typography>
        <Typography>
          L’Adhérent a le droit de demander la limitation ou de s’opposer au traitement de ses données par le site, sans
          que le {organization.organizationName} ne puisse refuser, sauf à démontrer l’existence de motifs légitimes et
          impérieux, pouvant prévaloir sur les intérêts et les droits et libertés de l’utilisateur.{" "}
        </Typography>
        <Typography>
          Afin de demander la limitation du traitement de ses données ou de formuler une opposition au traitement de ses
          données, l’Adhérent doit envoyer un courrier électronique au {organization.organizationName} en précisant
          l’objet de sa demande à l’adresse suivante : {organization.firstname} {organization.lastname} -{" "}
          {organization.electronicAddress}
        </Typography>
        <Typography variant={"subQuestion"} sx={{ marginTop: 1 }}>
          Droit de ne pas faire l’objet d’une décision fondée exclusivement sur un procédé automatisé
        </Typography>
        <Typography>
          L’Adhérent a le droit de ne pas faire l’objet d’une décision fondée exclusivement sur un procédé automatisé si
          la décision produit des effets juridiques le concernant, ou l’affecte de manière significative de façon
          similaire.
        </Typography>
        <Typography variant={"subQuestion"} sx={{ marginTop: 1 }}>
          Droit de saisir l’autorité de contrôle compétente
        </Typography>
        <Typography>
          Dans le cas où {organization.organizationName} décide de ne pas répondre à la demande de l’utilisateur, et que
          l’Adhérent souhaite contester cette décision, ou, s’il pense qu’il est porté atteinte à l’un des droits
          énumérés ci-dessus, il est en droit de saisir la CNIL (Commission Nationale de l’Informatique et des Libertés,{" "}
          <a href={"https://www.cnil.fr"}> https://www.cnil.fr</a>) ou tout juge compétent.
        </Typography>
        <Typography>
          Afin que {organization.organizationName} fasse droit à sa demande, l’Adhérent est tenu de lui communiquer ses
          prénom, nom ainsi que son adresse électronique.
        </Typography>
        <Typography>
          {organization.organizationName} est tenu de répondre à l’Adhérent dans un délai de 30 (trente) jours au
          maximum.
        </Typography>
      </Stack>

      <Stack spacing={1}>
        <Typography variant="h4">9.4. Sécurité et confidentialité</Typography>
        <Typography>
          {organization.organizationName} met en œuvre des mesures organisationnelles, techniques, logicielles et
          physiques en matière de sécurité du numérique pour protéger les données personnelles contre les altérations,
          destructions et accès non autorisés.
        </Typography>
        <Typography>
          Toutefois, il est à signaler qu'internet n'est pas un environnement complètement sécurisé et la Plateforme ne
          peut pas garantir la sécurité de la transmission ou du stockage des informations sur internet.
        </Typography>
      </Stack>

      <Stack spacing={1}>
        <Typography variant="h4">9.5. Procédure en cas d’atteinte à la sécurité des données </Typography>
        <Typography>
          {organization.organizationName} s’engage à informer l’Adhérent, dans un délai maximum de quarante-huit (48)
          heures après en avoir pris connaissance, de toute atteinte à la sécurité des données lorsque cette atteinte
          entraîne, de manière accidentelle ou illicite, l’accès ou la divulgation non autorisée, l’altération, la perte
          ou la destruction de données à caractère personnel.
        </Typography>
        <Typography>
          Il appartient alors à l’Adhérent d’en informer (i) l’autorité de contrôle dont il dépend, et (ii) les
          personnes concernées quand cette atteinte à la sécurité des données personnelles est susceptible d’engendrer
          un risque élevé pour les droits et libertés.
        </Typography>
        <Typography>
          En cas de survenance d’une violation de sécurité, {organization.organizationName} s’engage (i) à prendre au
          plus vite toute mesure de correction technique appropriée pour faire cesser la violation de sécurité
          identifiée, notamment afin de rendre les données incompréhensibles à toute personne non autorisée à y avoir
          accès et les appliquer aux données concernées par cette violation de sécurité et (ii) à en justifier par écrit
          sans délai à l’Adhérent.
        </Typography>
      </Stack>
    </Stack>
  );
}
