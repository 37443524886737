/* eslint-disable react/jsx-key */
// === Import NPM
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { GridCellParams, GridRowParams, GridSortItem } from "@mui/x-data-grid";
import { FieldValues } from "react-hook-form";
import * as yup from "yup";
import { DateTime } from "luxon";
import { useAtomValue } from "jotai";

// === Import LOCAL
import { ObjectShape } from "yup/lib/object";
import Header from "../Header/Header";
import GenericDatagrid from "../../Generics/GenericDatagrid/GenericDatagrid";
import GenericFilter from "../../Generics/GenericFilter/GenericFilter";
import { IShape, IFilterConfigurations, IFilterTypes } from "../../../interfaces/Filter";
import ProgressChip from "../ProgressChip/ProgressChip";
import { AccompanyingStatus, ProgressStatus, Type } from "../../../interfaces/Form";
import { ICompany, ICompanyDocument } from "../../../interfaces/Company";
import { userShortAtom } from "../../../atoms/Atoms";
import { Role } from "../../../interfaces/User";
import CompanyService from "../../../services/CompanyService";
import { objectFilter } from "../../../utils/ObjectFilter";
import FilterService from "../../../services/FilterService";
import ArchiveDocumentButton from "../DocumentActions/ArchiveDocumentButton/ArchiveDocumentButton";
import DeleteDocumentButton from "../DocumentActions/DeleteDocumentButton/DeleteDocumentButton";
import OpenDocumentButton from "../DocumentActions/OpenDocumentButton/OpenDocumentButton";
import DuplicateDocumentButton from "../DocumentActions/DuplicateDocumentButton/DuplicateDocumentButton";
import { IOiraShortForm } from "../../../interfaces/OiraForm";
import FormService from "../../../services/FormService";
import NewDocumentButton from "../DocumentActions/NewDocumentButton/NewDocumentButton";
import { StatusCode } from "../../../resources/StatusCode";
import { AmetraTotalCount } from "../../../resources/AppConstants";
import DownloadDocumentButton from "../DocumentActions/DownloadDocumentButton/DownloadDocumentButton";

interface DocumentFilterSchema {
  documentType: string;
  reference: string;
  progressStatus: string;
  accompanyingStatus: string;
}

const documentFilterSchema = yup.object<IShape<DocumentFilterSchema>>({
  documentType: yup.string().oneOf(["ALL", "DUERP", "COMPLEMENTARY_FORM"]),
  reference: yup.string().max(70, "Votre numéro de référence ne doit pas excéder 70 caractères."),
  progressStatus: yup
    .string()
    .oneOf(
      ["ALL", "NOT_FINALIZED", "COMPLETED", "FINALIZED", "ARCHIVED"],
      "Le statut de progression renseigné est incorrect.",
    ),
  accompanyingStatus: yup
    .string()
    .oneOf(
      ["ALL", "ACCOMPANIED", "NOT_ACCOMPANIED", "FIELD_ACCOMPANIMENT"],
      "Le statut d'accompagnement renseigné est incorrect.",
    ),
});

export default function DocumentTable() {
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [companyDocuments, setCompanyDocuments] = useState<ICompanyDocument[]>([]);
  const user = useAtomValue(userShortAtom);
  const [oiras, setOiras] = useState<IOiraShortForm[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [rowCount, setRowCount] = useState<number>(10);
  const [currentFilter, setCurrentFilter] = useState<IFilterConfigurations<ObjectShape> | null>(null);
  const [company, setCompany] = useState<ICompany>({
    externalCollaborators: [],
    collaborators: [],
    companyName: "",
    documents: [],
    logoUuid: "",
    membershipNumber: "",
    membershipOrganization: "",
    uuid: "",
  });

  const { companyUuid, companyName } = location.state;

  const filterRequest = async (filterInputs) => {
    setCurrentFilter(filterInputs);
    const requestParams = objectFilter(filterInputs, (filterInput) => filterInput !== "ALL" && filterInput !== "");
    const response = await FilterService().documentFilter(companyUuid, requestParams, page, rowsPerPage);
    if (response.status === StatusCode.OK) {
      setCompanyDocuments(response.data);
      setRowCount(+(response.headers[AmetraTotalCount] ?? response.data.length));
    }
  };

  const fetchCompany = async (uuid: string) => {
    setCompanyDocuments([]);
    const retrievedCompany = await CompanyService().getCompanyById(uuid);
    setCompany(retrievedCompany?.data);
    setCurrentFilter(null);
    await filterRequest({});
  };
  const fetchOiras = async () => {
    const res = await FormService().getAllPublishedOiras();
    setOiras(res.data ?? []);
  };

  useEffect(() => {
    setLoading(true);
    fetchCompany(companyUuid).then();
    fetchOiras().then();
    setLoading(false);
  }, []);

  useEffect(() => {
    setLoading(true);
    if (currentFilter) {
      filterRequest(currentFilter).then();
    } else {
      fetchCompany(companyUuid).then();
    }
    setLoading(false);
  }, [page, rowsPerPage]);

  const refreshDocuments = () => {
    fetchCompany(companyUuid);
  };

  const renderHeaderButtons = () => (
    <>
      <NewDocumentButton buttonWithIcon={false} company={company} oiras={oiras} documentType={Type.DUERP} />
      <NewDocumentButton
        buttonWithIcon={false}
        company={company}
        oiras={oiras}
        documentType={Type.COMPLEMENTARY_FORM}
      />
    </>
  );

  const documentFilter: IFilterConfigurations<DocumentFilterSchema> = {
    documentType: {
      id: "documentType",
      name: "documentType",
      label: "Type de document",
      type: IFilterTypes.SELECT,
      optionValues: [
        { id: 1, value: Type.ALL, label: "Tous" },
        { id: 2, value: Type.DUERP, label: "DUERP" },
        { id: 3, value: Type.COMPLEMENTARY_FORM, label: "Questionnaire complémentaire" },
      ],
    },
    reference: {
      id: "reference",
      name: "reference",
      label: "Numéro de référence",
      type: IFilterTypes.TEXT,
    },
    progressStatus: {
      id: "progressStatus",
      name: "progressStatus",
      label: "Statut du document",
      type: IFilterTypes.SELECT,
      optionValues: [
        { id: 1, value: ProgressStatus.ALL, label: "Tous" },
        { id: 2, value: ProgressStatus.NOT_FINALIZED, label: "Non finalisé" },
        { id: 3, value: ProgressStatus.COMPLETED, label: "Complété" },
        { id: 4, value: ProgressStatus.FINALIZED, label: "Finalisé" },
        ...(user.role !== Role.COMPANY_USER ? [{ id: 5, value: ProgressStatus.ARCHIVED, label: "Archivé" }] : []),
      ],
    },
    accompanyingStatus: {
      id: "accompanyingStatus",
      name: "accompanyingStatus",
      label: "Accompagnement",
      type: IFilterTypes.SELECT,
      optionValues: [
        { id: 1, value: AccompanyingStatus.ALL, label: "Tous" },
        { id: 2, value: AccompanyingStatus.ACCOMPANIED, label: "Accompagné" },
        { id: 3, value: AccompanyingStatus.NOT_ACCOMPANIED, label: "Non accompagné" },
        { id: 4, value: AccompanyingStatus.FIELD_ACCOMPANIMENT, label: "Accompagnement terrain" },
      ],
    },
  };

  const onFilterSubmit = async (data: FieldValues) => {
    const filterInputs = {
      documentType: data.documentType,
      progressStatus: data.progressStatus,
      reference: data.reference,
      accompanyingStatus: data.accompanyingStatus,
    };
    await filterRequest(filterInputs);
  };

  const renderBaseActions = (params: GridRowParams) => [
    <OpenDocumentButton document={params.row} company={company} />,
    <DuplicateDocumentButton documentType={params.row.documentType} documentUuid={params.row.uuid} company={company} />,
  ];

  const renderFoActions = (params: GridRowParams) => {
    const actions: JSX.Element[] = renderBaseActions(params);
    actions.push(<DeleteDocumentButton document={params.row} onValid={refreshDocuments} />);
    if (params.row.documentType === Type.DUERP && params.row.progressStatus === ProgressStatus.FINALIZED) {
      actions.push(
        <DownloadDocumentButton documentType={params.row.documentType} documentUuid={params.row.uuid} iconButton />,
      );
    }
    return actions;
  };

  const renderBoActions = (params: GridRowParams) => {
    const actions = [...renderBaseActions(params)];
    if (user.role === Role.ADMIN) {
      actions.push(<DeleteDocumentButton document={params.row} onValid={refreshDocuments} />);
    }
    if (params.row.progressStatus !== ProgressStatus.NOT_FINALIZED) {
      actions.push(
        <DownloadDocumentButton documentType={params.row.documentType} documentUuid={params.row.uuid} iconButton />,
      );
    }
    if (params.row.progressStatus !== ProgressStatus.ARCHIVED) {
      actions.push(<ArchiveDocumentButton document={params.row} onValid={refreshDocuments} />);
    }
    return actions;
  };

  const documentColumns = [
    {
      field: "uuid",
      hide: true,
    },
    {
      field: "lastUpdatedDate",
      headerName: "Date",
      flex: 1,
      valueFormatter: (params: GridCellParams) => DateTime.fromISO(params?.value).toLocaleString(),
      sortable: false,
    },
    {
      field: "documentType",
      headerName: "Type de document",
      flex: 2,
      sortable: false,
      valueFormatter: (params: GridCellParams) =>
        params.value === Type.DUERP ? "DUERP" : "Questionnaire complémentaire",
    },
    {
      field: "reference",
      headerName: "Numéro de référence",
      flex: 2,
      sortable: false,
    },
    {
      field: "progressStatus",
      headerName: "Statut",
      flex: 2,
      sortable: false,
      renderCell: (params: GridRowParams) => (
        <ProgressChip progressType={params.row.progressStatus as ProgressStatus} />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 250,
      getActions: (params: GridRowParams) =>
        user.role === Role.COMPANY_USER ? renderFoActions(params) : renderBoActions(params),
    },
  ];

  const sortModel: GridSortItem[] = [{ field: "lastUpdatedDate", sort: "desc" }];

  return (
    <>
      <Header
        title={user.role === Role.COMPANY_USER ? "Documents de mon entreprise" : "Documents de l'entreprise"}
        subtitle={companyName}
      >
        {renderHeaderButtons()}
      </Header>
      <Box sx={{ px: 15, py: 4 }}>
        <GenericFilter onSubmit={onFilterSubmit} schema={documentFilterSchema} filterInputs={documentFilter} />
        <GenericDatagrid
          rows={companyDocuments}
          columns={documentColumns}
          sortModel={sortModel}
          loading={loading}
          page={page}
          onPageChange={setPage}
          size={rowsPerPage}
          onPageSizeChange={setRowsPerPage}
          rowCount={rowCount}
        />
      </Box>
    </>
  );
}

DocumentTable.defaultProps = {};
