import { Checkbox, FormControlLabel, Grid, Radio, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { faCirclePlus, faComments, faTrash, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IDuerpAnswer } from "../../../../../../../interfaces/DuerpForm";
import colors from "../../../../../../../resources/cssConstant";
import GenericIconButton from "../../../../../../Generics/buttons/GenericIconButton";
import { currentDuerpAtom, userAtom } from "../../../../../../../atoms/Atoms";
import { Role } from "../../../../../../../interfaces/User";
import { ProgressStatus } from "../../../../../../../interfaces/Form";
import CommentCard from "../../../../../CommentCard/CommentCard";
import GenericCard from "../../../../../../Generics/GenericCard/GenericCard";
import GenericDialog from "../../../../../../Generics/GenericDialog/GenericDialog";
import GenericTextField from "../../../../../../Generics/GenericTextField/GenericTextField";
import { dateToString, stringifyErrorMessage } from "../../../../../../../utils/ConversionMethods";
import GenericButton from "../../../../../../Generics/buttons/GenericButton";
import { LinkifyText } from "../../../../../../../utils/LinkifyText";

interface MeasuresCardProps {
  title: string;
  measures: IDuerpAnswer[];
  checkMeasure: (checked: boolean, isExpert: boolean, measureIndex: number) => void;
  icon: IconDefinition;
  addMeasure: () => void;
  deleteMeasure: (measureIndex: number) => void;
  addExpertComment: (measureIndex: number, expertComment: string) => void;
  updateRiskManagement?: (riskManagement: boolean) => void;
  isRiskManaged?: boolean;
}

const commentSchema = yup.object().shape({
  comment: yup.string().max(2000, "Votre commentaire ne doit pas excéder 2000 caractères."),
});

export default function MeasuresCard({
  title,
  measures,
  checkMeasure,
  icon,
  addMeasure,
  deleteMeasure,
  addExpertComment,
  updateRiskManagement,
  isRiskManaged,
}: MeasuresCardProps) {
  const theme = useTheme();
  const user = useAtomValue(userAtom);
  const currentDuerp = useAtomValue(currentDuerpAtom);
  const [openExpertMeasureCommentModal, setOpenExpertMeasureCommentModal] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const isTablet = !useMediaQuery(theme.breakpoints.up("lg"));

  const {
    register: registerComment,
    handleSubmit: handleSubmitComment,
    reset: resetComment,
    formState: { errors: errorsComment, isValid: isValidComment },
  } = useForm({
    resolver: yupResolver(commentSchema),
    mode: "onTouched",
  });

  useEffect(() => {
    resetComment();
  }, [openExpertMeasureCommentModal]);

  const disableCheckBox = (isExpertColumn: boolean) => {
    if (currentDuerp.progressStatus === ProgressStatus.NOT_FINALIZED) {
      return isExpertColumn && user.role === Role.COMPANY_USER;
    }
    return true;
  };

  const addComment = (data: FieldValues) => {
    addExpertComment(currentIndex, data.comment);
    setOpenExpertMeasureCommentModal(false);
  };

  const disableDeletion = (hasOiraUuid: boolean, checkedByExpert: boolean) => {
    if (hasOiraUuid) {
      return true;
    }
    if (currentDuerp.progressStatus !== ProgressStatus.NOT_FINALIZED) {
      return true;
    }
    if (checkedByExpert && user.role === Role.COMPANY_USER) {
      return true;
    }
    return false;
  };

  const deletionTooltip = (hasOiraUuid: boolean, checkedByExpert: boolean) => {
    if (hasOiraUuid) {
      return "Vous pouvez uniquement supprimer les mesures supplémentaires ajoutées";
    }
    if (currentDuerp.progressStatus !== ProgressStatus.NOT_FINALIZED) {
      return "Vous ne pouvez pas modifier un DUERP finalisé";
    }
    if (checkedByExpert && user.role === Role.COMPANY_USER) {
      return "Cette mesure est validée par votre préventeur";
    }
    return "Supprimer cette mesure";
  };

  const renderCheckboxes = (measure: IDuerpAnswer, measureIndex: number) => {
    const isFirstLine = measureIndex === 0;
    const hasOiraUuid = measure.oiraAnswerUuid?.length > 0;
    return (
      <Stack direction="row" alignItems="flex-end">
        <FormControlLabel
          control={
            <Checkbox
              sx={{ mx: 2 }}
              checked={measure.checkedByCompany}
              onChange={(event, checked) => checkMeasure(checked, false, measureIndex)}
              disabled={disableCheckBox(false)}
            />
          }
          label={isFirstLine ? "Entreprise" : ""}
          labelPlacement="top"
        />
        <FormControlLabel
          control={
            <Checkbox
              sx={{ mx: 2 }}
              checked={measure.checkedByExpert}
              onChange={(event, checked) => checkMeasure(checked, true, measureIndex)}
              disabled={disableCheckBox(true)}
            />
          }
          label={isFirstLine ? "Préventeur" : ""}
          labelPlacement="top"
        />
        {user.role !== Role.COMPANY_USER && (
          <GenericIconButton
            onClick={() => {
              setCurrentIndex(measureIndex);
              setOpenExpertMeasureCommentModal(true);
            }}
            icon={faComments}
            tooltip="Ajouter un commentaire"
            color={colors.mainOrange}
            disabled={currentDuerp.progressStatus !== ProgressStatus.NOT_FINALIZED}
          />
        )}
        <GenericIconButton
          onClick={() => deleteMeasure(measureIndex)}
          icon={faTrash}
          tooltip={deletionTooltip(hasOiraUuid, measure.checkedByExpert)}
          color={colors.deleteIcon}
          disabled={disableDeletion(hasOiraUuid, measure.checkedByExpert)}
        />
      </Stack>
    );
  };

  const renderExpertMeasureCommentModal = () => {
    const expertComment = measures[currentIndex] ? measures[currentIndex].expertComment : "";
    return (
      <GenericDialog
        title="Ajouter un commentaire préventeur à cette mesure"
        openDialog={openExpertMeasureCommentModal}
        handleClose={() => setOpenExpertMeasureCommentModal(false)}
        onValid={handleSubmitComment(addComment)}
        disabled={!isValidComment}
      >
        <GenericTextField
          multiline
          rows={4}
          placeholder="Ajoutez des éléments supplémentaires concernant ce risque"
          label="Commentaire préventeur"
          id="expert_comment"
          register={registerComment("comment")}
          error={!!errorsComment.comment}
          helperText={stringifyErrorMessage(errorsComment.comment)}
          defaultValue={expertComment}
        />
      </GenericDialog>
    );
  };

  const renderMeasureString = (measure: IDuerpAnswer) => {
    let fullMeasure = measure.title;
    fullMeasure = measure.date ? `${fullMeasure} (${dateToString(measure.date)})` : fullMeasure;
    fullMeasure = measure.description ? `${fullMeasure}\n${measure.description}` : fullMeasure;
    return LinkifyText(fullMeasure);
  };

  const renderMeasureLine = (measure: IDuerpAnswer, measureIndex: number) => {
    const displayExpertComment = measure.expertComment !== "" && measure.expertComment !== null;
    return (
      <Stack key={measureIndex}>
        <Grid container direction="row" alignItems="center" justifyContent="space-between">
          <Grid item xs={isTablet ? 6 : 8}>
            <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
              {renderMeasureString(measure)}
            </Typography>
          </Grid>
          <Grid item>{renderCheckboxes(measure, measureIndex)}</Grid>
        </Grid>
        {displayExpertComment && (
          <CommentCard
            title="Commentaire de votre préventeur"
            icon={faComments}
            commentContent={measure.expertComment}
            backgroundColor={colors.cardBackground}
          />
        )}
      </Stack>
    );
  };

  const handleRiskManagement = (event) => {
    const { value } = event.target;
    if (updateRiskManagement) {
      updateRiskManagement(value === "true");
    }
  };

  const renderRiskManagementQuestion = () => (
    <Stack spacing={1} alignItems="flex-start">
      <Stack direction="row" spacing={1} alignItems="center">
        <Radio
          disabled={currentDuerp.progressStatus !== ProgressStatus.NOT_FINALIZED}
          value="true"
          checked={isRiskManaged}
          onChange={handleRiskManagement}
        />
        <Typography variant="body1">Oui</Typography>
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <Radio
          disabled={currentDuerp.progressStatus !== ProgressStatus.NOT_FINALIZED}
          value="false"
          checked={!isRiskManaged}
          onChange={handleRiskManagement}
        />
        <Typography variant="body1">Non</Typography>
      </Stack>
    </Stack>
  );

  return (
    <>
      {renderExpertMeasureCommentModal()}
      <GenericCard title={title} icon={icon}>
        {updateRiskManagement && renderRiskManagementQuestion()}
        <Stack spacing={1} sx={{ mb: 2 }}>
          {measures.map((measure, index) => renderMeasureLine(measure, index))}
        </Stack>
        {currentDuerp.progressStatus === ProgressStatus.NOT_FINALIZED && (
          <Stack direction="row" alignItems="center">
            <GenericButton
              onClick={addMeasure}
              icon={faCirclePlus}
              text="Ajouter une nouvelle mesure"
              color={colors.mainOrange}
            />
          </Stack>
        )}
      </GenericCard>
    </>
  );
}

MeasuresCard.defaultProps = {
  updateRiskManagement: undefined,
  isRiskManaged: false,
};
