import { Radio, Stack, Typography } from "@mui/material";
import { faShieldAlt } from "@fortawesome/free-solid-svg-icons";
import { useAtomValue } from "jotai";
import { ActionPriority, ProgressStatus } from "../../../../../../../interfaces/Form";
import GenericCard from "../../../../../../Generics/GenericCard/GenericCard";
import { currentDuerpAtom } from "../../../../../../../atoms/Atoms";

interface RiskCardProps {
  actionPriority: ActionPriority;
  updatePriority: (priority: ActionPriority) => void;
}
export default function RiskCard({ actionPriority, updatePriority }: Readonly<RiskCardProps>) {
  const duerp = useAtomValue(currentDuerpAtom);
  const disabled = duerp.progressStatus !== ProgressStatus.NOT_FINALIZED;
  const handlePriorityChange = (event) => {
    updatePriority(ActionPriority[event.target.value]);
  };

  return (
    <GenericCard title="Évaluez le niveau de risque" icon={faShieldAlt}>
      <Stack spacing={1} alignItems="flex-start">
        <Stack direction="row" spacing={1} alignItems="center">
          <Radio
            disabled={disabled}
            value={ActionPriority.LOW}
            checked={actionPriority === ActionPriority.LOW}
            onChange={handlePriorityChange}
          />
          <Typography variant="body1">Faible</Typography>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <Radio
            disabled={disabled}
            value={ActionPriority.MEDIUM}
            checked={actionPriority === ActionPriority.MEDIUM}
            onChange={handlePriorityChange}
          />
          <Typography variant="body1">Moyenne</Typography>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <Radio
            disabled={disabled}
            value={ActionPriority.HIGH}
            checked={actionPriority === ActionPriority.HIGH}
            onChange={handlePriorityChange}
          />
          <Typography variant="body1">Élevée</Typography>
        </Stack>
      </Stack>
    </GenericCard>
  );
}
