/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Button, Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAtomValue } from "jotai";
import GenericButton from "../../../Generics/buttons/GenericButton";
import GenericTextField from "../../../Generics/GenericTextField/GenericTextField";
import { createAccountSchema, updateAccountSchema, updateExpertAccountSchema } from "../../../../utils/YupSchemas/User";
import { stringifyErrorMessage } from "../../../../utils/ConversionMethods";
import { userAtom } from "../../../../atoms/Atoms";
import { IOptionValue } from "../../../../interfaces/Filter";
import LegalNotice from "../../LegalNotice/LegalNotice";
import colors from "../../../../resources/cssConstant";

interface ProfileFormProps {
  onSubmit: (data: FieldValues) => void;
  submitButtonText: string;
  title: string;
  update?: boolean;
  companyUser?: boolean;
}
export default function ProfileForm({
  onSubmit,
  submitButtonText,
  update,
  title,
  companyUser,
}: Readonly<ProfileFormProps>) {
  const [agreedToLegalNotice, setAgreedToLegalNotice] = useState<boolean>(false);
  const [openLegalNotice, setOpenLegalNotice] = useState<boolean>(false);
  const isCompanyUserSchema = (cUser) => {
    return cUser ? updateAccountSchema : updateExpertAccountSchema;
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isSubmitSuccessful },
  } = useForm({
    resolver: yupResolver(update ? isCompanyUserSchema(companyUser) : createAccountSchema),
    mode: "onTouched",
  });

  const user = useAtomValue(userAtom);

  const options: IOptionValue[] = [
    { value: "true", label: "Employeur ou salarié" },
    { value: "false", label: "Externe à l'entreprise/groupe" },
  ];

  useEffect(() => {
    if (isSubmitSuccessful && !update) reset();
  }, [isSubmitSuccessful]);

  return (
    <Stack
      spacing={7}
      onSubmit={handleSubmit(onSubmit)}
      component="form"
      width="30%"
      alignItems="center"
      paddingBottom={3}
    >
      <Typography variant="h4">{title}</Typography>
      <Stack spacing={2} width="100%">
        <GenericTextField
          defaultValue={user.firstname}
          id="firstname"
          label="Prénom"
          required={!update}
          error={!!errors.firstname}
          helperText={stringifyErrorMessage(errors.firstname)}
          register={register("firstname")}
        />
        <GenericTextField
          defaultValue={user.lastname}
          id="lastname"
          label="Nom"
          required={!update}
          error={!!errors.lastname}
          helperText={stringifyErrorMessage(errors.lastname)}
          register={register("lastname")}
        />
        <GenericTextField
          defaultValue={user.email}
          id="email"
          label="Email"
          required={!update}
          error={!!errors.email}
          helperText={stringifyErrorMessage(errors.email)}
          register={register("email")}
        />
        {!update && companyUser && (
          <>
            <GenericTextField
              type="password"
              id="password"
              label="Mot de passe"
              required={!update}
              error={!!errors.password}
              helperText={stringifyErrorMessage(errors.password)}
              register={register("password")}
            />
            <GenericTextField
              type="password"
              id="confirmPassword"
              label="Confirmez le mot de passe"
              required={!update}
              error={!!errors.confirmPassword}
              helperText={stringifyErrorMessage(errors.confirmPassword)}
              register={register("confirmPassword")}
            />
          </>
        )}
        {companyUser && (
          <>
            <GenericTextField
              defaultValue={update ? user["internal"] : ""}
              label="Fonction"
              id="internal"
              isSelectInput
              required={!update && companyUser}
              optionValues={options}
              error={!!errors.internal}
              helperText={stringifyErrorMessage(errors.internal)}
              register={register("internal")}
            />
            <GenericTextField
              defaultValue={user["positionTitle"]}
              id="positionTitle"
              label="Intitulé du poste"
              error={!!errors.positionTitle}
              helperText={stringifyErrorMessage(errors.positionTitle)}
              register={register("positionTitle")}
              sx={{ mt: 2, mb: 4 }}
            />
            {!update && (
              <Stack alignItems="center">
                <FormControlLabel
                  value={agreedToLegalNotice}
                  control={<Checkbox sx={{ mx: 2 }} onChange={(event, checked) => setAgreedToLegalNotice(checked)} />}
                  label="En créant un compte, vous confirmez avoir pris connaissance et accepté les"
                  labelPlacement="end"
                />
                <Button
                  sx={{ textDecoration: "underline", textTransform: "none", padding: 0, color: colors.primary }}
                  variant="text"
                  onClick={() => setOpenLegalNotice(true)}
                >
                  Conditions Générales d'Utilisation
                </Button>
              </Stack>
            )}
          </>
        )}
      </Stack>

      <GenericButton
        text={submitButtonText}
        onClick={handleSubmit(onSubmit)}
        disabled={!update && !(isValid && agreedToLegalNotice)}
      />
      <LegalNotice open={openLegalNotice} setOpen={setOpenLegalNotice} />
    </Stack>
  );
}

ProfileForm.defaultProps = {
  update: false,
  companyUser: false,
};
