// === Import NPM
import React, { PropsWithChildren } from "react";
import { Typography, Stack, Box } from "@mui/material";

// === Import LOCAL
import colors from "../../../resources/cssConstant";

type HeaderProps = PropsWithChildren<{
  title: string;
  subtitle?: string;
  marginBottom?: number;
  subtitleChildren?: boolean;
}>;

function Header({ title, subtitle, children, marginBottom, subtitleChildren }: HeaderProps) {
  return (
    <Stack
      justifyContent="space-between"
      alignItems="center"
      direction="row"
      sx={{
        backgroundColor: `${colors.headerColor}`,
        px: 3,
        py: 2,
        width: "100%",
        mb: marginBottom,
      }}
    >
      <Box>
        <Typography variant="h3">{title}</Typography>
        {subtitle && (
          <Typography variant="h4" sx={{ mt: 2 }}>
            {subtitle}
          </Typography>
        )}
        {subtitleChildren && children}
      </Box>
      {!subtitleChildren && (
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
          {children}
        </Stack>
      )}
    </Stack>
  );
}

Header.defaultProps = {
  subtitle: undefined,
  marginBottom: 0,
  subtitleChildren: false,
};

export default Header;
