export default function LocaleStorageService() {
  const setLocaleStorageItem = (key: string, value: string) => {
    localStorage.setItem(key, value);
  };

  const getLocaleStorageItem = (key: string) => localStorage.getItem(key);

  const removeLocaleStorageItem = (key: string) => {
    localStorage.removeItem(key);
  };

  return {
    setLocaleStorageItem,
    getLocaleStorageItem,
    removeLocaleStorageItem,
  };
}
