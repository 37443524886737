// === Import NPM
import React, { useState } from "react";
import { Button, Divider, IconButton, AppBar, Toolbar, Stack, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus, faCircleUser, faPowerOff, faBook } from "@fortawesome/free-solid-svg-icons";
import { useAtomValue } from "jotai";

// === Import LOCAL
import colors from "../../../resources/cssConstant";
import logoduerp from "../../../assets/images/logoduerp.png";
import { backOfficeRoutes, frontOfficeRoutes, publicRoutes } from "../../../resources/Routes";
import { useAuth } from "../../../routers/useAuth";
import { userAtom } from "../../../atoms/Atoms";
import { Role } from "../../../interfaces/User";
import LegalNotice from "../LegalNotice/LegalNotice";

function Navbar() {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const isLogged = auth.logged;
  const user = useAtomValue(userAtom);
  const [openLegalNotice, setOpenLegalNotice] = useState<boolean>(false);

  const renderSpaceDisplay = () => {
    switch (user.role) {
      case Role.ADMIN:
        return "Espace Administrateur";
      case Role.EXPERT:
        return "Espace Préventeur";
      case Role.COMPANY_USER:
        return "Espace Entreprise";
      default:
        return "";
    }
  };

  const updateBackgroundColor = (route: string) =>
    location.pathname === route ? `${colors.primary}` : `${colors.white}`;

  const updateTextColor = (route: string) => (location.pathname === route ? `${colors.white}` : `${colors.primary}`);

  const renderCGU = () => (
    <>
      <Button
        onClick={() => setOpenLegalNotice(true)}
        sx={{
          width: "fit-content",
          height: "100%",
          borderRadius: "0",
          padding: 3,
          backgroundColor: `${colors.white}`,
          color: `${colors.primary}`,
          "&:hover": { backgroundColor: `${colors.mainOrange}`, color: `${colors.white}` },
        }}
      >
        <FontAwesomeIcon style={{ fontSize: "2rem", marginRight: "1rem" }} icon={faBook} />
        Consulter les CGU
      </Button>
      <LegalNotice open={openLegalNotice} setOpen={setOpenLegalNotice} />
    </>
  );

  return (
    <AppBar position="relative" sx={{ zIndex: 1300 }}>
      <Toolbar disableGutters sx={{ justifyContent: "space-between", height: "100%" }}>
        <Stack direction="row" alignItems="center">
          <Button
            onClick={() => {
              if (user.role === Role.COMPANY_USER) navigate(frontOfficeRoutes.home);
              else navigate(backOfficeRoutes.home);
            }}
          >
            <img
              src={logoduerp}
              alt="logo"
              style={{
                display: "block",
                height: "40px",
              }}
            />
          </Button>
          <Divider orientation="vertical" variant="middle" flexItem sx={{ bgcolor: `${colors.navbarTitle}`, m: 1 }} />
          {isLogged && (
            <Typography variant="h3" sx={{ color: `${colors.navbarTitle}` }}>
              {renderSpaceDisplay()}
            </Typography>
          )}
        </Stack>
        <Stack direction="row" alignItems="center" sx={{ height: "100%" }}>
          {renderCGU()}
          {isLogged ? (
            <>
              <Button
                sx={{
                  width: "fit-content",
                  height: "100%",
                  borderRadius: "0",
                  padding: 3,
                  color: `${colors.primary}`,
                  "&:hover": { backgroundColor: `${colors.primary}`, color: `${colors.white}` },
                }}
                onClick={() => {
                  if (user.role === Role.COMPANY_USER) navigate(frontOfficeRoutes.profile);
                  if (user.role !== Role.COMPANY_USER) navigate(backOfficeRoutes.profile);
                }}
              >
                <FontAwesomeIcon style={{ fontSize: "2rem", marginRight: "1rem" }} icon={faCircleUser} />
                {`${user.firstname} ${user.lastname}`}
              </Button>
              <IconButton
                sx={{
                  height: "100%",
                  p: 3,
                  borderRadius: "0",
                  color: `${colors.primary}`,
                  "&:hover": { backgroundColor: `${colors.primary}`, color: `${colors.white}` },
                }}
                onClick={() => {
                  auth.logout();
                }}
              >
                <FontAwesomeIcon style={{ fontSize: "2rem" }} icon={faPowerOff} />
              </IconButton>
            </>
          ) : (
            <>
              <Button
                component={Link}
                to={publicRoutes.createAccount}
                sx={{
                  width: "wrap-content",
                  height: "100%",
                  borderRadius: "0",
                  padding: 3,
                  backgroundColor: updateBackgroundColor(publicRoutes.createAccount),
                  color: updateTextColor(publicRoutes.createAccount),
                  "&:hover": { backgroundColor: `${colors.mainOrange}`, color: `${colors.white}` },
                }}
              >
                <FontAwesomeIcon style={{ fontSize: "1.5rem", marginRight: "1rem" }} icon={faUserPlus} />
                Créer un compte
              </Button>
              <Button
                component={Link}
                to={publicRoutes.login}
                sx={{
                  width: "wrap-content",
                  height: "100%",
                  borderRadius: "0",
                  padding: 3,
                  backgroundColor: updateBackgroundColor(publicRoutes.login),
                  color: updateTextColor(publicRoutes.login),
                  "&:hover": { backgroundColor: `${colors.mainOrange}`, color: `${colors.white}` },
                }}
              >
                <FontAwesomeIcon style={{ fontSize: "2rem", marginRight: "1rem" }} icon={faCircleUser} />
                Se connecter
              </Button>
            </>
          )}
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

export default React.memo(Navbar);
