/* EXTERNAL */
import { faHandshake, faHandshakeSlash, faBuilding, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import colors from "../../../resources/cssConstant";

/* LOCAL */
import { AccompanyingStatus } from "../../../interfaces/Form";
import GenericChip from "../../Generics/GenericChip/GenericChip";

interface AccompanimentChipProps {
  accompanimentType: AccompanyingStatus;
}

export default function AccompanimentChip({ accompanimentType }: AccompanimentChipProps) {
  const renderChip = () => {
    switch (accompanimentType) {
      case AccompanyingStatus.ACCOMPANIED:
        return <GenericChip text="Accompagné" icon={faHandshake} color={colors.accompanied} />;
      case AccompanyingStatus.NOT_ACCOMPANIED:
        return <GenericChip text="Non-accompagné" icon={faHandshakeSlash} color={colors.notAccompanied} />;
      case AccompanyingStatus.FIELD_ACCOMPANIMENT:
        return <GenericChip text="Accompagnement terrain" icon={faBuilding} color={colors.fieldAccompaniment} />;
      default:
        return <GenericChip text="INCONNU" icon={faQuestionCircle} color={colors.accompanied} />;
    }
  };

  return renderChip();
}
