import { Stack, Typography } from "@mui/material";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useAtom, useAtomValue } from "jotai";
import { toast } from "react-toastify";
import { Role } from "../../../../../interfaces/User";
import GenericButton from "../../../../Generics/buttons/GenericButton";
import colors from "../../../../../resources/cssConstant";
import { ProgressStatus } from "../../../../../interfaces/Form";
import { currentComplementaryFormAtom, userAtom } from "../../../../../atoms/Atoms";
import ComplementaryFormService from "../../../../../services/ComplementaryFormService";
import { StatusCode } from "../../../../../resources/StatusCode";

export default function ComplementaryFormEndPage() {
  const user = useAtomValue(userAtom);
  const [complementaryForm, setComplementaryForm] = useAtom(currentComplementaryFormAtom);

  const completeComplementaryForm = async () => {
    const res = await ComplementaryFormService().completeComplementaryForm(complementaryForm.uuid);
    if (res.data) {
      setComplementaryForm(res.data);
      toast.success("Votre questionnaire a bien été complété.");
    } else {
      toast.error("Impossible de sauvegarder vos réponses pour le moment.");
    }
  };

  const validateComplementaryForm = async () => {
    const res = await ComplementaryFormService().finalizeComplementaryForm(complementaryForm.uuid);
    if (res?.status === StatusCode.NO_CONTENT) {
      const updatedForm = { ...complementaryForm };
      updatedForm.progressStatus = ProgressStatus.FINALIZED;
      setComplementaryForm(updatedForm);
      toast.success("Ce questionnaire a bien été validé.");
    } else {
      toast.error("Impossible de finaliser le document pour l'instant.");
    }
  };

  const updateComplementaryFormStatus = async () => {
    if (user.role === Role.COMPANY_USER) {
      await completeComplementaryForm();
    } else {
      await validateComplementaryForm();
    }
  };

  const renderEndSectionCompleted = () =>
    user.role === Role.COMPANY_USER ? (
      <Typography variant="body2">
        Merci d'avoir pris le temps de répondre ! Un préventeur consultera prochainement vos réponses afin de finaliser
        et compléter le dossier de votre entreprise.
      </Typography>
    ) : (
      <Stack spacing={5} alignItems="flex-start">
        <Typography variant="body1" gutterBottom>
          Ce questionnaire a été complété par un collaborateur de l'entreprise.
        </Typography>
        <Typography variant="body1" gutterBottom>
          En le validant, les collaborateurs pourront toujours voir les réponses apportées, mais ne pourront plus les
          modifier. En tant que préventeur, vous pouvez toujours modifier vos réponses. Vous pouvez également utiliser
          les réponses du questionnaire validé pour en démarrer un nouveau.
        </Typography>
        <GenericButton
          onClick={updateComplementaryFormStatus}
          text="Valider ce questionnaire"
          icon={faCheck}
          color={colors.primary}
        />
      </Stack>
    );

  const renderEndSectionNotFinalized = () => (
    <Stack spacing={5} alignItems="flex-start">
      {user.role === Role.COMPANY_USER ? (
        <>
          <Typography variant="body2">
            Merci d'avoir pris le temps de répondre ! Un préventeur consultera prochainement vos réponses afin de
            finaliser et compléter le dossier de votre entreprise.
          </Typography>
          <Typography variant="body2">Si vous avez terminé, vous pouvez valider ce questionnaire.</Typography>
        </>
      ) : (
        <>
          <Typography variant="body2">Attention :</Typography>
          <Typography variant="body1" gutterBottom>
            En validant ce questionnaire, les collaborateurs de l’entreprise pourront toujours voir les réponses
            apportées, mais ne pourront plus les modifier. En tant que préventeur, vous pouvez toujours modifier vos
            réponses. Vous pouvez également utiliser les réponses du questionnaire validé pour en démarrer un nouveau.
          </Typography>
        </>
      )}
      <GenericButton
        onClick={updateComplementaryFormStatus}
        text="Valider ce questionnaire"
        icon={faCheck}
        color={colors.primary}
      />
    </Stack>
  );

  const renderEndSectionFinalized = () =>
    user.role === Role.COMPANY_USER ? (
      <Typography variant="body2">
        Merci d'avoir pris le temps de répondre à ce questionnaire ! Un préventeur a consulté et validé vos réponses.
        Elles serviront à compléter le dossier de votre entreprise.
      </Typography>
    ) : (
      <Typography variant="body2">Ce questionnaire a été finalisé.</Typography>
    );

  const renderEndSectionArchived = () => (
    <Stack spacing={5} alignItems="flex-start">
      <Stack spacing={2} alignItems="flex-start">
        <Typography variant="body2">Ce questionnaire est archivé.</Typography>
        <Typography variant="body1" gutterBottom>
          Il n'est plus possible de modifier les réponses apportées. Les collaborateurs de l’entreprise n'ont plus accès
          à ce document. En tant que préventeur, vous pouvez toujours consulter vos réponses. Vous pouvez également
          utiliser les réponses du questionnaire pour en démarrer un nouveau.
        </Typography>
      </Stack>
    </Stack>
  );

  const renderEndSection = () => {
    const status: ProgressStatus = complementaryForm.progressStatus;
    switch (status) {
      case ProgressStatus.NOT_FINALIZED:
        return renderEndSectionNotFinalized();
      case ProgressStatus.COMPLETED:
        return renderEndSectionCompleted();
      case ProgressStatus.FINALIZED:
        return renderEndSectionFinalized();
      case ProgressStatus.ARCHIVED:
      default:
        return renderEndSectionArchived();
    }
  };

  return (
    <Stack spacing={5} width="50%">
      <Typography variant="body1">Vous êtes arrivé à la fin du questionnaire !</Typography>
      {renderEndSection()}
    </Stack>
  );
}
