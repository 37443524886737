import { GridCellProps, GridCell } from "@mui/x-data-grid";

export interface CustomerCellProps extends GridCellProps {
  fieldsToApplyPlaceholder: string[];
}

export default function CustomCell({ fieldsToApplyPlaceholder = [], children, ...other }: Readonly<CustomerCellProps>) {
  return (
    <GridCell {...other}>
      {other.cellMode !== "edit" &&
      (other.value == null || other.value === "") &&
      fieldsToApplyPlaceholder.includes(other.field)
        ? other.placeholder
        : children}
    </GridCell>
  );
}
