export const publicRoutes = {
  login: "/connexion",
  createAccount: "/creation-de-compte",
  changePassword: "/recover-password",
};

export const backOfficeRoutes = {
  home: "/entreprises",
  company: "/entreprises/:companyName",
  company_representatives: "/utilisateurs",
  experts: "/preventeurs",
  all_forms: "/questionnaires-oira",
  specific_form: "/questionnaires-oira/:formTemplateName",
  duerp: "/entreprises/duerp/:refNumber",
  complementary_form: "/entreprises/questionnaire-complementaire/:refNumber",
  profile: "/profil",
  administration: "/administration",
};

export const frontOfficeRoutes = {
  home: "/entreprises",
  profile: "/profil",
  company: "/entreprises/:companyName",
  duerp: "/entreprises/duerp/:refNumber",
  complementary_form: "/entreprises/questionnaire-complementaire/:refNumber",
};
