// WithAxios.js
import { useMemo } from "react";
import { AxiosError } from "axios";
import { useAuth } from "./useAuth";
import HttpService from "../services/HttpService";
import { AmetraToken } from "../resources/AppConstants";

export default function AxiosInterceptor({ children }: { children: JSX.Element }) {
  const auth = useAuth();

  useMemo(() => {
    HttpService.axiosApiInstance.interceptors.response.use(
      (response) => {
        const token = response.headers[AmetraToken];
        if (token) auth.updateToken(token as string);
        return response;
      },
      (error: AxiosError) => error.response,
    );
  }, []);

  return children;
}
