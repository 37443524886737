// === Import: NPM
import React, { useState, useContext, useEffect } from "react";
import { AxiosResponse } from "axios";
import { useAtom } from "jotai";
import { toast } from "react-toastify";
import { AmetraToken, ApplicationTokenExpirationDate } from "../resources/AppConstants";
import AuthenticationService from "../services/AuthenticationService";
import HttpService from "../services/HttpService";
import LocaleStorageService from "../services/LocaleStorageService";
import { IUserLogin, IUserShort, Role } from "../interfaces/User";
import { userShortAtom, companyUserAtom, backOfficeUserAtom, userCompaniesAtom } from "../atoms/Atoms";
import UserService from "../services/UserService";
import ExpertService from "../services/ExpertService";

export interface UseAuthProps {
  updateToken: (token: string) => void;
  login: (data: IUserLogin) => AxiosResponse<IUserShort>;
  logged: boolean;
  logout: () => void;
}

export const authContext = React.createContext<any>({});

export const useAuth = (): UseAuthProps => useContext(authContext);

function useProvideAuth() {
  const [, setUserShort] = useAtom(userShortAtom);
  const [, setCompanyUser] = useAtom(companyUserAtom);
  const [, setBoUser] = useAtom(backOfficeUserAtom);
  const [, setUserCompanies] = useAtom(userCompaniesAtom);
  const [logged, setLogged] = useState<boolean | null>(null);

  const logout = () => {
    setLogged(false);
    LocaleStorageService().removeLocaleStorageItem(AmetraToken);
    LocaleStorageService().removeLocaleStorageItem(ApplicationTokenExpirationDate);
    HttpService.resetAxiosInstance();
    setUserShort({ uuid: "", role: Role.UNDEFINED });
    setUserCompanies([]);
    setBoUser({ uuid: "", firstname: "", lastname: "", email: "", role: Role.UNDEFINED, companies: [] });
    setCompanyUser({
      uuid: "",
      firstname: "",
      lastname: "",
      email: "",
      role: Role.COMPANY_USER,
      internal: true,
      positionTitle: "",
      companies: [],
    });
  };

  const isTokenValid = () => {
    const retrievedToken = LocaleStorageService().getLocaleStorageItem(AmetraToken);
    const retrievedTokenExpirationDate = LocaleStorageService().getLocaleStorageItem(ApplicationTokenExpirationDate);
    if (retrievedToken && retrievedTokenExpirationDate) {
      if (parseInt(retrievedTokenExpirationDate, 10) > Date.now() / 1000) {
        return true;
      }
    }
    return false;
  };

  const setTokenExpirationDate = () => {
    const tokenExpirationDate = Date.now() + 10800000;
    LocaleStorageService().setLocaleStorageItem(ApplicationTokenExpirationDate, tokenExpirationDate.toString());
  };

  const updateToken = (token: string) => {
    LocaleStorageService().setLocaleStorageItem(AmetraToken, token);
    setTokenExpirationDate();
    HttpService.setToken(token);
    setLogged(true);
  };

  const updateUser = async (role: Role, userUuid: string) => {
    switch (role) {
      case Role.COMPANY_USER:
        UserService()
          .getUserById(userUuid)
          .then((companyUserRes) => {
            setCompanyUser(companyUserRes.data);
            setUserCompanies(companyUserRes.data.companies);
          });
        break;
      case Role.EXPERT:
      case Role.ADMIN:
        ExpertService()
          .getExpertById(userUuid)
          .then((boUserRes) => setBoUser(boUserRes.data));
        break;
      default:
        break;
    }
  };

  const introspect = async () => {
    const currentToken = LocaleStorageService().getLocaleStorageItem(AmetraToken);
    HttpService.setToken(currentToken);
    const res = await AuthenticationService().introspect();
    if (res?.data) {
      const token = res.headers[AmetraToken] as string;
      updateToken(token);
      setUserShort({ uuid: res.data.uuid, role: res.data.role });
      await updateUser(res.data.role, res.data.uuid);
    } else {
      toast.error("Votre session a expiré. Veuillez vous reconnecter.");
      logout();
    }
  };

  useEffect(() => {
    const isValid = isTokenValid();
    if (isValid) {
      introspect().then();
    } else {
      logout();
    }
  }, []);

  const login = async (data: IUserLogin) => {
    const res = await AuthenticationService().login(data);
    if (res.data) {
      const token = res.headers[AmetraToken] as string;
      updateToken(token);
      setUserShort({ uuid: res.data.uuid, role: res.data.role });
      await updateUser(res.data.role, res.data.uuid);
    }
    return res;
  };

  return {
    updateToken,
    login,
    logged,
    logout,
  };
}

export function ProvideAuth({ children }: Readonly<{ children: JSX.Element }>) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
