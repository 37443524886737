const colors: Colors = {
  primary: "#253E8C",
  headerColor: "#E4F5F7",
  mainOrange: "#FC963F",
  stepperActionValidate: "#4EBCCB",
  accompanied: "#2AA723",
  notAccompanied: "#A66112",
  fieldAccompaniment: "#541aab",
  notFinalized: "#C64225",
  completed: "#0096BF",
  finalized: "#253E8C",
  archived: "#172859",
  navbarTitle: "#75ACB1",
  white: "#FFFFFF",
  listItemHover: "#6379BD",
  errors: "#d32f2f",
  deleteIcon: "#F00",
  lightBlue: "#ebf0ff",
  disabledIcon: "#cccccc",
  cardBackground: "#FEEAD9",
  disabledText: "rgba(0, 0, 0, 0.38)",
};

export default colors;

interface Colors {
  primary: string;
  headerColor: string;
  mainOrange: string;
  stepperActionValidate: string;
  accompanied: string;
  notAccompanied: string;
  fieldAccompaniment: string;
  notFinalized: string;
  finalized: string;
  completed: string;
  archived: string;
  navbarTitle: string;
  white: string;
  listItemHover: string;
  errors: string;
  deleteIcon: string;
  lightBlue: string;
  disabledIcon: string;
  cardBackground: string;
  disabledText: string;
}
