import { Navigate, Route, Routes } from "react-router-dom";
import { backOfficeRoutes } from "../resources/Routes";
import Companies from "../components/Commons/Companies/Companies";
import Experts from "../components/BO-components/Directories/Experts/Experts";
import Users from "../components/BO-components/Directories/Users/Users";
import OiraForms from "../components/BO-components/Directories/OiraForms/OiraForms";
import { Role } from "../interfaces/User";
import RoleChecker from "./RoleChecker";
import Template from "../components/BO-components/PageTemplate/Template";
import DocumentTable from "../components/Commons/CompanyDocuments/DocumentTable";
import Duerp from "../components/Commons/Duerp/Duerp";
import Profile from "../components/Commons/Profile/Profile";
import OiraFormGroupPage from "../components/BO-components/OiraForms/Oira";
import ComplementaryForm from "../components/Commons/ComplementaryForm/ComplementaryForm";
import Administration from "../components/BO-components/Administration/Administration";

export default function BORouter() {
  const renderComponent = (children: JSX.Element) => <Template>{children}</Template>;

  return (
    <Routes>
      <Route path={backOfficeRoutes.home} element={renderComponent(<Companies />)} />
      <Route path={backOfficeRoutes.company} element={renderComponent(<DocumentTable />)} />
      <Route path={backOfficeRoutes.duerp} element={<Duerp />} />
      <Route path={backOfficeRoutes.complementary_form} element={<ComplementaryForm />} />
      <Route path={backOfficeRoutes.company_representatives} element={renderComponent(<Users />)} />
      <Route
        path={backOfficeRoutes.experts}
        element={<RoleChecker roles={[Role.ADMIN]}>{renderComponent(<Experts />)}</RoleChecker>}
      />
      <Route
        path={backOfficeRoutes.all_forms}
        element={<RoleChecker roles={[Role.ADMIN]}>{renderComponent(<OiraForms />)}</RoleChecker>}
      />
      <Route
        path={backOfficeRoutes.specific_form}
        element={<RoleChecker roles={[Role.ADMIN]}>{renderComponent(<OiraFormGroupPage />)}</RoleChecker>}
      />
      <Route path={backOfficeRoutes.profile} element={<Profile />} />
      <Route
        path={backOfficeRoutes.administration}
        element={<RoleChecker roles={[Role.ADMIN]}>{renderComponent(<Administration />)}</RoleChecker>}
      />
      <Route path="*" element={<Navigate to={backOfficeRoutes.home} />} />
    </Routes>
  );
}
