import { AxiosResponse } from "axios";
import HttpService from "./HttpService";
import { IOiraForm, IOiraShortForm } from "../interfaces/OiraForm";
import { Endpoints } from "../resources/Endpoints";

export default function FormService() {
  const getAllOiras = (): Promise<AxiosResponse<IOiraShortForm[]>> =>
    HttpService.fetch({
      url: Endpoints.oira.base,
      method: "get",
    });

  const getAllPublishedOiras = (): Promise<AxiosResponse<IOiraShortForm[]>> =>
    HttpService.fetch({
      url: Endpoints.oira.base,
      method: "get",
      params: {
        published: true,
      },
    });

  const getOiraById = (uuid: string): Promise<AxiosResponse<IOiraForm>> =>
    HttpService.fetch({
      url: Endpoints.oira.getOira(uuid),
      method: "get",
    });

  const getOiraGroup = (groupUuid: string): Promise<AxiosResponse<IOiraForm[]>> =>
    HttpService.fetch({
      url: Endpoints.oira.getOiraGroup(groupUuid),
      method: "get",
    });

  const publishOiraVersion = (uuid: string): Promise<AxiosResponse<IOiraForm[]>> =>
    HttpService.fetch({
      url: Endpoints.oira.publishOiraVersion(uuid),
      method: "put",
    });

  const unpublishOira = (groupUuid: string): Promise<AxiosResponse> =>
    HttpService.fetch({
      url: Endpoints.oira.unpublishOira(groupUuid),
      method: "put",
    });

  const updateOiraSectionDescription = (
    oiraUuid: string,
    sectionUuid: string,
    description: string,
  ): Promise<AxiosResponse<IOiraForm>> =>
    HttpService.fetch({
      url: Endpoints.oira.updateSectionDescription(oiraUuid, sectionUuid),
      method: "put",
      data: description,
      headers: { "Content-Type": "text/plain" },
    });

  const updateOiraQuestionDescription = (
    oiraUuid: string,
    questionUuid: string,
    description: string,
  ): Promise<AxiosResponse<IOiraForm>> =>
    HttpService.fetch({
      url: Endpoints.oira.updateQuestionDescription(oiraUuid, questionUuid),
      method: "put",
      data: description,
      headers: { "Content-Type": "text/plain" },
    });

  const importOiraForm = (file: File): Promise<AxiosResponse<IOiraForm>> => {
    const formData = new FormData();
    formData.append("file", file);
    return HttpService.fetch({
      url: Endpoints.oira.importOiraForm,
      method: "post",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
  };

  const importOiraFormInGroup = (file: File, groupUuid: string): Promise<AxiosResponse<IOiraForm>> => {
    const formData = new FormData();
    formData.append("file", file);
    return HttpService.fetch({
      url: Endpoints.oira.importOiraInGroup(groupUuid),
      method: "post",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
  };

  const downloadOiraForm = (uuid: string): Promise<AxiosResponse<Blob>> =>
    HttpService.fetch({
      url: Endpoints.oira.downloadOiraForm(uuid),
      method: "get",
      responseType: "blob",
    });

  return {
    getAllOiras,
    getAllPublishedOiras,
    getOiraById,
    updateOiraSectionDescription,
    updateOiraQuestionDescription,
    getOiraGroup,
    publishOiraVersion,
    unpublishOira,
    importOiraForm,
    importOiraFormInGroup,
    downloadOiraForm,
  };
}
