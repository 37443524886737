import { Box, Skeleton, Stack, Typography } from "@mui/material";

function SkeletonOiraItem() {
  return (
    <Typography variant="body2" sx={{ height: "150px", width: "100%" }}>
      <Skeleton sx={{ height: "150px", width: "100%" }} />
    </Typography>
  );
}

export default function SkeletonOira() {
  return (
    <Stack spacing={1} height="100%" sx={{ m: 2 }}>
      {[...Array(5)].map((_, index) => (
        <Box key={index}> {SkeletonOiraItem()} </Box>
      ))}
    </Stack>
  );
}
