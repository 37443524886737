import { AxiosResponse } from "axios";
import { IComplementaryForm, IComplementarySection } from "../interfaces/ComplementaryForm";
import HttpService from "./HttpService";
import { Endpoints } from "../resources/Endpoints";
import { DocumentFormat } from "../interfaces/Form";

export default function ComplementaryFormService() {
  const getFormTemplate = (): Promise<AxiosResponse<IComplementarySection[]>> =>
    HttpService.fetch({
      url: Endpoints.complementaryForms.template,
      method: "get",
    });

  const createComplementaryForm = (
    companyUuid: string,
    duerpsUuids: string[],
  ): Promise<AxiosResponse<IComplementaryForm>> =>
    HttpService.fetch({
      url: Endpoints.complementaryForms.base,
      method: "post",
      data: { companyUuid, duerpsUuids },
    });

  const getComplementaryFormByUuid = (uuid: string): Promise<AxiosResponse<IComplementaryForm>> =>
    HttpService.fetch({
      url: Endpoints.complementaryForms.getComplementaryForm(uuid),
      method: "get",
    });

  const associateDuerpsToComplementaryForm = (
    uuid: string,
    duerpsUuids: string[],
  ): Promise<AxiosResponse<IComplementaryForm>> =>
    HttpService.fetch({
      url: Endpoints.complementaryForms.associateDuerpsToComplementaryForm(uuid),
      method: "put",
      data: duerpsUuids,
    });

  const duplicateComplementaryForm = (
    uuid: string,
    companyUuid: string,
    duerpsUuids: string[],
  ): Promise<AxiosResponse<IComplementaryForm>> =>
    HttpService.fetch({
      url: Endpoints.complementaryForms.duplicateComplementaryForm(uuid),
      method: "post",
      data: { companyUuid, duerpsUuids },
    });

  const updateComplementaryForm = (
    uuid: string,
    complementaryForm: IComplementaryForm,
  ): Promise<AxiosResponse<IComplementaryForm>> =>
    HttpService.fetch({
      url: Endpoints.complementaryForms.getComplementaryForm(uuid),
      method: "put",
      data: complementaryForm,
    });

  const deleteComplementaryForm = (uuid: string): Promise<AxiosResponse<IComplementaryForm>> =>
    HttpService.fetch({
      url: Endpoints.complementaryForms.getComplementaryForm(uuid),
      method: "delete",
    });

  const completeComplementaryForm = (uuid: string): Promise<AxiosResponse<IComplementaryForm>> =>
    HttpService.fetch({
      url: Endpoints.complementaryForms.completeComplementaryForm(uuid),
      method: "put",
    });

  const finalizeComplementaryForm = (uuid: string): Promise<AxiosResponse<IComplementaryForm>> =>
    HttpService.fetch({
      url: Endpoints.complementaryForms.finalizeComplementaryForm(uuid),
      method: "put",
    });

  const archiveComplementaryForm = (uuid: string): Promise<AxiosResponse<IComplementaryForm>> =>
    HttpService.fetch({
      url: Endpoints.complementaryForms.archiveComplementaryForm(uuid),
      method: "put",
    });

  const downloadBusinessDocument = (uuid: string, format: DocumentFormat): Promise<AxiosResponse<Blob>> =>
    HttpService.fetch({
      url: Endpoints.complementaryForms.downloadComplementaryForm(uuid),
      method: "get",
      params: {
        format,
      },
      responseType: "blob",
    });

  return {
    getFormTemplate,
    createComplementaryForm,
    getComplementaryFormByUuid,
    updateComplementaryForm,
    deleteComplementaryForm,
    completeComplementaryForm,
    finalizeComplementaryForm,
    duplicateComplementaryForm,
    associateDuerpsToComplementaryForm,
    downloadBusinessDocument,
    archiveComplementaryForm,
  };
}
