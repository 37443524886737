import { Stack, Typography } from "@mui/material";

interface LegalNoticePartEightProps {
  organizationName: string;
}

export default function LegalNoticePartEight({ organizationName }: Readonly<LegalNoticePartEightProps>) {
  return (
    <Stack spacing={1}>
      <Typography variant="h3">8. Propriété Intellectuelle </Typography>
      <Typography>
        En accédant à la plateforme MONDUERP, {organizationName} et l’Adhérent reconnaissent expressément que les
        contenus mis à leur disposition, les images, photographies, graphismes, agencements, logos, marques, textes,
        sont la propriété exclusive de l’Editeur, ou qu’ils disposent d’un droit d’utilisation de ces derniers, au même
        titre que la structure du site, les icônes, et sons illustrant la Plateforme.
      </Typography>
      <Typography>
        Les contenus proposés et la Plateforme elle-même sont protégés au titre des droits d’auteurs, et donc par le
        code de la propriété intellectuelle français ainsi que par les traités et accords internationaux relatifs à la
        protection des droits de propriété intellectuelle.
      </Typography>
      <Typography>
        L’Editeur bénéficie par ailleurs d’un partenariat avec l’Institut National de Recherche et de Sécurité lui
        permettant d’intégrer à la Plateforme les fonctionnalités et contenus OiRA.
      </Typography>
      <Typography>
        A ce titre, tous les contenus et éléments constitutifs de la Plateforme ne peuvent être reproduits totalement ou
        partiellement sans autorisation expresse de l’Editeur sous peine de poursuites judiciaires civiles ou pénales.
      </Typography>
      <Typography>
        L’Editeur est titulaire de tous les droits, titres et intérêts portant sur la Plateforme et les contenus créés
        par lui, y compris tous droits de propriété intellectuelle incluant, notamment, tous droits relatifs aux droits
        d’auteur, droits des dessins et modèles, marques, enseignes, noms commerciaux, dénomination sociale, noms de
        domaine, technologie, savoir-faire, procédés, formules, codes sources et codes exécutables.
      </Typography>
      <Typography>
        L’Editeur concède une licence non exclusive et temporaire à {organizationName} afin de pouvoir utiliser la
        Plateforme MONDUERP et les contenus dans le strict cadre de ces CGU ; toutes autres utilisations sont prohibées.
      </Typography>
      <Typography>
        Sont notamment expressément interdits, toutes reproduction, représentation, adaptation, exploitation,
        distribution, diffusion, utilisation commerciale ou non, traduction, arrangement, compilation, transformation ou
        toute création d’œuvres dérivées ou composites de tout ou partie des œuvres et/ou tout autre contenu figurant
        sur la Plateforme MONDUERP sur quelque support que ce soit et par tout procédé que ce soit, actuel ou futur,
        toute extraction ou réutilisation, y compris à des fins privées, d’une partie substantielle du contenu des bases
        de données constituées.
      </Typography>
      <Typography>
        Ces agissements sont susceptibles de constituer des actes de contrefaçon sanctionnés pénalement et civilement,
        engageant la responsabilité de leur auteur.
      </Typography>
    </Stack>
  );
}
