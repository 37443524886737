import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { ListItemIcon, MenuItem, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { useAtomValue } from "jotai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericIconButton from "../../../Generics/buttons/GenericIconButton";
import GenericDialog from "../../../Generics/GenericDialog/GenericDialog";
import { IShortForm, Type } from "../../../../interfaces/Form";
import { userShortAtom } from "../../../../atoms/Atoms";
import { Role } from "../../../../interfaces/User";
import colors from "../../../../resources/cssConstant";
import ComplementaryFormService from "../../../../services/ComplementaryFormService";
import DuerpService from "../../../../services/DuerpService";
import { StatusCode } from "../../../../resources/StatusCode";

interface DeleteDocumentButtonProps {
  document: IShortForm;
  onValid: () => void;
  menuButton?: boolean;
}
export default function DeleteDocumentButton({ document, onValid, menuButton }: Readonly<DeleteDocumentButtonProps>) {
  const user = useAtomValue(userShortAtom);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const isBackOfficeUser = user.role !== Role.COMPANY_USER;
  const isDuerp = document.documentType === Type.DUERP;
  const typeLabel = isDuerp ? "DUERP" : "Questionnaire complémentaire";

  const handleDeleteDocument = async () => {
    let res;
    if (isDuerp) {
      res = await DuerpService().deleteDuerp(document.uuid);
    } else {
      res = await ComplementaryFormService().deleteComplementaryForm(document.uuid);
    }
    return res?.response ? res.response : res;
  };

  const handleArchiveDocument = async () =>
    document.documentType === Type.DUERP
      ? DuerpService().archiveDuerp(document.uuid)
      : ComplementaryFormService().archiveComplementaryForm(document.uuid);

  const handleDeleteOrArchive = async () => {
    const res = isBackOfficeUser ? await handleDeleteDocument() : await handleArchiveDocument();
    if (res.status === StatusCode.NO_CONTENT) {
      toast.success(`Votre document de référence ${document.reference} a bien été supprimé.`);
    } else if (res.status === StatusCode.CONFLICT) {
      toast.error(
        `Impossible de supprimer le document de référence ${document.reference}. Il est lié à un ou plusieurs documents.`,
      );
    } else {
      toast.error(`Impossible de supprimer le document de référence ${document.reference}.`);
    }
    onValid();
    setOpenDeleteModal(false);
  };

  const renderDeleteModal = () => (
    <GenericDialog
      openDialog={openDeleteModal}
      handleClose={() => setOpenDeleteModal(false)}
      title={isDuerp ? "Supprimer le DUERP" : "Supprimer le questionnaire complémentaire"}
      onValid={() => handleDeleteOrArchive()}
    >
      <Stack>
        <Typography variant="modalContent">
          Voulez-vous vraiment supprimer le {typeLabel} de référence {document.reference} ?
        </Typography>
        <Typography variant="modalContent">Cette action est irréversible.</Typography>
      </Stack>
    </GenericDialog>
  );

  const renderMenuButton = () => (
    <MenuItem onClick={() => setOpenDeleteModal(true)}>
      <ListItemIcon>
        <FontAwesomeIcon icon={faTrash} size="xs" color={colors.deleteIcon} />
      </ListItemIcon>
      <Typography variant="body1">Supprimer le document</Typography>
    </MenuItem>
  );

  return (
    <>
      {renderDeleteModal()}
      {menuButton ? (
        renderMenuButton()
      ) : (
        <GenericIconButton
          onClick={() => setOpenDeleteModal(true)}
          icon={faTrash}
          tooltip="Supprimer le document"
          color={colors.deleteIcon}
        />
      )}
    </>
  );
}

DeleteDocumentButton.defaultProps = {
  menuButton: false,
};
