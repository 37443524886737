import { Dispatch, SetStateAction } from "react";
import { GridActionsCellItem, GridCellEditCommitParams, GridColDef } from "@mui/x-data-grid";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericDatagrid from "../../../../Generics/GenericDatagrid/GenericDatagrid";
import { SPSTITableRow } from "../../../../../interfaces/Form";
import colors from "../../../../../resources/cssConstant";

interface ComplementaryFormSPSTITableProps {
  tableRows: SPSTITableRow[];
  setTableRows: Dispatch<SetStateAction<SPSTITableRow[]>>;
}

export default function ComplementaryFormSPSTITable({
  tableRows,
  setTableRows,
}: Readonly<ComplementaryFormSPSTITableProps>) {
  const deleteRow = (id: number) => {
    let updatedRows = [...tableRows];
    updatedRows = updatedRows.filter((row) => row.id !== id);
    setTableRows(updatedRows);
  };

  const renderDeleteRow = (id: number) => [
    <GridActionsCellItem
      key={id}
      icon={
        <Tooltip arrow title="Supprimer cette ligne">
          <FontAwesomeIcon size="sm" color={colors.deleteIcon} icon={faTrash} />
        </Tooltip>
      }
      label="delete"
      onClick={() => {
        deleteRow(id);
      }}
    />,
  ];

  const columnGroupingModel = [
    {
      groupId: "Suivi individuel simple",
      headerAlign: "center",
      children: [{ field: "sis_cdi" }, { field: "sis_cdd" }, { field: "sis_inter" }, { field: "sis_saison" }],
    },
    {
      groupId: "Suivi individuel renforcé",
      headerAlign: "center",
      children: [{ field: "sir_cdi" }, { field: "sir_cdd" }, { field: "sir_inter" }, { field: "sir_saison" }],
    },
    {
      groupId: "Suivi individuel adapté",
      headerAlign: "center",
      children: [{ field: "sia_cdi" }, { field: "sia_cdd" }, { field: "sia_inter" }, { field: "sia_saison" }],
    },
  ];

  const createColumn = (field, headerName, type, minWidth?): GridColDef => ({
    field,
    headerName,
    editable: true,
    sortable: false,
    headerAlign: "center",
    flex: 1,
    minWidth: minWidth,
    align: "center",
    type,
    valueGetter: (params) => {
      if (params.value && typeof params.value === "string") {
        return params.value.replaceAll(",", ";")
      } else {
        return params.value;
      }
    },
    valueParser: (value) => {
      if (value && typeof value === "string") {
        return value.replaceAll(",", ";")
      } else {
        return value;
      }
    }
  });

  const columns = [
    {
      field: "actions",
      type: "actions",
      headerName: "",
      headerAlign: "center",
      flex: 0.5,
      align: "center",
      editable: false,
      sortable: false,
      getActions: (params) => renderDeleteRow(params.row.id),
    },
    createColumn("jobs", "Postes", "string", 200),
    createColumn("sis_cdi", "CDI", "number"),
    createColumn("sis_cdd", "CDD", "number"),
    createColumn("sis_inter", "Intérimaire", "number"),
    createColumn("sis_saison", "Saisonnier", "number"),
    createColumn("sir_cdi", "CDI", "number"),
    createColumn("sir_cdd", "CDD", "number"),
    createColumn("sir_inter", "Intérimaire", "number"),
    createColumn("sir_saison", "Saisonnier", "number"),
    createColumn("sia_cdi", "CDI", "number"),
    createColumn("sia_cdd", "CDD", "number"),
    createColumn("sia_inter", "Intérimaire", "number"),
    createColumn("sia_saison", "Saisonnier", "number"),
  ];

  const onCellEditCommit = (params: GridCellEditCommitParams) => {
    const rowsCopy = [...tableRows];
    const isNotNumberCell = params.field === "jobs";
    const valueToUpdate = rowsCopy.findIndex((row) => row.id === params.id);
    const emptyValue = isNotNumberCell ? "" : 0;
    const controlledValue = params.value !== "" ? params.value : emptyValue;
    const numberValue = Math.abs(parseInt(controlledValue, 10)) ?? 0;
    rowsCopy[valueToUpdate][params.field] = isNotNumberCell ? controlledValue.toString() : numberValue;
    setTableRows(rowsCopy);
  };

  return (
    <GenericDatagrid
      columnGroupingModel={columnGroupingModel}
      columns={columns}
      size={10}
      rowsPerPageOptions={[10]}
      rows={tableRows}
      page={0}
      rowCount={0}
      onPageChange={() => undefined}
      onPageSizeChange={() => undefined}
      onCellEditCommit={onCellEditCommit}
      hideFooter
      emptyeditCellPlaceholder="Double-clic pour modifier"
      fieldsToApplyPlaceholder={["jobs"]}
    />
  );
}
