const httpsRegex =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

const httpRegex =
  /http?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

const isTextAnURL = (text: string) => httpsRegex.test(text) || httpRegex.test(text);

export const LinkifyText = (text: string) => {
  const parsedText = text.split(" ");

  return parsedText.map((textElement) => {
    const isURL = isTextAnURL(textElement);
    if (isURL) {
      const strippedURL = textElement.replaceAll(")", "<)<").replaceAll("(", "<(<").replaceAll("\n", "<\n<");
      const splitURL = strippedURL.split("<");
      return splitURL.map((element) => {
        let url = element;
        if (isTextAnURL(element)) {
          if (element.endsWith(".")) {
            url = element.slice(0, element.length - 1);
          }
          return (
            <a key={element} style={{ fontStyle: "italic" }} href={url} target="_blank" rel="noopener noreferrer">
              {element}
            </a>
          );
        }
        return ` ${element} `;
      });
    }
    return ` ${textElement} `;
  });
};
