import { useAtomValue } from "jotai";
import "react-toastify/dist/ReactToastify.css";
import { Box } from "@mui/material";
import { Role } from "../interfaces/User";
import BORouter from "./BORouter";
import FORouter from "./FORouter";
import PublicRouter from "./PublicRouter";
import AxiosInterceptor from "./AxiosInterceptor";
import { useAuth } from "./useAuth";
import { userShortAtom } from "../atoms/Atoms";
import StyledToastContainer from "../components/Generics/StyledToastContainer/StyledToastContainer";

export default function Router() {
  const auth = useAuth();
  const user = useAtomValue(userShortAtom);

  const renderRouter = () => {
    switch (user.role) {
      case Role.COMPANY_USER:
        return <FORouter />;
      case Role.ADMIN:
      case Role.EXPERT:
        return <BORouter />;
      default:
        return <PublicRouter />;
    }
  };

  return (
    <AxiosInterceptor>
      <Box sx={{ height: "calc(100% - 70px)" }}>
        <StyledToastContainer />
        {auth.logged !== null && renderRouter()}
      </Box>
    </AxiosInterceptor>
  );
}
