// === Import NPM
import React, { PropsWithChildren } from "react";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";

// === Import LOCAL
import colors from "../../../resources/cssConstant";
import GenericButton from "../buttons/GenericButton";

type GenericDialogProps = PropsWithChildren<{
  openDialog: boolean;
  handleClose: () => void;
  title: string;
  disabled?: boolean;
  cancelLabel?: string;
  confirmLabel?: string;
  onValid?: () => void;
}>;

function GenericDialog({
  openDialog,
  handleClose,
  title,
  children,
  confirmLabel = "Valider",
  cancelLabel = "Annuler",
  disabled = false,
  onValid,
}: Readonly<GenericDialogProps>) {
  return (
    <Dialog open={openDialog} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      {children}
      <DialogActions>
        <GenericButton text={cancelLabel} onClick={handleClose} color={colors.primary} />
        {onValid && (
          <GenericButton text={confirmLabel} onClick={() => onValid()} color={colors.mainOrange} disabled={disabled} />
        )}
      </DialogActions>
    </Dialog>
  );
}

export default GenericDialog;
