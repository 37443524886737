import { Stack, Typography } from "@mui/material";

export default function LegalNoticePartFour() {
  return (
    <Stack spacing={3}>
      <Typography variant="h3">4. Présentation et accès à la Plateforme MONDUERP</Typography>

      <Stack spacing={1}>
        <Typography variant="h4">4.1. Présentation des Services</Typography>
        <Typography>
          La Plateforme MONDUERP a vocation à aider les SPSTI dans l’accompagnement de leurs Adhérents à l’élaboration
          de leur document unique d’évaluation des risques professionnels et dans la réalisation de la fiche entreprise
          de chaque Adhérent.
        </Typography>
        <Typography>Les Services permettent notamment de :</Typography>
        <Typography>
          - Remplir les formulaires d’évaluation de santé au travail de manière numérique et en ligne ;
        </Typography>
        <Typography>
          - Centraliser les démarches de déclaration de santé au travail auprès des associations responsables de la
          santé sur le lieu d’emploi ;
        </Typography>
      </Stack>

      <Stack spacing={1}>
        <Typography variant="h4">4.2. Accès à la Plateforme MONDUERP</Typography>
        <Typography>
          L’accès à la Plateforme MONDUERP nécessite la création d’un compte sur l’Espace entreprise par l’Adhérent.
        </Typography>
        <Typography>
          Pour créer son Espace entreprise, l’Adhérent doit fournir certaines informations personnelles telles que ses
          prénoms, noms, adresse e-mail, fonction professionnelle, intitulé du poste.
        </Typography>
        <Typography>
          Lors de la première connexion, le mot de passe doit contenir entre 8 et 30 caractères, dont 1 lettre
          majuscule, 1 lettre minuscule, 1 chiffre et 1 caractère spécial. Chaque personne habilitée est entièrement
          responsable de l’utilisation de son code utilisateur et de son mot de passe qu’elle s’engage à conserver
          secret et à ne pas divulguer sous quelque forme que ce soit à des tiers non autorisés.
        </Typography>
        <Typography>
          A l’issue de cette phase et avant la validation définitive de la création de l’Espace entreprise, l’Adhérent
          se verra proposer de lire et d’accepter les présentes CGU, sans quoi il ne pourra bénéficier des Services de
          la Plateforme.
        </Typography>
        <Typography>
          Suite à la création de son compte dans l’Espace entreprise, l’Adhérent pourra ajouter de nouveaux utilisateurs
          en ajoutant des adresses électroniques associées à l’entreprise. Les comptes associés à ces emails disposeront
          des mêmes droits que l’Adhérent pour cette entreprise.
        </Typography>
        <Typography>
          Il appartient à l’Adhérent et à l’ensemble des utilisateurs de son Espace entreprise de prendre toutes les
          mesures de sécurité appropriées de façon à protéger les données et logiciels de la contamination d’éventuels
          virus ou programmes malfaisants.
        </Typography>
        <Typography>
          L’association du code utilisateur et du mot de passe permet d’accéder au portail adhérent. Le mot de passe est
          personnel.
        </Typography>
        <Typography>
          L’Adhérent certifie fournir des informations exactes, à jour et complètes. En cas de modification de ces
          informations, l’Adhérent s’engage à les mettre à jour sans délai.
        </Typography>
        <Typography>
          En cas de non-respect de ces règles, l’Éditeur se réserve le droit, sans préavis ni indemnité, de suspendre ou
          résilier l’espace entreprise et/ou de suspendre l’accès de l’Adhérent à la Plateforme MONDUERP.
        </Typography>
      </Stack>
    </Stack>
  );
}
