import { IShortForm } from "./Form";

export interface IComplementaryForm extends IShortForm {
  answersDictionary: IComplementaryAnswerDictionary;
  duerps: IShortForm[];
}

export interface IComplementaryAnswerDictionary {
  [k: string]: IComplementaryAnswerGroup;
}

export interface IComplementaryAnswerGroup {
  uuid: string | null;
  answers: IComplementaryAnswer[];
  expertComment: string;
}

export interface IComplementaryAnswer {
  uuid: string | null;
  value: string;
}

export interface IComplementarySection {
  label: string;
  enumValue: ISectionEnum;
  questions: IComplementaryQuestion[];
}

export interface IComplementaryQuestion {
  label: string;
  enumValue: IQuestionEnum;
  type: IQuestionType;
  options?: string[];
  parentValue?: IQuestionEnum;
}

export enum IQuestionType {
  SELECT = "SELECT",
  SINGLE_SELECT = "SINGLE_SELECT",
  TEXT = "TEXT",
  LONG_TEXT = "LONG_TEXT",
  BOOLEAN = "BOOLEAN",
  EMAIL = "EMAIL",
  PHONE = "PHONE",
  NUMBER = "NUMBER",
  DATE = "DATE",
  TABLE = "TABLE",
}

export enum ISectionEnum {
  NONE = "NONE",
  END = "END",
  GENERAL_INFORMATION = "GENERAL_INFORMATION",
  PREVENTION_MANAGEMENT = "PREVENTION_MANAGEMENT",
  WORKFORCE = "WORKFORCE",
  HYGIENE = "HYGIENE",
  HEALTH = "HEALTH",
  SPSTI_INFORMATION = "SPSTI_INFORMATION",
}

export enum IQuestionEnum {
  // default value
  NONE = "NONE",

  // SECTION 1
  COMPANY_NAME = "COMPANY_NAME",
  MEMBERSHIP_NUMBER = "MEMBERSHIP_NUMBER",
  COMPANY_CREATOR = "COMPANY_CREATOR",
  COMPANY_ADDRESS = "COMPANY_ADDRESS",
  COMPANY_EMAIL = "COMPANY_EMAIL",
  NAF_CODE = "NAF_CODE",
  SHORT_DESCRIPTION = "SHORT_DESCRIPTION",
  COLLECTIVE_CONVENTION = "COLLECTIVE_CONVENTION",

  // SECTION 2
  HAS_DUERP = "HAS_DUERP",
  DUERP_DATE = "DUERP_DATE",
  HAS_CSE = "HAS_CSE",
  RISK_FACTORS = "RISK_FACTORS",
  HAS_REFERENT = "HAS_REFERENT",
  REFERENT_NAME = "REFERENT_NAME",
  REFERENT_PHONE = "REFERENT_PHONE",
  REFERENT_EMAIL = "REFERENT_EMAIL",

  // SECTION 3
  NUMBER_OF_EMPLOYEES = "NUMBER_OF_EMPLOYEES",
  NUMBER_OF_WOMEN_EMPLOYEES = "NUMBER_OF_WOMEN_EMPLOYEES",
  NUMBER_OF_MEN_EMPLOYEES = "NUMBER_OF_MEN_EMPLOYEES",
  WORK_TIME_DESCRIPTION = "WORK_TIME_DESCRIPTION",
  HAS_ATYPICAL_HOURS = "HAS_ATYPICAL_HOURS",
  SHIFT_WORK_TIME_DESCRIPTION = "SHIFT_WORK_TIME_DESCRIPTION",
  HAVE_WORK_CLOTHES = "HAVE_WORK_CLOTHES",
  ARE_CLOTHES_CLEANED = "ARE_CLOTHES_CLEANED",

  // SECTION 4
  PREMISES_MAINTENANCE = "PREMISES_MAINTENANCE",
  HYGIENE_FACILITIES = "HYGIENE_FACILITIES",
  HAVE_LUNCH_SPACE = "HAVE_LUNCH_SPACE",
  AIR_CONDITIONED_AND_HEATED = "AIR_CONDITIONED_AND_HEATED",
  TEMPERATURE_SYSTEM_MAINTAINED_REGULARLY = "TEMPERATURE_SYSTEM_MAINTAINED_REGULARLY",
  HAS_DEFIBRILLATOR = "HAS_DEFIBRILLATOR",
  ASBESTOS_TECHNICAL_REPORT = "ASBESTOS_TECHNICAL_REPORT",

  // SECTION 5
  NUMBER_OF_ACCIDENTS = "NUMBER_OF_ACCIDENTS",
  NUMBER_OF_DISABILITIES = "NUMBER_OF_DISABILITIES",
  NUMBER_OF_INVESTIGATIONS = "NUMBER_OF_INVESTIGATIONS",
  DECLARED_PATHOLOGIES = "DECLARED_PATHOLOGIES",
  DECLARED_PATHOLOGIES_DESCRIPTION = "DECLARED_PATHOLOGIES_DESCRIPTION",

  // SECTION 6
  JOBS_TABLE = "JOBS_TABLE",
  MET_PERSON_NAME = "MET_PERSON_NAME",
  FORM_CREATION_DATE = "FORM_CREATION_DATE",
  PREVIOUS_FORM_CREATION_DATE = "PREVIOUS_FORM_CREATION_DATE",
  COMPANY_DOCTOR = "COMPANY_DOCTOR",
  EXPERT_NAME = "EXPERT_NAME",
  EXPERT_POSITION = "EXPERT_POSITION",
  ACTION_PRIORITY = "ACTION_PRIORITY",
}
