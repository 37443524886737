import { Navigate, Route, Routes } from "react-router-dom";
import { frontOfficeRoutes } from "../resources/Routes";
import Profile from "../components/Commons/Profile/Profile";
import Companies from "../components/Commons/Companies/Companies";
import DocumentTable from "../components/Commons/CompanyDocuments/DocumentTable";
import Duerp from "../components/Commons/Duerp/Duerp";
import ComplementaryForm from "../components/Commons/ComplementaryForm/ComplementaryForm";

export default function FORouter() {
  return (
    <Routes>
      <Route path={frontOfficeRoutes.home} element={<Companies />} />
      <Route path={frontOfficeRoutes.company} element={<DocumentTable />} />
      <Route path={frontOfficeRoutes.duerp} element={<Duerp />} />
      <Route path={frontOfficeRoutes.profile} element={<Profile />} />
      <Route path={frontOfficeRoutes.complementary_form} element={<ComplementaryForm />} />
      <Route path="*" element={<Navigate to={frontOfficeRoutes.home} />} />
    </Routes>
  );
}
