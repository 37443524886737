import { AxiosResponse } from "axios";
import HttpService from "./HttpService";
import {
  IBackOfficeUser,
  IBackOfficeUserCreation,
  IBackofficeUserRole,
  IBackOfficeUserUpdate,
} from "../interfaces/BackOfficeUser";
import { Endpoints } from "../resources/Endpoints";
import { IUserShort } from "../interfaces/User";

export default function ExpertService() {
  const getAllExperts = (): Promise<AxiosResponse<IBackOfficeUser[]>> =>
    HttpService.fetch({
      url: Endpoints.users.expertUsers.base,
      method: "get",
    });

  const getExpertById = (uuid: string): Promise<AxiosResponse<IBackOfficeUser>> =>
    HttpService.fetch({ url: `${Endpoints.users.expertUsers.base}/${uuid}`, method: "get" });

  const createExpert = (expertData: IBackOfficeUserCreation): Promise<AxiosResponse<IUserShort>> =>
    HttpService.fetch({ url: Endpoints.users.expertUsers.base, method: "post", data: expertData });

  const updateExpert = (uuid: string, expertData: IBackOfficeUserUpdate): Promise<AxiosResponse<IBackOfficeUser>> =>
    HttpService.fetch({ url: `${Endpoints.users.expertUsers.base}/${uuid}`, method: "put", data: expertData });

  const updateExpertRole = (uuid: string, expertRole: IBackofficeUserRole): Promise<AxiosResponse<IBackOfficeUser>> =>
    HttpService.fetch({
      url: `${Endpoints.users.expertUsers.base}/${uuid}/role`,
      method: "put",
      data: expertRole,
      headers: { "Content-Type": "application/json" },
    });

  const deleteExpert = (uuid: string): Promise<AxiosResponse<any>> =>
    HttpService.fetch({ url: `${Endpoints.users.expertUsers.base}/${uuid}`, method: "delete" });

  return {
    getAllExperts,
    getExpertById,
    createExpert,
    updateExpert,
    updateExpertRole,
    deleteExpert,
  };
}
