// === Import NPM
import React from "react";
import { Box, useTheme } from "@mui/material";

// === Import LOCAL
import DrawerComponent from "../Drawer/Drawer";

function Template({ children }: Readonly<{ children: JSX.Element }>) {
  const theme = useTheme();
  return (
    <>
      <DrawerComponent />
      <Box sx={{ ml: `${(theme.components?.MuiDrawer?.styleOverrides?.paper as any)?.minWidth}` }}>{children}</Box>
    </>
  );
}

export default Template;
