import { Stack, Typography } from "@mui/material";

export default function LegalNoticePartFive() {
  return (
    <Stack spacing={1}>
      <Typography variant="h3">5. Droits et obligations de l’Adhérent</Typography>
      <Typography>
        L’Adhérent reconnait avoir pris connaissance de la nature, de la destination et des modalités d’utilisation de
        la plateforme MONDUERP.
      </Typography>
      <Typography>
        Il s’engage à respecter les présentes CGU, sous peine de voir son accès au site internet supprimé et/ ou
        restreint.
      </Typography>
      <Typography>
        L’Adhérent s’engage à n’utiliser la Plateforme MONDUERP comme support afin de communiquer tout propos
        diffamants, injurieux, harcelants, diffamatoires, obscènes, pornographiques ou menaçants, et/ou qui portent
        atteinte à la vie privée d’autrui et plus généralement, tout contenu contraire aux lois et règlements en
        vigueur, ainsi qu’à l’ordre public et aux bonnes mœurs.
      </Typography>
      <Typography>
        Il s’engage à ne pas utiliser, reproduire ou exploiter tout contenu de la Plateforme MONDUERP dont il n’est
        l’auteur ou propriétaire conformément à l’article 7 des présentes.
      </Typography>
      <Typography>
        L’Adhérent peut modifier les coordonnées le concernant, changer ou régénérer son mot de passe ou le récupérer
        s’il l’a oublié.
      </Typography>
    </Stack>
  );
}
