import { useAtomValue } from "jotai";
import { Navigate } from "react-router-dom";
import { Role } from "../interfaces/User";
import { userShortAtom } from "../atoms/Atoms";
import { backOfficeRoutes } from "../resources/Routes";

export default function RoleChecker({ children, roles }: { children: JSX.Element; roles: Role[] }) {
  const user = useAtomValue(userShortAtom);

  const canAccess = () => (user.role ? roles.includes(user.role) : false);

  return canAccess() ? children : <Navigate to={backOfficeRoutes.home} />;
}
