/* eslint-disable no-sequences */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
// function to filter filteredInputs

// eslint-disable-next-line import/prefer-default-export
export const objectFilter = (object, filterFunction) =>
  Object.keys(object)
    .filter((objectKey) => filterFunction(object[objectKey]))
    .reduce((result, objectKey) => ((result[objectKey] = object[objectKey]), result), {});
