import { Skeleton } from "@mui/material";

export default function DuerpQuestionSkeleton() {
  return (
    <>
      <Skeleton sx={{ m: 3 }} variant="rectangular" width="100%" height="20%" />
      <Skeleton sx={{ m: 3 }} variant="rectangular" width="100%" height="50%" />
      <Skeleton sx={{ m: 3 }} variant="rectangular" width="100%" height="100%" />
    </>
  );
}
