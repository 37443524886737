import { Box, CircularProgress, Typography } from "@mui/material";
import colors from "../../../resources/cssConstant";

interface StepperCircularProgressProps {
  progressValue: number;
}

export default function StepperCircularProgress({ progressValue }: Readonly<StepperCircularProgressProps>) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="body1" component="div" sx={{ color: colors.white }}>
          {`${progressValue}%`}
        </Typography>
      </Box>
      <CircularProgress variant="determinate" sx={{ color: colors.white }} size={70} thickness={7} value={100} />
      <CircularProgress
        variant="determinate"
        sx={{
          color: colors.stepperActionValidate,
          position: "absolute",
          left: 0,
          strokeLinecap: "round",
        }}
        size={70}
        thickness={7}
        value={progressValue}
      />
    </Box>
  );
}
