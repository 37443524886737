/* EXTERNAL */
import { useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import {
  Box,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { faEdit, faFolderOpen, faChevronUp, faChevronDown, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useAtomValue } from "jotai";
import { DateTime } from "luxon";
/* LOCAL */
import { ICompanyShort } from "../../../../../interfaces/Company";
import GenericIconButton from "../../../../Generics/buttons/GenericIconButton";
import colors from "../../../../../resources/cssConstant";
import ProgressChip from "../../../ProgressChip/ProgressChip";
import { IShortForm, ProgressStatus, Type } from "../../../../../interfaces/Form";
import DocumentActions from "../DocumentActions/DocumentActions";
import { userAtom } from "../../../../../atoms/Atoms";
import { Role } from "../../../../../interfaces/User";
import { frontOfficeRoutes, backOfficeRoutes } from "../../../../../resources/Routes";
import CompanyForm from "../../../Companies/container/CompanyForm";
import CompanyDelete from "../../../Companies/container/CompanyDelete";
import NewDocumentButton from "../../../DocumentActions/NewDocumentButton/NewDocumentButton";
import { IOiraShortForm } from "../../../../../interfaces/OiraForm";
import RoleChecker from "../../../../../routers/RoleChecker";

interface CollapsibleCompanyProps {
  shortCompany: ICompanyShort;
  oiras: IOiraShortForm[];
  fetchCompanies: () => void;
}
export default function CollapsibleCompany({ shortCompany, oiras, fetchCompanies }: Readonly<CollapsibleCompanyProps>) {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const user = useAtomValue(userAtom);

  const DuerpTitle = "DUERP";
  const ComplementaryFromTitle = "Questionnaire complémentaire";

  const openCompanyDocuments = () => {
    const encodeCompanyName = shortCompany.companyName.replaceAll("/", "%2F");
    user.role === Role.COMPANY_USER
      ? navigate(generatePath(frontOfficeRoutes.company, { companyName: encodeCompanyName }), {
          state: { companyUuid: shortCompany.uuid, companyName: shortCompany.companyName },
        })
      : navigate(generatePath(backOfficeRoutes.company, { companyName: encodeCompanyName }), {
          state: { companyUuid: shortCompany.uuid, companyName: shortCompany.companyName },
        });
  };

  const renderCompanyActions = () => (
    <>
      <GenericIconButton onClick={() => setOpenUpdateModal(true)} icon={faEdit} tooltip="Modifier les informations" />
      <GenericIconButton
        onClick={() => openCompanyDocuments()}
        icon={faFolderOpen}
        tooltip="Consulter les documents de cette entreprise"
      />
      <RoleChecker roles={[Role.ADMIN]}>
        <GenericIconButton
          onClick={() => setOpenDeleteModal(true)}
          color={colors.deleteIcon}
          icon={faTrash}
          tooltip="Supprimer cette entreprise"
        />
      </RoleChecker>
    </>
  );

  const renderEmptyLine = (title: string, type: Type) => (
    <TableRow key={title}>
      <TableCell component="th" scope="row">
        <Typography variant="body2">{title}</Typography>
      </TableCell>
      <TableCell colSpan={2}>
        <Typography sx={{ fontStyle: "italic" }}>Aucune donnée à afficher</Typography>
      </TableCell>
      <TableCell align="center">
        <NewDocumentButton buttonWithIcon company={shortCompany} documentType={type} oiras={oiras} />
      </TableCell>
    </TableRow>
  );

  const renderDocumentLine = (document: IShortForm, title: string) => {
    const status: ProgressStatus = document.progressStatus;
    return (
      <TableRow key={document.uuid}>
        <TableCell component="th" scope="row">
          <Typography variant="body2">{title}</Typography>
        </TableCell>
        <TableCell>{DateTime.fromISO(document.lastUpdatedDate).toLocaleString()}</TableCell>
        <TableCell>
          <ProgressChip progressType={status} />
        </TableCell>
        <TableCell align="center">
          <DocumentActions document={document} company={shortCompany} oiras={oiras} />
        </TableCell>
      </TableRow>
    );
  };

  const handleOpenCollapse = () => {
    setOpen(!open);
  };

  return (
    <>
      <CompanyForm
        openModal={openUpdateModal}
        setOpenModal={setOpenUpdateModal}
        isUpdate
        company={shortCompany}
        fetchCompanies={fetchCompanies}
      />
      <CompanyDelete
        openModal={openDeleteModal}
        setOpenModal={setOpenDeleteModal}
        company={shortCompany}
        fetchCompanies={fetchCompanies}
      />
      <TableRow>
        <TableCell onClick={handleOpenCollapse} component="th" scope="row">
          <GenericIconButton
            onClick={handleOpenCollapse}
            icon={open ? faChevronUp : faChevronDown}
            tooltip={open ? "Fermer" : "Voir les derniers documents"}
          />
        </TableCell>
        <TableCell onClick={handleOpenCollapse}>{shortCompany.companyName}</TableCell>
        <TableCell onClick={handleOpenCollapse}>{shortCompany.membershipNumber}</TableCell>
        {user.role !== Role.COMPANY_USER && (
          <>
            <TableCell align="center" onClick={handleOpenCollapse}>
              {shortCompany.duerpsNumber}
            </TableCell>
            <TableCell align="center" onClick={handleOpenCollapse}>
              {shortCompany.complementaryFormsNumber}
            </TableCell>
          </>
        )}
        <TableCell>{renderCompanyActions()}</TableCell>
      </TableRow>
      <TableRow sx={{ backgroundColor: colors.lightBlue }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={user.role === Role.COMPANY_USER ? 4 : 6}>
          <Collapse in={open} timeout="auto">
            <Box sx={{ p: 2 }}>
              <Typography variant="h4" gutterBottom>
                Derniers documents de {shortCompany.companyName}
              </Typography>
              <TableContainer component={Paper} variant="outlined">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Type de document</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Statut</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {shortCompany.lastDuerp
                      ? renderDocumentLine(shortCompany.lastDuerp, DuerpTitle)
                      : renderEmptyLine(DuerpTitle, Type.DUERP)}
                    {shortCompany.lastComplementaryForm
                      ? renderDocumentLine(shortCompany.lastComplementaryForm, ComplementaryFromTitle)
                      : renderEmptyLine(ComplementaryFromTitle, Type.COMPLEMENTARY_FORM)}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
