import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { generatePath, useNavigate } from "react-router-dom";
import { ListItemIcon, MenuItem, Stack, Typography } from "@mui/material";
import { useAtom, useAtomValue } from "jotai";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import GenericIconButton from "../../../Generics/buttons/GenericIconButton";
import { Type } from "../../../../interfaces/Form";
import { Role } from "../../../../interfaces/User";
import { backOfficeRoutes, frontOfficeRoutes } from "../../../../resources/Routes";
import GenericDialog from "../../../Generics/GenericDialog/GenericDialog";
import { userAtom, userCompaniesNameAtom } from "../../../../atoms/Atoms";
import { ICompany, ICompanyShort } from "../../../../interfaces/Company";
import { AutocompleteOption, IShape } from "../../../../interfaces/Filter";
import GenericAutoComplete from "../../../Generics/GenericAutoComplete/GenericAutoComplete";
import CompanyService from "../../../../services/CompanyService";
import GenericButton from "../../../Generics/buttons/GenericButton";
import colors from "../../../../resources/cssConstant";
import ComplementaryFormService from "../../../../services/ComplementaryFormService";
import DuerpService from "../../../../services/DuerpService";
import FormService from "../../../../services/FormService";

interface DuplicateDocumentButtonProps {
  documentType: Type;
  documentUuid: string;
  iconButton?: boolean;
  menuButton?: boolean;
  company?: ICompanyShort | ICompany;
}

const autocompleteSchema = yup.object<IShape<AutocompleteOption>>({
  id: yup.string(),
  name: yup.string(),
});

export default function DuplicateDocumentButton({
  documentType,
  documentUuid,
  company,
  iconButton,
  menuButton,
}: Readonly<DuplicateDocumentButtonProps>) {
  const navigate = useNavigate();
  const user = useAtomValue(userAtom);
  const [companies, setCompanies] = useAtom(userCompaniesNameAtom);
  const isDuerp = documentType === Type.DUERP;
  const [openDuplicateModal, setOpenDuplicateModal] = useState<boolean>(false);
  const [confirmDuplicateDisabled, setConfirmDuplicateDisabled] = useState<boolean>(false);
  const [showOldVersionMessage, setShowOldVersionMessage] = useState<boolean>(false);
  const { control: duplicateControl, handleSubmit: duplicateSubmit } = useForm({
    resolver: yupResolver(autocompleteSchema),
    mode: "onTouched",
  });

  const isOiraVersionPublished = async () => {
    if (isDuerp) {
      const res = await DuerpService().getDuerp(documentUuid);
      if (res.data) {
        const { oiraFormUuid } = res.data;
        const oira = await FormService().getOiraById(oiraFormUuid);
        setShowOldVersionMessage(!oira.data.published);
      } else {
        setShowOldVersionMessage(false);
      }
    } else {
      setShowOldVersionMessage(false);
    }
  };

  const fetchCompanies = async () => {
    const retrievedCompanies = await CompanyService().getCompaniesNames();
    setCompanies(retrievedCompanies?.data ?? []);
  };

  useEffect(() => {
    if (openDuplicateModal) {
      isOiraVersionPublished();
      if (user.role === Role.COMPANY_USER) setCompanies(user.companies);
      else fetchCompanies();
    }
  }, [openDuplicateModal]);

  const handleDuplicateDocument = async (data: FieldValues) => {
    setConfirmDuplicateDisabled(true);
    const retrievedCompany = data.duplicateValue ?? {
      id: company?.uuid,
      name: company?.companyName,
    };
    if (retrievedCompany) {
      const field = isDuerp ? "duerp" : "complementary_form";
      const path = user.role === Role.COMPANY_USER ? frontOfficeRoutes[field] : backOfficeRoutes[field];
      const companyRes = await CompanyService().getCompanyById(retrievedCompany.id);
      const updatedCompany = companyRes.data;
      let retrievedDocument;
      if (isDuerp) {
        retrievedDocument = await DuerpService().duplicateDuerp(documentUuid, retrievedCompany.id);
      } else {
        retrievedDocument = await ComplementaryFormService().duplicateComplementaryForm(
          documentUuid,
          retrievedCompany.id,
          [],
        );
      }
      if (retrievedDocument) {
        navigate(generatePath(path, { refNumber: retrievedDocument.data.reference.replaceAll("/", "%2F") }), {
          state: { company: updatedCompany, uuid: retrievedDocument.data.uuid },
        });
        toast.success("Votre document a bien été dupliqué.");
        window.location.reload();
      } else {
        toast.error("Impossible de dupliquer ce document.");
      }
    }
    setOpenDuplicateModal(false);
  };

  const renderOldVersionMessage = (documentName: string) => (
    <Stack>
      <Typography variant="modalContent" fontWeight="bold" display="inline" mt={4}>
        Attention :
      </Typography>
      <Typography variant="modalContent" mb={2}>
        Ce {documentName} utilise une ancienne version du questionnaire. Pour réaliser un {documentName} avec la
        nouvelle version du questionnaire, vous devez démarrer un nouveau {documentName} vide.
      </Typography>
      <Typography variant="modalContent">
        En confirmant la duplication de ce {documentName}, vous conservez l’ancienne version du {documentName}.
      </Typography>
    </Stack>
  );

  const renderDuplicateModal = () => {
    const documentName = isDuerp ? "DUERP" : "questionnaire";
    return (
      <GenericDialog
        openDialog={openDuplicateModal}
        handleClose={() => {
          setConfirmDuplicateDisabled(false);
          setOpenDuplicateModal(false);
        }}
        title={isDuerp ? "Dupliquer le DUERP" : "Dupliquer le questionnaire complémentaire"}
        onValid={duplicateSubmit(handleDuplicateDocument)}
        disabled={confirmDuplicateDisabled}
      >
        <GenericAutoComplete
          options={companies}
          id="duplicateAutocomplete"
          defaultValue={{
            id: company?.uuid ?? "",
            name: company?.companyName ?? "",
          }}
          name="duplicateValue"
          label="Sélectionnez une entreprise"
          control={duplicateControl}
        />
        {showOldVersionMessage && renderOldVersionMessage(documentName)}
      </GenericDialog>
    );
  };

  const renderMenuButton = () => (
    <MenuItem onClick={() => setOpenDuplicateModal(true)}>
      <ListItemIcon>
        <FontAwesomeIcon icon={faCopy} size="xs" color={colors.primary} />
      </ListItemIcon>
      <Typography variant="body1">Dupliquer ce document</Typography>
    </MenuItem>
  );

  const renderButtonStyle = () =>
    iconButton ? (
      <GenericIconButton
        onClick={() => setOpenDuplicateModal(true)}
        icon={faCopy}
        tooltip="Nouveau document à partir de celui-ci"
      />
    ) : (
      <GenericButton onClick={() => setOpenDuplicateModal(true)} text="Nouveau document à partir de celui-ci" />
    );

  return (
    <>
      {openDuplicateModal && renderDuplicateModal()}
      {menuButton ? renderMenuButton() : renderButtonStyle()}
    </>
  );
}

DuplicateDocumentButton.defaultProps = {
  company: undefined,
  iconButton: true,
  menuButton: false,
};
