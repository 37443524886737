import { atom } from "jotai";
import { IUserShort, Role } from "../interfaces/User";
import { IBackOfficeUser } from "../interfaces/BackOfficeUser";
import { ICompanyUser } from "../interfaces/CompanyUser";
import { ICompanyNameFilter, ICompanyShort } from "../interfaces/Company";
import { emptyDuerp, emptyComplementaryForm, emptyOrganization } from "../resources/AppConstants";
import { IDuerpForm } from "../interfaces/DuerpForm";
import { IComplementaryForm } from "../interfaces/ComplementaryForm";
import { IOrganization } from "../interfaces/Organization";

export const userShortAtom = atom<IUserShort>({ role: Role.UNDEFINED, uuid: "" });

export const backOfficeUserAtom = atom<IBackOfficeUser>({
  email: "",
  firstname: "",
  lastname: "",
  role: Role.EXPERT,
  uuid: "",
  companies: [],
});

export const companyUserAtom = atom<ICompanyUser>({
  email: "",
  firstname: "",
  internal: false,
  lastname: "",
  positionTitle: "",
  role: Role.COMPANY_USER,
  uuid: "",
  companies: [],
});

export const userAtom = atom<IBackOfficeUser | ICompanyUser, IBackOfficeUser | ICompanyUser>(
  (get) => (get(userShortAtom).role === Role.COMPANY_USER ? get(companyUserAtom) : get(backOfficeUserAtom)),
  (get, set, newValue: ICompanyUser | IBackOfficeUser) =>
    get(userShortAtom).role === Role.COMPANY_USER
      ? set(companyUserAtom, newValue as ICompanyUser)
      : set(backOfficeUserAtom, newValue),
);

export const userCompaniesAtom = atom<ICompanyShort[]>([]);

export const userCompaniesNameAtom = atom<ICompanyNameFilter[]>([]);

export const currentDuerpAtom = atom<IDuerpForm>(emptyDuerp);

export const currentComplementaryFormAtom = atom<IComplementaryForm>(emptyComplementaryForm);

export const allOrganizationsAtom = atom<IOrganization[]>([]);

export const currentOrganizationAtom = atom<IOrganization>(emptyOrganization);
