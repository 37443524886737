import { IconButton, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons/faMagnifyingGlass";
import colors from "../../../resources/cssConstant";

export default function SearchButton() {
  return (
    <Tooltip arrow title="Rechercher">
      <span>
        <IconButton type="submit">
          <FontAwesomeIcon size="lg" color={colors.primary} icon={faMagnifyingGlass} />
        </IconButton>
      </span>
    </Tooltip>
  );
}
