import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { DateTime } from "luxon";
import { dateFormatRegexp, dateFormatString } from "../resources/AppConstants";

export const stringifyErrorMessage = (
  errorField: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined,
): string => errorField?.message?.toString() ?? "";

export const dateTimeValidator = (value: any) => {
  try {
    if (value instanceof DateTime) {
      return true;
    }
    return typeof value === "string" && dateFormatRegexp.test(value);
  } catch (error) {
    return false;
  }
};

export const stringToDateTime = (value: string) =>
  DateTime.fromFormat(value, dateFormatString, {
    locale: "fr-FR",
  });

export const dateToString = (date: DateTime) => DateTime.fromISO(date.toString()).toFormat("dd/MM/yyyy");
