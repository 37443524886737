/* EXTERNAL */
import Chip from "@mui/material/Chip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-regular-svg-icons";

/* LOCAL */
import colors from "../../../resources/cssConstant";

interface GenericChipProps {
  text: string;
  icon: IconDefinition;
  color: string;
}

export default function GenericChip({ text, icon, color }: Readonly<GenericChipProps>) {
  return (
    <Chip
      sx={{ p: 2, backgroundColor: color }}
      label={text}
      icon={<FontAwesomeIcon size="xl" color={colors.white} icon={icon} />}
    />
  );
}
