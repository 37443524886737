import { AxiosResponse } from "axios";
import { IUserLogin, IUserShort } from "../interfaces/User";
import { Endpoints } from "../resources/Endpoints";
import HttpService from "./HttpService";
import { ICompanyUser, ICompanyUserRegister } from "../interfaces/CompanyUser";
import LocaleStorageService from "./LocaleStorageService";
import { AmetraToken } from "../resources/AppConstants";

export default function AuthenticationService() {
  const createFOAccount = (data: ICompanyUserRegister): Promise<AxiosResponse<ICompanyUser>> =>
    HttpService.fetch({
      url: Endpoints.users.companyUsers.register,
      method: "post",
      data,
    });

  const login = (data: IUserLogin): Promise<AxiosResponse<IUserShort>> =>
    HttpService.fetch({ url: Endpoints.login, method: "post", data });

  const introspect = (): Promise<AxiosResponse<IUserShort>> => {
    const retrievedToken = LocaleStorageService().getLocaleStorageItem(AmetraToken);
    return HttpService.fetch({
      url: Endpoints.introspection,
      method: "get",
      headers: { Authorization: `Bearer ${retrievedToken}` },
    });
  };

  const changePassword = (userEmail: string): Promise<AxiosResponse<any>> =>
    HttpService.fetch({
      url: Endpoints.users.changePasswordRequest,
      method: "post",
      data: { email: userEmail },
    });

  const updatePassword = (token: string, password: string): Promise<AxiosResponse<any>> =>
    HttpService.fetch({
      url: Endpoints.users.changePassword,
      method: "put",
      data: { token, password },
    });

  return {
    createFOAccount,
    login,
    introspect,
    changePassword,
    updatePassword,
  };
}
