import { useEffect } from "react";
import { useAtom } from "jotai";
import Header from "../../Commons/Header/Header";
import AdministrationForm from "./AdministrationForm";
import OrganizationService from "../../../services/OrganizationService";
import { allOrganizationsAtom, currentOrganizationAtom } from "../../../atoms/Atoms";
import AdministrationSkeleton from "./AdministrationSkeleton";

export default function Administration() {
  const [, setAllOrganizations] = useAtom(allOrganizationsAtom);
  const [uniqueOrganization, setUniqueOrganization] = useAtom(currentOrganizationAtom);

  const fetchUniqueOrganization = async () => {
    const uniqueOrganizationRes = await OrganizationService().getUniqueOrganization();

    setUniqueOrganization(uniqueOrganizationRes.data);
  };

  const fetchAllOrganizations = async () => {
    const organizationRes = await OrganizationService().getOrganizations();
    setAllOrganizations(organizationRes.data);
  };

  useEffect(() => {
    fetchUniqueOrganization();
    fetchAllOrganizations();
  }, []);

  return (
    <>
      <Header title="Administration" />
      {uniqueOrganization.uuid !== "" ? (
        <AdministrationForm organization={uniqueOrganization} updateOrganization={setUniqueOrganization} />
      ) : (
        <AdministrationSkeleton />
      )}
    </>
  );
}
