import { AppBar, Dialog, Stack, Toolbar, Typography, Slide } from "@mui/material";
import { useAtom } from "jotai";
import { TransitionProps } from "@mui/material/transitions";
import { Dispatch, SetStateAction, forwardRef, useEffect, ReactElement, Ref } from "react";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import GenericIconButton from "../../Generics/buttons/GenericIconButton";
import OrganizationService from "../../../services/OrganizationService";
import LegalNoticePartFour from "./LegalNoticePartFour";
import LegalNoticePartFive from "./LegalNoticePartFive";
import LegalNoticePartSix from "./LegalNoticePartSix";
import LegalNoticePartSeven from "./LegalNoticePartSeven";
import LegalNoticePartEight from "./LegalNoticePartEight";
import LegalNoticePartNine from "./LegalNoticePartNine";
import LegalNoticePartOne from "./LegalNoticePartOne";
import colors from "../../../resources/cssConstant";
import { currentOrganizationAtom } from "../../../atoms/Atoms";

interface LegalNoticeProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: ReactElement<any, any>;
    },
    ref: Ref<unknown>,
  ) => <Slide direction="up" ref={ref} {...props} />,
);

export default function LegalNotice({ open, setOpen }: Readonly<LegalNoticeProps>) {
  const [uniqueOrganization, setUniqueOrganization] = useAtom(currentOrganizationAtom);

  const fetchUniqueOrganization = async () => {
    const uniqueOrganizationRes = await OrganizationService().getUniqueOrganization();
    setUniqueOrganization(uniqueOrganizationRes.data);
  };

  useEffect(() => {
    fetchUniqueOrganization();
  }, []);

  return (
    <Dialog fullScreen open={open} onClose={() => setOpen(false)} TransitionComponent={Transition}>
      <AppBar sx={{ position: "sticky", backgroundColor: colors.primary, width: "100%", borderRadius: 2, p: 0 }}>
        <Toolbar sx={{ height: "100%" }}>
          <Typography sx={{ flex: 1, color: colors.white }} variant="h3">
            Conditions Générales d'Utilisation
          </Typography>
          <GenericIconButton icon={faClose} color={colors.white} onClick={() => setOpen(false)} tooltip="Fermer" />
        </Toolbar>
      </AppBar>

      <Stack spacing={5} sx={{ paddingX: 10, paddingY: 3 }}>
        <Stack spacing={1}>
          <Typography variant="h3">Préambule</Typography>
          <Typography>{uniqueOrganization.spstipresentation}</Typography>
          <Typography>
            {uniqueOrganization.organizationName} a souscrit à la solution web « MONDUERP » développée par l’Association
            pour la Médecine du Travail des Alpes Maritimes (ci-après «<b>AMETRA06</b>»).
          </Typography>
          <Typography>
            {uniqueOrganization.organizationName} met ainsi à disposition de ses Adhérents le portail en ligne «
            MONDUERP » qui permet de simplifier l’élaboration de leur Document Unique d’Évaluation des Risques
            Professionnels.
          </Typography>
          <Typography>
            Les présentes Conditions générales d’Utilisation sont applicables à {uniqueOrganization.organizationName}{" "}
            ainsi qu’à tous les Adhérents dans leur utilisation de la plateforme « MONDUERP ».
          </Typography>
        </Stack>

        <LegalNoticePartOne organization={uniqueOrganization} />

        <Stack spacing={1}>
          <Typography variant="h3">2. Objet et champ d'application</Typography>
          <Typography>
            Les Conditions Générales ont pour objet de définir les conditions et modalités d’utilisation de la
            plateforme MONDUPERP par {uniqueOrganization.organizationName} et par ses Adhérents.
          </Typography>
        </Stack>

        <Stack spacing={1}>
          <Typography variant="h3">3. Opposabilité des Conditions générales d’utilisation</Typography>
          <Typography>
            L’accès de l’Adhérent à la plateforme MONDUERP vaut acceptation pleine et entière des présentes CGU.
          </Typography>{" "}
          <Typography>
            Elles constituent le contrat liant l’Editeur à {uniqueOrganization.organizationName} et{" "}
            {uniqueOrganization.organizationName} à l’Adhérent. Ce dernier s’engage donc à les lire attentivement et
            reconnaît que l’utilisation, la souscription et/ou la réservation aux Services implique leur acceptation
            sans restriction ni réserve.
          </Typography>
        </Stack>

        <LegalNoticePartFour />
        <LegalNoticePartFive />
        <LegalNoticePartSix organizationName={uniqueOrganization.organizationName} />
        <LegalNoticePartSeven organizationName={uniqueOrganization.organizationName} />
        <LegalNoticePartEight organizationName={uniqueOrganization.organizationName} />
        <LegalNoticePartNine organization={uniqueOrganization} />

        <Stack spacing={1}>
          <Typography variant="h3">10. Évolution de la plateforme MONDUERP</Typography>
          <Typography>
            En fonction des évolutions technologiques ou réglementaires, l’Editeur pourra faire évoluer ou apporter des
            adaptations à la plateforme MONDUERP.
          </Typography>
          <Typography>
            L’Editeur se réserve par ailleurs le droit de modifier ou de supprimer tout service proposé au regard de ces
            évolutions.
          </Typography>
        </Stack>

        <Stack spacing={1}>
          <Typography variant="h3">11. Modification des Conditions Générales d’Utilisation </Typography>
          <Typography>
            L’Editeur se réserve la possibilité de modifier, à tout moment et sans information préalable de
            l’Utilisateur, les présentes CGU afin de les adapter aux évolutions du Site et de son exploitation.
          </Typography>
          <Typography>
            Les Utilisateurs auront l’obligation d’accepter les nouvelles Conditions Générales d’Utilisation, s’ils
            souhaitent continuer d’utiliser la Plateforme MONDUERP.
          </Typography>
          <Typography>
            La proposition des nouvelles CGU se fera lors de la prochaine connexion de l’Utilisateur après modification
            des présentes sous forme de message affiché dès ouverture du site{" "}
            {<a href={window.location.origin}>{window.location.origin}</a>}
          </Typography>
        </Stack>

        <Stack spacing={1}>
          <Typography variant="h3">12. Juridiction compétente et droit applicable</Typography>
          <Typography>
            En cas de différend lié à l’exécution des Conditions Générales d’Utilisation,{" "}
            {uniqueOrganization.organizationName} et l’Adhérent s’efforcent de le régler à l’amiable préalablement à
            toute action contentieuse.
          </Typography>
          <Typography>
            Dans le cas où un accord amiable ne peut intervenir, le droit applicable au litige est le droit français.
            Les présentes sont soumises à la compétence exclusive des Tribunaux compétents dans le ressort de la Cour
            d’Appel de Paris.
          </Typography>
        </Stack>
      </Stack>
    </Dialog>
  );
}
