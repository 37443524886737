import { Stack, Typography } from "@mui/material";

interface LegalNoticePartSixProps {
  organizationName: string;
}

export default function LegalNoticePartSix({ organizationName }: Readonly<LegalNoticePartSixProps>) {
  return (
    <Stack spacing={1}>
      <Typography variant="h3">6. Droits et obligations de {organizationName}</Typography>
      <Typography>
        {organizationName} reconnaît que l’accès à la Plateforme MONDUERP est réservé aux seuls utilisateurs habilités
        et munis d’un code utilisateur et mot de passe valides.
      </Typography>
      <Typography>
        {organizationName} peut créer, rechercher, modifier, ou supprimer un Espace entreprise d’un utilisateur. Il a
        accès à la fonction déverrouillage de l’espace entreprise.
      </Typography>
      <Typography>
        {organizationName} s’engage à mettre tout en œuvre pour assurer le bon fonctionnement de l’espace entreprise et
        la confidentialité des informations communiquées.
      </Typography>
      <Typography>
        {organizationName} fournit tous ses efforts afin d’assurer que l’accès et le fonctionnement du portail adhérent
        soient assurés 24 heures sur 24 et 7 jours sur 7.
      </Typography>
      <Typography>
        L’accès au service pourra cependant être suspendu aussi brièvement que possible pour d’éventuelles interventions
        de maintenance ou d’amélioration afin d’assurer le bon fonctionnement du service.
      </Typography>
      <Typography>
        L’accessibilité au portail adhérent pourra être suspendue dans les cas suivants, sans que la responsabilité de{" "}
        {organizationName} puisse être engagée :
      </Typography>
      <Typography>
        - Attaque des serveurs susceptibles d’en affecter la capacité de traitement, la sécurité ou l’intégrité ou celle
        des données, telles que notamment les attaques par virus ou autres codes nuisibles ou les attaques par accès
        massifs ou requêtes visant à saturer la capacité de traitement ou de stockage ;
      </Typography>
      <Typography>
        - Evènement suffisamment important comme la mise en place d’un patch de sécurité très urgent ;
      </Typography>
      <Typography>
        - Demande de suspension ou restriction d’accès au serveur formulée par une autorité administrative ou
        judiciaire.
      </Typography>
    </Stack>
  );
}
