import { Stack, Typography } from "@mui/material";

interface LegalNoticePartSevenProps {
  organizationName: string;
}

export default function LegalNoticePartSeven({ organizationName }: Readonly<LegalNoticePartSevenProps>) {
  return (
    <Stack spacing={1}>
      <Typography variant="h3">7. Retours d’expérience</Typography>
      <Typography>
        {organizationName} et les Adhérents reconnaissent avoir été informés, préalablement à l’acceptation des
        présentes, de l’existence d’un accord entre l’AMETRA06 et l’INRS portant sur la réalisation d’études
        scientifiques et techniques au niveau national pour l’amélioration de la prévention des risques professionnels.
      </Typography>
      <Typography>
        Ils donnent ainsi leur accord à l’Editeur pour transmettre, de façon anonymisées, toutes les données qui lui
        permettront d’effectuer un retour d’expérience sur :
      </Typography>
      <Typography>- les actions mises en œuvre par le licencié afin de promouvoir la Plateforme ;</Typography>
      <Typography>- le nombre d’évaluations réalisées sur la Plateforme par métier ;</Typography>
      <Typography>
        - la perception des entreprises quant à la pertinence des contenus (risques et mesures) et au caractère
        opérationnel et efficace des mesures proposées ;
      </Typography>
      <Typography>
        - les actions mises en œuvre par les entreprises et leurs impacts en termes de Santé Sécurité au travail ;
      </Typography>
      <Typography>
        - les éventuelles difficultés rencontrées par les entreprises et leurs impacts en matière de Santé Sécurité au
        travail ;
      </Typography>
      <Typography>
        - des propositions d’amélioration de contenus sectoriels suite aux retours d’expérience des adhérents.
      </Typography>
    </Stack>
  );
}
