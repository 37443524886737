import { Stack, Typography } from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useState } from "react";
import GenericTextField from "../../Generics/GenericTextField/GenericTextField";
import { stringifyErrorMessage } from "../../../utils/ConversionMethods";
import GenericButton from "../../Generics/buttons/GenericButton";
import AuthenticationService from "../../../services/AuthenticationService";
import { StatusCode } from "../../../resources/StatusCode";

const emailSchema = yup.object().shape({
  email: yup.string().email("Le format est incorrect.").required("Veuillez remplir ce champ."),
});

export default function ChangePasswordRequest() {
  const [displayPasswordChangeEmail, setDisplayPasswordChangeEmail] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(emailSchema),
    mode: "onTouched",
  });

  const onSubmit = async (data: FieldValues) => {
    const res = await AuthenticationService().changePassword(data.email);
    if (res.status === StatusCode.OK) {
      setDisplayPasswordChangeEmail(true);
      reset();
    } else {
      toast.error("Aucun compte utilisateur n'est associé à cette adresse email.");
    }
  };

  return (
    <Stack alignItems="center" spacing={10} width="30%" onSubmit={handleSubmit(onSubmit)} component="form">
      <Typography variant="h4">Saisir l'email de votre compte utilisateur</Typography>
      <Stack alignItems="center" spacing={2} width="100%">
        <GenericTextField
          defaultValue=""
          required
          id="email"
          label="Email"
          error={!!errors.email}
          helperText={stringifyErrorMessage(errors.email)}
          register={register("email")}
        />
      </Stack>
      <GenericButton disabled={!isValid} text="Générer un nouveau mot de passe" login />
      {displayPasswordChangeEmail && (
        <Typography>
          Un email vient de vous être envoyé à l'adresse associée à votre compte utilisateur. Veuillez vérifier vos
          courriers indésirables ou vos spams. Il contient les étapes nécessaires au changement de mot de passe de votre
          compte.
        </Typography>
      )}
    </Stack>
  );
}
